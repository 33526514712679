var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "page-layout" }, [
        _c("div", { staticClass: "sprint-msg" }, [
          _vm._v(
            "\n      Design Sprint is a week-long collaborative process with activites that\n      ensures a product solves the problem form the users perspective, is\n      techniccally feasible and meets business requirements. Your Product Team\n      will draw up the schedule.\n    "
          ),
        ]),
        _c("div", { staticClass: "empty-box" }, [
          _c("div", [
            _c("img", { attrs: { src: "/img/onboarding/schedule-icon.svg" } }),
          ]),
          _c("div", { staticClass: "waiting-msg" }, [
            _vm._v(
              "\n        Waiting for team to generate design sprint schedule...\n      "
            ),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }