var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "report-container" },
      [
        _vm._m(0),
        _c("div", { staticClass: "day-date-head" }, [
          _c("div", { staticClass: "first-column" }),
          _c(
            "div",
            { staticClass: "date-list-div" },
            _vm._l(_vm.days, function (day, i) {
              return _c("div", { key: i, staticClass: "day-column" }, [
                _vm._v("\n          " + _vm._s(day) + "\n        "),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "standup-schedule" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "report-row-white" },
            _vm._l(5, function (i) {
              return _c("div", { key: i, staticClass: "report-column" }, [
                _vm._m(2, true),
                i == 1
                  ? _c("div", { staticClass: "standup-card" }, [
                      _c("div", { staticClass: "standup-badge" }),
                      _vm._m(3, true),
                    ])
                  : _vm._e(),
              ])
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "sprint-report-row" }, [
          _c("div", { staticClass: "first-column empty-sprint" }, [
            _vm._v("SPRINT REPORTS"),
          ]),
          _c(
            "div",
            { staticClass: "report-row-white" },
            _vm._l(5, function (i) {
              return _c("div", { key: i, staticClass: "report-column" })
            }),
            0
          ),
        ]),
        _vm._l(_vm.teamMembers, function (member, i) {
          return member.name
            ? _c(
                "div",
                {
                  key: i,
                  staticClass: "developer-task-row",
                  style: (i + 1) % 2 == 0 ? "background: #FFFFFF;" : null,
                },
                [
                  _c("div", { staticClass: "first-column task-owner" }, [
                    _c(
                      "div",
                      [
                        _c("v-avatar", {
                          staticClass: "profile_bg",
                          style: {
                            backgroundImage:
                              "url(" +
                              (_vm.$imageUrl + member.profile_picture ||
                                _vm.test) +
                              ")",
                          },
                          attrs: { size: 32, color: "grey lighten-4" },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "owner-name" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.shortenName(member.name)) +
                          "\n        "
                      ),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "task-report-row" },
                    _vm._l(_vm.reports.length, function (j) {
                      return _c(
                        "div",
                        { key: j, staticClass: "task-report-column" },
                        [
                          _c("div", { staticClass: "backlog-card" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.backlogTasks.length - _vm.reportTasks
                                ) +
                                " tasks in Backlog\n          "
                            ),
                          ]),
                          _vm._l(_vm.reports, function (report) {
                            return _c(
                              "div",
                              { key: report.id },
                              _vm._l(report.report, function (rep) {
                                return _c("div", { key: rep.id }, [
                                  rep.team_user == member.id
                                    ? _c("div", [
                                        rep.completed == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "task-ongoing-card",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "green-badge",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "task-ongoing-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    0/" +
                                                        _vm._s(
                                                          _vm.backlogTasks
                                                            .length
                                                        ) +
                                                        " tasks Completed\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        rep.pending == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "task-ongoing-card",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "green-badge",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "task-ongoing-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    1/" +
                                                        _vm._s(
                                                          _vm.pendingTasksLength
                                                        ) +
                                                        " tasks Pending\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        rep.completed_for_review == 1
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "task-ongoing-card",
                                              },
                                              [
                                                _c("div", {
                                                  staticClass: "green-badge",
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "task-ongoing-content",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    1/" +
                                                        _vm._s(
                                                          _vm.completedForReviewTasksLength
                                                        ) +
                                                        " tasks Completed For\n                    Review\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            )
                          }),
                        ],
                        2
                      )
                    }),
                    0
                  ),
                ]
              )
            : _vm._e()
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "report-header" }, [
      _c("div", { staticClass: "report-schedule" }, [
        _c("div", { staticClass: "schedule-text" }, [_vm._v("SCHEDULE")]),
        _c("div", { staticClass: "schedule-current-date" }, [
          _vm._v("March 2021"),
        ]),
        _c("div", { staticClass: "report-date-toggle" }, [
          _c("div", [
            _c("img", {
              attrs: { src: "/img/dashboard/dev/day-previous.svg" },
            }),
          ]),
          _c("div", { staticClass: "current-day" }, [_vm._v("Today")]),
          _c("div", [
            _c("img", { attrs: { src: "/img/dashboard/dev/day-next.svg" } }),
          ]),
        ]),
      ]),
      _c("div", [
        _c("div", [
          _c("img", { attrs: { src: "/img/dashboard/settings-icon.svg" } }),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "first-column" }, [
      _c("div", { staticStyle: { "margin-left": "8px" } }, [
        _c("img", { attrs: { src: "/img/dashboard/dev/meeting-icon.svg" } }),
      ]),
      _c("span", { staticStyle: { "margin-top": "7px" } }, [
        _vm._v("Scheduled Meetings"),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "standup-card" }, [
      _c("div", { staticClass: "standup-badge" }),
      _c("div", { staticClass: "standup-content" }, [
        _c("div", { staticClass: "standup-name" }, [_vm._v("Sprint Planning")]),
        _c("div", { staticClass: "standup-time" }, [
          _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
          _vm._v("\n                7:00pm WAT\n              "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "standup-content" }, [
      _c("div", { staticClass: "standup-name" }, [_vm._v("Daily Standup")]),
      _c("div", { staticClass: "standup-time" }, [
        _c("img", { attrs: { src: "/img/dashboard/time-icon.svg" } }),
        _vm._v("\n                7:00pm WAT\n              "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }