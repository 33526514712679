<template>
  <div>
    <div class="page-layout">
      <div class="page-msg">
        <div class="container-title">Draw Product Roadmap</div>
        <div class="description">
          The Product Roadmap maps out the direction of the product development
          over time. Based on learnings from the design sprint, priority is
          given to the most important ideas, features or user stories.
        </div>
      </div>
      <div class="stuff-to-know">
        <div class="info-header" style="color: #979da5">STUFF TO KNOW</div>
        <div class="item-list" v-for="(item, i) in listItems" :key="i">
          <div><img src="/img/onboarding/grey-checked.svg" /></div>
          <div>{{ item }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({
    listItems: [
      `The Product Roadmap would be drawn out by the Product team and shared with you.`,
      `Collaborate with the Product Team to make sure that the roadmap captures your vision as well before you approve.`,
      `Collaborate with the Product Team to make sure that the roadmap captures your vision as well before you approve.`,
      `Sprints are run weekly`,
    ],
  }),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
  line-height: 130%;
}
.container-title {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.description {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
  margin-top: 16px;
}
.stuff-to-know {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
  margin-top: 50px;
}

.stuff-to-know div {
  color: #1b1e22;
}
.grey-checked-color {
  color: #979da5;
  font-size: 14px;
  line-height: 130%;
}
.info-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.item-list {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
  display: flex;
  width: 512px;
}
.item-list img {
  margin-right: 8px;
}
</style>
