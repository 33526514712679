<template>
  <div>
    <Navbar v-bind="{ stageTitle }">
      <div
        class="btn-wrap"
        v-if="
          project.workspace_progress_level == 1 &&
          project.is_nda == 1 &&
          isMeetingDone()
        "
      >
        <button class="grupa-white-blue-btn">Choose A Different Team</button>
        <button
          @click="handleChooseThisTeam"
          class="grupa-blue-btn"
          style="margin-left: 16px"
        >
          Continue With {{ teamMembers[0].team_name }}
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="btnSpinner"
          />
        </button>
      </div>
      <div
        class="btn-wrap"
        v-if="
          (isDesignOver && project.workspace_progress_level == 3) ||
          project.workspace_progress_level == 4
        "
      >
        <button
          class="grupa-disabled-btn"
          :class="{ 'grupa-blue-btn': project.share_roadmap == 1 }"
          :disabled="project.share_roadmap == 0 || getDay3OfDesignSprint"
          @click="handleCheckRoadmap"
        >
          Check Roadmap
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="btnSpinner"
          />
        </button>
      </div>
      <div
        class="btn-wrap"
        v-if="project.workspace_progress_level == 5 && !project.team_accept_sla"
      >
        <button class="grupa-disabled-btn" :disabled="true">
          Waiting for Team
        </button>
      </div>
      <div
        class="btn-wrap"
        v-if="project.workspace_progress_level == 5 && project.team_accept_sla"
      >
        <button class="grupa-blue-btn" @click="handleAcceptSla">
          Agree & Execute
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="btnSpinner"
          />
        </button>
      </div>
    </Navbar>
    <div class="prekickoff-wrapper">
      <DesignSprintPayment
        v-if="getDay3OfDesignSprint"
        v-bind="{ openLearnMore, openStripeModal }"
      />
      <ProgessBar />
      <div class="right-container">
        <ProjectTeam v-if="project.is_nda < 1" />
        <MeetTheTeam
          v-if="project.is_nda == 1 && project.workspace_progress_level < 3"
        />
        <DesignSprint
          v-bind="{ sprintArray }"
          v-if="project.workspace_progress_level == 3 && !isDesignOver"
        />
        <ProductRoadMap
          v-if="
            (isDesignOver && project.workspace_progress_level == 3) ||
            project.workspace_progress_level == 4
          "
        />
        <TeamSLA
          v-if="
            project.workspace_progress_level == 5 && !project.team_accept_sla
          "
        />
        <ClientSLA
          v-if="
            project.workspace_progress_level == 5 && project.team_accept_sla
          "
        />
        <StartProductDevelopment v-if="project.workspace_progress_level == 6" />
      </div>
    </div>
    <PaymentSuccess v-if="paymentModalVisible" />
    <LearnMoreModal v-if="isLearnMore" v-bind="{ project, closeDialog }" />
    <StripeModal
      v-if="openStripe"
      v-on:close="closeStripeModal"
      v-bind:amount="designSprintBalance"
      v-bind="{ user, modalType, paymentInfo, userCreditCard }"
    />
    <AlertSuccess v-if="successMsg" time="7" />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PaymentSuccess from "@/components/paymentSuccess";
import ProgessBar from "./progressBar";
import ProjectTeam from "./project-team";
import MeetTheTeam from "./meet-team";
import DesignSprint from "./designSprint";
import ProductRoadMap from "./productRoadMap";
import StartProductDevelopment from "./productDevelopment";
import TeamSLA from "./teamSLA";
import Navbar from "@/components/general/toolbar";
import ClientSLA from "@/components/SLADoc";
import DesignSprintPayment from "./payment";
import { confirmWorkWithTeam, acceptSLA } from "@/api";
import LearnMoreModal from "./payment/learnMoreModal.vue";
import StripeModal from "@/components/stripeModal";
import AlertSuccess from "@/components/alertSuccess";

export default {
  props: {},
  components: {
    StripeModal,
    Navbar,
    PaymentSuccess,
    ProgessBar,
    ProjectTeam,
    MeetTheTeam,
    DesignSprint,
    ProductRoadMap,
    TeamSLA,
    StartProductDevelopment,
    ClientSLA,
    DesignSprintPayment,
    LearnMoreModal,
    AlertSuccess,
  },

  data: () => ({
    openStripe: false,
    isLearnMore: false,
    showDialog: false,
    stageTitle: "Workspace",
    stage: 1,
    btnSpinner: false,
    isDesignOver: null,
    modalType: "design_sprint",
    paymentInfo: {
      purpose: "design sprint balance",
      payment_plan: "none",
      iteration_id: null,
    },
    designSprintBalance: "",
  }),
  watch: {
    sprintArray: function (newVal) {
      if (newVal.length > 0) {
        let currentDFate = this.sprintArray[4].date;
        this.isDesignOver = this.$moment(currentDFate).isBefore(this.$moment());
      }
    },
  },
  created() {
    if (this.project.workspace_progress_level == 2) {
      this.$router.push({
        name: "pay_design_sprint",
        params: { id: this.$route.params.id },
      });
    }
  },
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    closeStripeModal() {
      this.openStripe = false;
    },
    openStripeModal() {
      this.openStripe = true;
      this.paymentInfo.iteration_id =
        this.clientProjectDoc.documents.iterations[0].id;
      this.designSprintBalance = this.project.design_thinking_fee / 2;
    },
    openLearnMore() {
      this.isLearnMore = true;
    },
    closeModal() {
      this.openStripe = false;
    },
    closeDialog() {
      this.showDialog = false;
      this.isLearnMore = false;
    },
    goToRoadMap() {
      this.$router.push({
        name: "project_workspace",
        params: { id: this.$route.params.id },
      });
    },
    isMeetingDone() {
      let meeting_date = this.project.preview_meeting_start_date;
      return this.$moment(meeting_date).isBefore(this.$moment());
    },
    handleChooseThisTeam() {
      let payload = {
        project_id: this.$route.params.id,
      };
      this.btnSpinner = true;
      confirmWorkWithTeam(payload)
        .then((resp) => {
          this.btnSpinner = false;
          this.updateProject(resp.data.project);
          this.$router.push({
            name: "pay_design_sprint",
            params: { id: payload.project_id },
          });
        })
        .catch((err) => {
          this.btnSpinner = false;
          console.log(err, "Api error");
        });
    },

    handleCheckRoadmap() {
      let project_id = this.$route.params.id;
      this.$router.push({ name: "client_roadmap", params: { project_id } });
    },
    handleAcceptSla() {
      this.btnSpinner = true;
      let project_id = this.$route.params.id;
      let data = { project_id };
      acceptSLA(data)
        .then((resp) => {
          this.updateProject(resp.data.data);
          this.setSuccessMsg("Accepted SLA!!");
        })
        .catch((err) => {})
        .finally(() => {
          this.btnSpinner = false;
        });
    },

    compareWithCurrentDate(date) {
      return this.$moment(date).isBefore(this.$moment());
    },
  },
  computed: {
    ...mapState("modalMgmt", ["paymentModalVisible"]),
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientPrekickoff", ["designSprintDays", "clientCreditCards"]),
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    project() {
      return this.clientProject;
    },
    teamMembers() {
      return this.projectTeam;
    },
    sprintArray() {
      return this.designSprintDays;
    },
    getDay3OfDesignSprint() {
      let sprintArray = this.sprintArray;
      if (sprintArray.length > 0) {
        let sprintObj = sprintArray.find((sprint) => sprint.day == 3);
        let thirdDay = this.compareWithCurrentDate(sprintObj.date);
        if (thirdDay && this.project.design_sprint_balance == 0) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    },
    userCreditCard() {
      return this.clientCreditCards;
    },
    user() {
      return this.currentUser;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.prekickoff-wrapper {
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
  display: flex;
  position: relative;
}
.right-container {
  flex-grow: 1;
}
.cover-all {
  height: 100vh;
}
.btn-wrap {
  padding-top: 9px;
  display: flex;
}
.grupa-blue-btn {
  background: #0781f2 !important;
  color: #fdfdfd !important;
}
</style>
