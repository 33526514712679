<template>
  <div>
    <hr
      style="border-top: 1px solid rgb(129 120 120 / 57%); margin-top: 50px"
    />
    <div class="grey-header">
      <div class="alert alert-warning">
        <!--  Here’s a copy of the Platform Agreement with Grupa, a copy has been sent
        to the team. -->
        Click to <b>Review and Sign</b> the platform agreement document
      </div>
    </div>

    <div class="platform-agreement">
      <div class="document-name">
        <div class="document-icon">
          <img src="/img/doc-icons/pdf.svg" alt="" />
        </div>
        <div class="product-name">
          {{ project.project_name }} {{ `<>` }} Grupa Platform Agreement.pdf
        </div>
      </div>

      <div
        class="open-action"
        @click="handlePADrawer"
        style="font-size: 12px; margin-left: 32%"
      >
        {{ !isPASignedProps() ? "Review and Sign" : "Signed" }}
      </div>

      <div class="open-action" @click="handleOpenAgreement" v-if="!loadingPdf">
        <img
          src="/img/download.png"
          style="margin-left: 0px; width: 25px"
          class="button-spinner"
          v-show="isPASignedProps()"
        />
      </div>
      <img
        src="/img/lightbox/preloader.gif"
        style="margin-left: 20px"
        class="button-spinner"
        v-show="loadingPdf"
      />
    </div>

    <hr
      style="border-top: 1px solid rgb(129 120 120 / 57%); margin-top: 50px"
    />

    <div class="grey-header">
      <div class="alert alert-warning">
        <!--  Here’s a copy of the SLA, click to open the document. -->
        Click to <b>Review and Sign</b> the service level agreement (SLA)
        document
      </div>
    </div>
    <!-- Content -->
    <div class="platform-agreement">
      <div class="document-name">
        <div class="document-icon">
          <img src="/img/doc-icons/pdf.svg" alt="" />
        </div>
        <div class="product-name">
          {{ project.project_name }} {{ `<>` }}
          <span> Product Team Service Level Agreement.pdf </span>
        </div>
      </div>

      <!-- <div class="d-flex justify-center align-center h-100">
        <v-btn color="primary" @click="drawerSLAState = !drawerSLAState">
          Toggle
        </v-btn>
      </div> -->

      <!--  v-if="!isSLASignedProps()" -->
      <div
        class="open-action"
        @click="handleSLADrawer"
        style="font-size: 12px; margin-left: 25%"
      >
        {{ !isSLASignedProps() ? "Review and Sign" : "Signed" }}
      </div>

      <!--  v-if="!loadingPdfSLA " -->
      <div
        class="open-action"
        @click="handleOpenAgreementSLA"
        v-if="isSLASignedProps() || project.accept_sla"
      >
        <img
          v-if="!loadingPdfSLA"
          src="/img/download.png"
          style="margin-left: 0px; width: 25px"
          class="button-spinner"
        />
      </div>
      <img
        src="/img/lightbox/preloader.gif"
        style="margin-left: 20px"
        class="button-spinner"
        v-show="loadingPdfSLA"
      />
    </div>
    <div class="btn-container">
      <!--  <button @click="handleSignSLA" class="grupa-blue-btn">
        Agree & Execute
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="spinner"
        />
      </button> -->
    </div>

    <DrawerSLA
      ref="slaDrawer"
      v-bind="{
        teamDetails,
        projectDoc,
        isSLASignedFunction,
        spinnerSLA,
        handleSignSLA,
        project,
        equity,
      }"
      v-bind:devUser="false"
    />

    <DrawerPA
      ref="paDrawer"
      v-bind="{
        teamDetails,
        projectDoc,
        isPAgreementSignedFunction,
        isPASignedProps,
        spinnerSLA,
        //handleSignSLA,
        project,
        equity
      }"
      v-bind:devUser="false"
    />

    <PlatformAgreement
      ref="platformAgreement"
      id="platformAgreement"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, project,equity }"
    />

    <GrupaSLA
      ref="grupa_sla"
      id="grupa-sla"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, projectDoc, project, equity }"
      v-bind:devUser="false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ChipButton from "@/components/chipButton/chipButton";
import GrupaSLA from "@/components/general/grupaSLA";

import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
import { acceptSLA } from "@/api";

import PlatformAgreement from "@/components/general/PlatformAgreement";
import DrawerSLA from "./DrawerSLA.vue";
import DrawerPA from "./DrawerPA.vue";

//

export default {
  props: {
    equity: {
      required: true,
      // type: String,
    },
    isAgreedState: {
      type: Function,
      required: true,
    },

    isSLASignedFunction: {
      type: Function,
      required: true,
    },

    isSLASignedProps: {
      type: Function,
      required: true,
    },

    isPAgreementSignedFunction: {
      type: Function,
      required: true,
    },
    isPASignedProps: {
      type: Function,
      required: true,
    },

    //
  },

  data: () => ({
    loadingPdf: false,
    loadingPdfSLA: false,
    spinnerSLA: false,
    // spinnerSLA: false,
  }),
  created() {},
  components: { ChipButton, PlatformAgreement, GrupaSLA, DrawerSLA, DrawerPA },

  methods: {
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),

    /////////////////////////////////////////////////////FROM SLA
    ...mapActions("clientDashboard", ["updateProject"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),

    handleSLADrawer() {
      this.$refs.slaDrawer.toggleSLADrawer();
    },

    handlePADrawer() {
      this.$refs.paDrawer.togglePADrawer();
    },
    handleOpenAgreement() {
      this.loadingPdf = true;
      const elementHTML = this.$refs.platformAgreement.$refs.agreementContainer;
      const settings = this.pdfSettings("platformAgreement");
      const doc = html2pdf().set(settings).from(elementHTML).save();
      doc.output("datauristring").then((pdfAsString) => {
        let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
        var fileURL = URL.createObjectURL(output);
        this.loadingPdf = false;

        this.isAgreedState();
        window.open(fileURL);
      });
    },

    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
    pdfSettings(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `PLATFORM_AGREEMENT_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },

    /////////////////////////////////?SLA CODES START HERE

    pdfSettingsSLA(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `SLA_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },

    handleOpenAgreementSLA() {
      this.loadingPdfSLA = true;

      const elementHTML = this.$refs.grupa_sla.$refs.slaWrapper;
      const settings = this.pdfSettingsSLA("platformAgreement");

      const doc = html2pdf().set(settings).from(elementHTML).save();
      doc.output("datauristring").then((pdfAsString) => {
        let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
        var fileURL = URL.createObjectURL(output);
        this.loadingPdfSLA = false;

        window.open(fileURL);
      });
    },
    handleSignSLA() {
      this.spinnerSLA = true;
      let project_id = this.$route.params.id;
      let data = { project_id };
      acceptSLA(data)
        .then((resp) => {
          this.updateProject(resp.data.data);
          this.setSuccessMsg("Service Level Agreement Signed");
          this.isSLASignedFunction();
          this.spinnerSLA = false;
        })
        .catch((err) => {})
        .finally(() => {
          this.spinner = false;
        });
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProjectDoc",
      "clientProject",
      "projectTeam",
      "projectPrice",
      "startupStage",
    ]),
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("modalMgmt", ["paymentModalVisible"]),
    user() {
      return this.currentUser;
    },
    teamDetails() {
      let team = { ...this.projectTeam[0] };
      team.members = this.projectTeam;
      return team;
    },
    project() {
      const project = JSON.parse(JSON.stringify(this.clientProject));
      project.owner = this.user;
      // Remove when backend figure out pricing calculation
      if (this.projectPrice.startupStage) {
        project.startupStage = this.startupStage;
      }
      //
      return project;
    },
    projectSLA() {
      return this.clientProject;
    },

    projectDoc() {
      let projectDoc = { ...this.clientProjectDoc.documents };
      projectDoc.project = this.clientProjectDoc.project;
      return projectDoc;
    },

    amountToPay() {
      let { no_of_engineers, price } = this.clientProject;
      const per_sprint_fee = (price * no_of_engineers) / 4;
      const total = (per_sprint_fee * 2).toLocaleString();
      return {
        per_sprint_fee,
        total,
      };
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 30px;
}
.platform-agreement {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
}
.document-name {
  display: flex;
  align-items: center;

  gap: 24px;
}
.document-icon img {
  height: 32px;
  width: 32px;
}
.product-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.open-action {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
}
.section-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.payment-charge {
  margin-top: 24px;
}
.total-charge {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.total-charge span {
  color: #1b1e22;
}
.payment-item-list {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.item-description {
  display: flex;
  gap: 12px;
}
.description-text {
  width: 323px;
}
.description-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.description-content {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 2px;
}
.btn-container {
  margin-top: 40px;
}
.stripe-copy-right {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
}
.stripe-copy-right span {
  color: #1b1e22;
}

.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.platform-agreement {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
}
.document-name {
  display: flex;
  align-items: center;

  gap: 24px;
}
.document-icon img {
  height: 32px;
  width: 32px;
}
.product-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.open-action {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
}
.section-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.payment-charge {
  margin-top: 24px;
}
.total-charge {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.total-charge span {
  color: #1b1e22;
}
.payment-item-list {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.item-description {
  display: flex;
  gap: 12px;
}
.description-text {
  width: 323px;
}
.description-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.description-content {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 2px;
}
.btn-container {
  margin-top: 40px;
}
.stripe-copy-right {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
}
.stripe-copy-right span {
  color: #1b1e22;
}
</style>
