<template>
  <div>
    <div class="page-layout">
      <div class="page-msg">
        {{ teamBio.team_name }} has been notified of your commitment to work
        with them. They’ll respond shortly (typically within 0 - 6hrs)
      </div>
      <div class="team-wrapper">
        <div class="top-info">
          <div class="team-info">
            <div class="team-name">
              {{ teamBio.team_name }}
              <span class=""> {{ teamBio.grupa_score }} </span>
            </div>
            <div class="team-experience">Principal Engineering Team</div>
            <div class="team-location">
              <img src="/img/onboarding/location-icon.svg" />
              <!-- Lagos, Nigeria (GMT +1) -->
              {{ teamBio.city ? teamBio.city + "," : "" }}
              {{ teamBio.country ? teamBio.country : "" }}
              <span v-if="teamBio.timezone"
                >(GMT {{ getTimeZone(teamBio.timezone) }})</span
              >
            </div>
            <div class="team-bio">{{ teamBio.bio }}</div>
            <div class="action-div">
              <!-- <button
                @click="goToViewTeam(teamBio.team_name)"
                class="grupa-white-blue-btn"
              >
                View team
              </button>
              <button class="grupa-grey-btn">Change Team</button> -->
            </div>
          </div>
          <div class="team-members">
            <div class="top-header">
              <div class="team-header">TEAM</div>
              <div class="team-msg">
                Number of actual team members may vary based on the service plan
                you have selected.
              </div>
            </div>
            <div class="team-developers">
              <div class="dev-info" v-for="(member, i) in teamMembers" :key="i">
                <v-avatar
                  :size="72"
                  color="grey lighten-4"
                  class="profile_bg"
                  :style="{
                    backgroundImage: `url(${
                      $imageUrl + member.profile_picture || test
                    })`,
                  }"
                ></v-avatar>
                <div class="dev_name" v-if="member.name">
                  {{ abbreviateFullName(member.name.split(" ")[0]) }}
                </div>
                <div v-if="member.team_role" class="dev_role">
                  {{ member.team_role.split(" ")[0] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-info">
          <div class="team-skills">
            <div class="section-title">SKILLS & LANGUAGES</div>
            <div class="team-speciality">
              <img src="/img/onboarding/speciality-icon.svg" />
              Web & Mobile
            </div>
            <div class="team-stack">
              <div
                v-for="(stack, i) in teamFrameWork(teamMembers)"
                :key="i"
                class="stack-div"
              >
                {{ stack }}
              </div>
            </div>
          </div>
          <!-- <div class="team-industries">
            <div class="section-title">INDUSTRIES & WORK EXPERIENCE</div>
            <div
              class="industries-list"
              v-for="(interest, i) in JSON.parse(teamBio.interests)"
              :key="i"
              v-if="teamBio.interests"
            >
              <div class="industry-div">
                {{ interest.category }}
              </div>
            </div>
            <div class="team-experience">
              <div>
                <img
                  src="/img/onboarding/company/google.svg"
                  style="margin-right: 18px"
                />
              </div>
              <div><img src="/img/onboarding/company/microsoft.svg" /></div>
              <div>
                <img
                  src="/img/onboarding/company/amazon.svg"
                  style="margin-right: 18px"
                />
              </div>
              <div><img src="/img/onboarding/company/slack.svg" /></div>
            </div>
          </div> -->
          <div class="service-plan">
            <div class="section-title">SERVICE PLAN</div>
            <div class="selected-plan">
              <div class="plan-details">
                <div class="plan-name">{{ project.work_mode }}</div>
                <div class="plan-info">team of 5</div>
                <div class="plan-info">100 work hours per week</div>
                <div class="plan-info">8 weeks per iteration</div>
                <div class="plan-price">$2,500 per weekly sprint</div>
              </div>
              <div class="plan-icon">
                <img src="/img/onboarding/upstart-icon.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import countryTimeZones from "countries-and-timezones";
import { mapState } from "vuex";
import shortName from "@/mixins/shortName";
import appMixin from "@/mixins/appMixin";
export default {
  components: {},
  mixins: [shortName, appMixin],
  data: () => ({
    profile: "/img/onboarding/company/profile.jpeg",
  }),

  created() {},
  methods: {
    getTimeZone(timeZone) {
      const timezone = countryTimeZones.getTimezone(`${timeZone}`);
      return timezone.dstOffsetStr.split(":")[0];
    },
    abbreviateFullName(name) {
      return name.length > 8 ? `Mr ${name.charAt(0)}` : name;
    },
    goToViewTeam(team_name) {
      if (team_name) {
        this.$router.push({
          name: "client_view_team",
          params: { team_name: team_name },
        });
      }
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    project() {
      return this.clientProject;
    },
    teamMembers() {
      return this.projectTeam;
    },
    teamBio() {
      return this.projectTeam[0];
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.page-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
}
.team-wrapper {
  margin-top: 48px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 24px 37px 24px 24px;
}
.top-info {
  display: flex;
}
.team-info {
  width: 350px;
  padding-right: 24px;
  text-align: left;
  border-right: solid 1px #f7f7f8;
}
.team-name {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
}
.team-experience {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}
.team-location {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  margin-top: 24px;
}
.team-bio {
  margin-top: 24px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 326px;
}
.action-div {
  margin-top: 61px;
}
.team-members {
  text-align: left;
  /* width: 456px; */
  padding-left: 24px;
}
.top-header {
  display: flex;
  justify-content: space-between;
}
.team-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.team-msg {
  font-size: 10px;
  line-height: 12px;
  text-align: right;
  color: #1fa564;
  width: 267px;
}
.team-developers {
  display: flex;
  flex-wrap: wrap;
  margin-top: 18px;
  column-gap: 48px;
  row-gap: 24px;
}
.dev-info {
  /* margin-bottom: 24px; */
}
.dev_name {
  margin-top: 4px;
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #1b1e22;
}

.dev_role {
  font-size: 12px;
  line-height: 140%;
  text-align: center;
  color: #979da5;
  margin-top: 1px;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
}
.bottom-info {
  margin-top: 12px;
  padding: 12px 0px;
  display: flex;
}
.team-skills {
  padding: 12px 24px 12px 0px;
  border-right: solid 1px #f7f7f8;
  text-align: left;
  width: 50%;
  /* width: 33%; */
}
.section-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.team-speciality {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.industries-list,
.team-stack {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;
  gap: 8px;
}
.stack-div,
.industry-div {
  background: #f7f7f8;
  border-radius: 30px;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  /* margin: 0px 8px 8px 0px; */
}
.team-industries {
  padding: 12px 24px;
  border-right: solid 1px #f7f7f8;
  text-align: left;
  width: 33%;
}
.team-experience {
  margin-top: 28px;
  display: flex;
  flex-wrap: wrap;
}
.team-experience img {
  margin-bottom: 16px;
}
.service-plan {
  padding: 12px 24px;
  width: 50%;
}
.selected-plan {
  display: flex;
  margin-top: 24px;
}
.plan-details {
}
.plan-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-transform: capitalize;
}
.plan-info {
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  margin-top: 4px;
}
.float-righ .plan-price {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  margin-top: 4px;
}
.plan-icon {
  margin-left: 30px;
}
@media (max-width: 1280px) {
  .team-developers {
    column-gap: 35px;
  }
  .team-bio {
    width: 285px;
  }
  .team-info {
    width: 310px;
  }
  .team-industries {
  }
  .team-members {
  }
  .team-skills {
  }
}
@media (max-width: 1440px) {
  .team-developers {
    column-gap: 43px;
  }
}
@media (min-width: 1440px) {
  .team-wrapper {
    width: 869px;
  }
}
</style>
