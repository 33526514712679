<template>
  <div class="project-drawer">
    <!--    <v-navigation-drawer v-model="drawer2" right absolute temporary> -->
    <v-navigation-drawer
      v-model="drawer2"
      right
      absolute
      temporary
      name="PlatformAgreement"
    >
      <div class="drawer-wrapper" style="width: 100%">
        <div class="toolbar">
          <div class="drawer-title">
            <span style="cursor:pointer"  @click="togglePADrawer"><img src="/img/close.svg"/></span>&nbsp;&nbsp;&nbsp; Platform Agreement</div>
          <div class="btn-wrapper">
            <!--  v-if="project.workspace_progress_level == 6" -->
            <button
              @click="togglePADrawer"
              class="grupa-disabled-btn"
              v-if="isPASignedProps()"
            >
              Agreed & Signed
            </button>
          

            <!--  v-if="project.workspace_progress_level != 6" -->
            <button
              class="grupa-blue-btn"
              v-if="!isPASignedProps()"
              @click="isPAgreementSignedFunction"
            >
              Agree & Sign
              <!--  <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinnerSLA"
              /> -->
            </button>
          </div>
        </div>
        <div class="nda-content">
          <PlatformAgreement
            ref="platformAgreement_"
            id="platformAgreement_"
            style="overflow: hidden; height: auto"
            v-bind="{ teamDetails, project }"
          />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import PlatformAgreement from "@/components/general/PlatformAgreement";

export default {
  props: {
    projectDoc: {
      type: Object,
      required: true,
    },
    teamDetails: {
      type: Object,
      required: true,
    },

    spinnerSLA: {
      type: Boolean,
      required: true,
    },

    project: {
      required: true,
    },

    isPAgreementSignedFunction: {
      type: Function,
      required: true,
    },

    isPASignedProps: {
      type: Function,
      required: true,
    },

    //
  },
  data() {
    return {
      drawer2: false,
    };
  },

  components: { PlatformAgreement },

  created() {},
  methods: {
    togglePADrawer() {
      this.drawer2 = !this.drawer2;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}
.drawer-wrapper {
  width: 596px;
  /* margin-top: 147px; */
}
.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.nda-content {
  padding: 28px 24px;
  text-align: left;
}
.nda-title {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-bottom: 16px;
}
.div-paragraph {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 16px;
}
.div-column {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 8px;
}
</style>

<style>
.project-drawer .v-overlay--absolute {
  position: fixed;
}
.project-drawer .v-navigation-drawer--absolute {
  position: fixed;
}
.project-drawer .v-navigation-drawer--open {
  min-width: 60% !important;
}
</style>
