var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("hr", {
        staticStyle: {
          "border-top": "1px solid rgb(129 120 120 / 57%)",
          "margin-top": "50px",
        },
      }),
      _vm._m(0),
      _c("div", { staticClass: "platform-agreement" }, [
        _c("div", { staticClass: "document-name" }, [
          _vm._m(1),
          _c("div", { staticClass: "product-name" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.project.project_name) +
                " " +
                _vm._s("<>") +
                " Grupa Platform Agreement.pdf\n      "
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "open-action",
            staticStyle: { "font-size": "12px", "margin-left": "32%" },
            on: { click: _vm.handlePADrawer },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(!_vm.isPASignedProps() ? "Review and Sign" : "Signed") +
                "\n    "
            ),
          ]
        ),
        !_vm.loadingPdf
          ? _c(
              "div",
              {
                staticClass: "open-action",
                on: { click: _vm.handleOpenAgreement },
              },
              [
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isPASignedProps(),
                      expression: "isPASignedProps()",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "0px", width: "25px" },
                  attrs: { src: "/img/download.png" },
                }),
              ]
            )
          : _vm._e(),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadingPdf,
              expression: "loadingPdf",
            },
          ],
          staticClass: "button-spinner",
          staticStyle: { "margin-left": "20px" },
          attrs: { src: "/img/lightbox/preloader.gif" },
        }),
      ]),
      _c("hr", {
        staticStyle: {
          "border-top": "1px solid rgb(129 120 120 / 57%)",
          "margin-top": "50px",
        },
      }),
      _vm._m(2),
      _c("div", { staticClass: "platform-agreement" }, [
        _c("div", { staticClass: "document-name" }, [
          _vm._m(3),
          _c("div", { staticClass: "product-name" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.project.project_name) +
                " " +
                _vm._s("<>") +
                "\n        "
            ),
            _c("span", [_vm._v(" Product Team Service Level Agreement.pdf ")]),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "open-action",
            staticStyle: { "font-size": "12px", "margin-left": "25%" },
            on: { click: _vm.handleSLADrawer },
          },
          [
            _vm._v(
              "\n      " +
                _vm._s(!_vm.isSLASignedProps() ? "Review and Sign" : "Signed") +
                "\n    "
            ),
          ]
        ),
        _vm.isSLASignedProps() || _vm.project.accept_sla
          ? _c(
              "div",
              {
                staticClass: "open-action",
                on: { click: _vm.handleOpenAgreementSLA },
              },
              [
                !_vm.loadingPdfSLA
                  ? _c("img", {
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "0px", width: "25px" },
                      attrs: { src: "/img/download.png" },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _c("img", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loadingPdfSLA,
              expression: "loadingPdfSLA",
            },
          ],
          staticClass: "button-spinner",
          staticStyle: { "margin-left": "20px" },
          attrs: { src: "/img/lightbox/preloader.gif" },
        }),
      ]),
      _c("div", { staticClass: "btn-container" }),
      _c(
        "DrawerSLA",
        _vm._b(
          { ref: "slaDrawer", attrs: { devUser: false } },
          "DrawerSLA",
          {
            teamDetails: _vm.teamDetails,
            projectDoc: _vm.projectDoc,
            isSLASignedFunction: _vm.isSLASignedFunction,
            spinnerSLA: _vm.spinnerSLA,
            handleSignSLA: _vm.handleSignSLA,
            project: _vm.project,
            equity: _vm.equity,
          },
          false
        )
      ),
      _c(
        "DrawerPA",
        _vm._b(
          { ref: "paDrawer", attrs: { devUser: false } },
          "DrawerPA",
          {
            teamDetails: _vm.teamDetails,
            projectDoc: _vm.projectDoc,
            isPAgreementSignedFunction: _vm.isPAgreementSignedFunction,
            isPASignedProps: _vm.isPASignedProps,
            spinnerSLA: _vm.spinnerSLA,
            //handleSignSLA,
            project: _vm.project,
            equity: _vm.equity,
          },
          false
        )
      ),
      _c(
        "PlatformAgreement",
        _vm._b(
          {
            ref: "platformAgreement",
            staticStyle: { overflow: "hidden", height: "0" },
            attrs: { id: "platformAgreement" },
          },
          "PlatformAgreement",
          {
            teamDetails: _vm.teamDetails,
            project: _vm.project,
            equity: _vm.equity,
          },
          false
        )
      ),
      _c(
        "GrupaSLA",
        _vm._b(
          {
            ref: "grupa_sla",
            staticStyle: { overflow: "hidden", height: "0" },
            attrs: { id: "grupa-sla", devUser: false },
          },
          "GrupaSLA",
          {
            teamDetails: _vm.teamDetails,
            projectDoc: _vm.projectDoc,
            project: _vm.project,
            equity: _vm.equity,
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grey-header" }, [
      _c("div", { staticClass: "alert alert-warning" }, [
        _vm._v("\n      Click to "),
        _c("b", [_vm._v("Review and Sign")]),
        _vm._v(" the platform agreement document\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "document-icon" }, [
      _c("img", { attrs: { src: "/img/doc-icons/pdf.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "grey-header" }, [
      _c("div", { staticClass: "alert alert-warning" }, [
        _vm._v("\n      Click to "),
        _c("b", [_vm._v("Review and Sign")]),
        _vm._v(" the service level agreement (SLA)\n      document\n    "),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "document-icon" }, [
      _c("img", { attrs: { src: "/img/doc-icons/pdf.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }