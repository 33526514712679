var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            right: "",
            absolute: "",
            temporary: "",
            name: "PlatformAgreement",
          },
          model: {
            value: _vm.drawer2,
            callback: function ($$v) {
              _vm.drawer2 = $$v
            },
            expression: "drawer2",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-wrapper", staticStyle: { width: "100%" } },
            [
              _c("div", { staticClass: "toolbar" }, [
                _c("div", { staticClass: "drawer-title" }, [
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.togglePADrawer },
                    },
                    [_c("img", { attrs: { src: "/img/close.svg" } })]
                  ),
                  _vm._v("    Platform Agreement"),
                ]),
                _c("div", { staticClass: "btn-wrapper" }, [
                  _vm.isPASignedProps()
                    ? _c(
                        "button",
                        {
                          staticClass: "grupa-disabled-btn",
                          on: { click: _vm.togglePADrawer },
                        },
                        [_vm._v("\n            Agreed & Signed\n          ")]
                      )
                    : _vm._e(),
                  !_vm.isPASignedProps()
                    ? _c(
                        "button",
                        {
                          staticClass: "grupa-blue-btn",
                          on: { click: _vm.isPAgreementSignedFunction },
                        },
                        [_vm._v("\n            Agree & Sign\n            ")]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "nda-content" },
                [
                  _c(
                    "PlatformAgreement",
                    _vm._b(
                      {
                        ref: "platformAgreement_",
                        staticStyle: { overflow: "hidden", height: "auto" },
                        attrs: { id: "platformAgreement_" },
                      },
                      "PlatformAgreement",
                      { teamDetails: _vm.teamDetails, project: _vm.project },
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }