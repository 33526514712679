var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "right-container" }, [
      _c("div", { staticClass: "grey-header" }, [
        _vm._v("How would you like to pay?"),
      ]),
      _c("div", { staticClass: "payment-options" }, [
        _c(
          "div",
          {
            staticClass: "payment-option-card",
            on: {
              click: function ($event) {
                return _vm.handleSelectOption("BI-SPRINT")
              },
            },
          },
          [
            _c("div", { staticClass: "div-option-name" }, [
              _c("img", {
                staticStyle: { "margin-right": "8px" },
                attrs: {
                  src:
                    "/img/dashboard/" +
                    (_vm.selectedOption == "BI-SPRINT"
                      ? "radio-checked.svg"
                      : "radio-unchecked.svg"),
                  alt: "",
                },
              }),
              _vm._v("\n          Bi-Weekly\n        "),
            ]),
            _c("div", { staticClass: "payment-description" }, [
              _vm._v(
                "\n          Pay an installment every 2 weeks, at the beginning of every 2\n          sprints\n        "
              ),
            ]),
            _c("div", { staticClass: "card-payment-value" }, [
              _vm._v(
                "\n          $" + _vm._s(_vm.formatPrice(_vm.biSprintPrice))
              ),
              _c("span", [_vm._v("/installment")]),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "payment-option-card",
            on: {
              click: function ($event) {
                return _vm.handleSelectOption("MONTHLY")
              },
            },
          },
          [
            _c("div", { staticClass: "div-option-name" }, [
              _c("img", {
                staticStyle: { "margin-right": "8px" },
                attrs: {
                  src:
                    "/img/dashboard/" +
                    (_vm.selectedOption == "MONTHLY"
                      ? "radio-checked.svg"
                      : "radio-unchecked.svg"),
                  alt: "",
                },
              }),
              _vm._v("\n          Monthly\n        "),
            ]),
            _c("div", { staticClass: "payment-description" }, [
              _vm._v(
                "\n          Pay per month, at the beginning of every new iteration.\n        "
              ),
            ]),
            _c("div", { staticClass: "card-payment-value" }, [
              _vm._v(
                "\n          $" + _vm._s(_vm.formatPrice(_vm.monthlyPrice))
              ),
              _c("span", [_vm._v("/installment")]),
            ]),
          ]
        ),
      ]),
      _c("div", { staticClass: "payment-details" }, [
        _c("div", { staticClass: "flex-section" }, [
          _c("div", { staticClass: "section-grey-text" }, [
            _vm._v("Charge per expert:"),
          ]),
          _vm.selectedOption == "MONTHLY"
            ? _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.project.price)) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.project.price / 2)) +
                    "\n        "
                ),
              ]),
        ]),
        _c("div", { staticClass: "flex-section section-margin" }, [
          _c("div", { staticClass: "section-grey-text" }, [
            _vm._v("Number of experts:"),
          ]),
          _c("div", { staticClass: "section-grey-text" }, [
            _vm._v(_vm._s(_vm.project.no_of_engineers)),
          ]),
        ]),
        _c("div", { staticClass: "divider-section" }),
        _c("div", { staticClass: "flex-section section-margin" }, [
          _c("div", { staticClass: "section-bold-text" }, [
            _vm._v("Sub-total:"),
          ]),
          _vm.selectedOption == "MONTHLY"
            ? _c("div", { staticClass: "section-bold-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.monthlyPrice)) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "section-bold-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.biSprintPrice)) +
                    "\n        "
                ),
              ]),
        ]),
        _c("div", { staticClass: "flex-section section-margin" }, [
          _c("div", { staticClass: "section-grey-text" }, [
            _vm._v("VAT (7.5%):"),
          ]),
          _vm.selectedOption == "MONTHLY"
            ? _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.vatCharges(_vm.monthlyPrice))) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.vatCharges(_vm.biSprintPrice))) +
                    "\n        "
                ),
              ]),
        ]),
        _c("div", { staticClass: "flex-section section-margin" }, [
          _c("div", { staticClass: "section-grey-text" }, [
            _vm._v("Transaction fee (3%):"),
          ]),
          _vm.selectedOption == "MONTHLY"
            ? _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(
                      _vm.formatPrice(_vm.transactionFee(_vm.monthlyPrice))
                    ) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "section-grey-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(
                      _vm.formatPrice(_vm.transactionFee(_vm.biSprintPrice))
                    ) +
                    "\n        "
                ),
              ]),
        ]),
        _c("div", { staticClass: "divider-section" }),
        _c("div", { staticClass: "flex-section section-margin" }, [
          _c("div", { staticClass: "section-bold-text" }, [
            _vm._v("Total Charge:"),
          ]),
          _vm.selectedOption == "MONTHLY"
            ? _c("div", { staticClass: "section-bold-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.amountDue(_vm.monthlyPrice))) +
                    "\n        "
                ),
              ])
            : _c("div", { staticClass: "section-bold-text" }, [
                _vm._v(
                  "\n          $" +
                    _vm._s(_vm.formatPrice(_vm.amountDue(_vm.biSprintPrice))) +
                    "\n        "
                ),
              ]),
        ]),
      ]),
      _c("div", { staticClass: "action-container" }, [
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            on: { click: _vm.handleKickoffFee },
          },
          [
            _vm._v(
              "\n        Pay $" +
                _vm._s(
                  _vm.selectedOption == "MONTHLY"
                    ? _vm.formatPrice(_vm.amountDue(_vm.monthlyPrice))
                    : _vm.formatPrice(_vm.amountDue(_vm.biSprintPrice))
                ) +
                "\n      "
            ),
          ]
        ),
        _vm._m(1),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-container" }, [
      _c("div", { staticClass: "section-header" }, [
        _vm._v("Start Development"),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v("Let’s start building!"),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      Payments are to be done before corresponding work starts.\n    "
        ),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      We hold your payments in escrow and disburse weekly to the team only\n      after you have confirmed that the sprint for that week has been\n      delivered.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stripe-copy-right" }, [
      _c("img", {
        staticStyle: { "margin-right": "5px" },
        attrs: { src: "/img/dashboard/stripe-lock.svg" },
      }),
      _vm._v("\n        Secured by "),
      _c("span", [_vm._v("Stripe")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }