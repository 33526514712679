var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-wrapper" }, [
    _vm._m(0),
    _c("div", { staticClass: "right-container" }, [
      _c("div", { staticClass: "grey-header" }, [
        _vm._v(
          "\n      Confirm the parameters below as agreed by you and your team\n    "
        ),
      ]),
      _c("div", [
        _c("div", { staticClass: "form-label" }, [
          _vm._v("How many experts are we to provide? *"),
        ]),
        _c(
          "div",
          { staticClass: "form-grouped" },
          _vm._l(_vm.teamNumber, function (number) {
            return _c(
              "div",
              {
                key: number,
                staticClass: "chip-rounded",
                class: {
                  "selected-chip-btn": _vm.form.no_of_engineers == number,
                },
                on: {
                  click: function ($event) {
                    _vm.form.no_of_engineers = number
                  },
                },
              },
              [
                _c("div", { staticClass: "chip-text" }, [
                  _vm._v(_vm._s(number)),
                ]),
              ]
            )
          }),
          0
        ),
      ]),
      _c("div", [
        _vm._m(1),
        _c(
          "div",
          { staticClass: "form-grouped" },
          [
            _c("ChipButton", {
              attrs: {
                text: "Yes",
                disabled: "true",
                buttonIcon: "/img/dashboard/yes-icon.svg",
                dashedBorder: true,
                selected: _vm.form.is_want_design_sprint == 1 ? true : false,
              },
              on: {
                click: function ($event) {
                  _vm.form.is_want_design_sprint = 1
                },
              },
            }),
            _c("ChipButton", {
              attrs: {
                text: "No",
                disabled: "true",
                buttonIcon: "/img/dashboard/no-icon.svg",
                dashedBorder: true,
                selected: _vm.form.is_want_design_sprint == 0 ? true : false,
              },
              on: {
                click: function ($event) {
                  _vm.form.is_want_design_sprint = 0
                },
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", [
        _vm._m(2),
        _c("div", { staticClass: "form-grouped" }, [
          _vm.isSavingComp
            ? _c("img", {
                staticClass: "button-spinner",
                staticStyle: { "margin-left": "20px", height: "20px" },
                attrs: { src: "/img/lightbox/preloader.gif" },
              })
            : _vm._e(),
          !_vm.isSavingComp
            ? _c("div", [
                _vm.isSLASigned
                  ? _c(
                      "div",
                      { staticClass: "form-grouped" },
                      _vm._l(_vm.compensationModels, function (item, index) {
                        return _c("ChipButton", {
                          key: index,
                          attrs: {
                            disabled: "true",
                            text: item.name,
                            dashedBorder: true,
                            buttonIcon:
                              "img/onboarding/specify_preferences/" + item.img,
                            selected:
                              item.value == _vm.form.compensation_id
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleSelectCompensation(item.value)
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
                _vm.isSLASigned
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-danger",
                        staticStyle: {
                          padding: "2px",
                          "text-align": "center",
                          "margin-top": "10px",
                          "font-size": "10px",
                        },
                      },
                      [
                        _vm._v(
                          "\n            Cant change after signing SLA\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                !_vm.isSLASigned
                  ? _c(
                      "div",
                      { staticClass: "form-grouped" },
                      _vm._l(_vm.compensationModels, function (item, index) {
                        return _c("ChipButton", {
                          key: index,
                          attrs: {
                            text: item.name,
                            dashedBorder: true,
                            buttonIcon:
                              "img/onboarding/specify_preferences/" + item.img,
                            selected:
                              item.value == _vm.form.compensation_id
                                ? true
                                : false,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.handleSelectCompensation(item.value)
                            },
                          },
                        })
                      }),
                      1
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("br"),
      _c("div", { staticClass: "product-pricing" }, [
        _vm._m(3),
        _c("div", { staticClass: "pricing-label" }, [
          _vm._v("\n        Cash component:\n        "),
          _vm.estimatedCost.cash
            ? _c("span", [
                _vm._v(
                  "$" +
                    _vm._s(_vm.estimatedCost.cash.toLocaleString()) +
                    " cash per month"
                ),
              ])
            : _vm._e(),
        ]),
        _c("div", { staticClass: "pricing-label" }, [
          _vm._v("\n        Equity component:\n        "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.estimatedCost.equity) +
                "% equity (3month cliff period)"
            ),
          ]),
        ]),
      ]),
      _c(
        "div",
        [
          _c(
            "Documents",
            _vm._b(
              {},
              "Documents",
              {
                equity: _vm.equity,
                isAgreedState: _vm.isAgreedState,
                isSLASignedFunction: _vm.isSLASignedFunction,
                isSLASignedProps: _vm.isSLASignedProps,
                isPAgreementSignedFunction: _vm.isPAgreementSignedFunction,
                isPASignedProps: _vm.isPASignedProps,
              },
              false
            )
          ),
        ],
        1
      ),
      _c("div", { staticClass: "agreement-container" }, [
        _c(
          "div",
          { staticClass: "checkbox-div" },
          [
            _c("v-checkbox", {
              model: {
                value: _vm.form.agreement,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "agreement", $$v)
                },
                expression: "form.agreement",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "agreement-text" }, [
          _vm._v(
            "\n        I accept and agree to have signed the documents and proceed with the\n        parameters as selected above\n      "
          ),
        ]),
      ]),
      _c("div", { staticClass: "button-container" }, [
        _c(
          "button",
          {
            staticClass: "grupa-disabled-btn",
            class: _vm.enableBtn ? "grupa-blue-btn" : null,
            attrs: { disabled: !_vm.enableBtn },
            on: { click: _vm.handleGenerateDoc },
          },
          [
            _vm._v("\n        All Agreement Signed\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinner,
                  expression: "spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-container" }, [
      _c("div", { staticClass: "section-header" }, [
        _vm._v("Review & Sign Agreements"),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      Confirm the parameters as agreed with your team to generate the mutual\n      platform agreement.\n    "
        ),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      The platform agreement would be sent to your email, to your team and can\n      also be accessible in the Documents tab.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-label" }, [
      _vm._v("\n        Do you want to run a Design Sprint? * "),
      _c("span", [_vm._v("What’s this?")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-label" }, [
      _vm._v("\n        Confirm compensation method * "),
      _c("span", [_vm._v("What’s this?")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-div-title" }, [
      _c("img", { attrs: { src: "/img/dashboard/rate-icon.svg", alt: "" } }),
      _vm._v("\n        Rate per expert\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }