var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-container" }, [
      _c(
        "div",
        { staticClass: "content-wrapper" },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-10" }, [
              _c(
                "div",
                { staticClass: "user-greeting" },
                [
                  _c(
                    "TimeZoneCheck",
                    _vm._b(
                      {},
                      "TimeZoneCheck",
                      { currentUser: _vm.currentUser },
                      false
                    )
                  ),
                  _vm._v(
                    " Hi\n            " +
                      _vm._s(_vm.shortenName(_vm.currentUser.name)) +
                      ",\n          "
                  ),
                ],
                1
              ),
              _c("div", { staticClass: "text-below" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      _vm.$moment().format("dddd, Do MMM YYYY, h:mm:ss a")
                    ) +
                    "\n          "
                ),
              ]),
            ]),
            _vm.browserTimezone != ""
              ? _c("div", { staticClass: "col-2 text-left" }, [
                  _c(
                    "div",
                    {
                      staticClass: "text-below",
                      staticStyle: { "margin-top": "0px", "font-size": "10px" },
                    },
                    [_vm._v("\n            Timezone\n          ")]
                  ),
                  _c("div", [_c("b", [_vm._v(_vm._s(_vm.browserTimezone))])]),
                  _vm.isUsingVPN && _vm.ipTimezone != ""
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            "margin-top": "15px",
                            color: "#d45b54",
                            "font-size": "12px",
                            "font-weight": "900",
                          },
                        },
                        [_vm._v("\n            VPN DETECTED\n          ")]
                      )
                    : _vm._e(),
                  _vm.isUsingVPN && _vm.ipTimezone != ""
                    ? _c(
                        "div",
                        {
                          staticClass: "text-below",
                          staticStyle: {
                            "margin-top": "0px",
                            "font-size": "10px",
                          },
                        },
                        [_vm._v("\n            VPN Timezone\n          ")]
                      )
                    : _vm._e(),
                  _c("div", [_c("b", [_vm._v(_vm._s(_vm.ipTimezone))])]),
                ])
              : _vm._e(),
          ]),
          _c("nylasPop"),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "card card-1" }, [
                _c("div", { staticClass: "card-text-block" }, [
                  !_vm.meetingCheckLoader
                    ? _c("div", { staticClass: "card-text" }, [
                        _c("div", { staticClass: "bold-text-head" }, [
                          _vm._v(_vm._s(_vm.textOne)),
                        ]),
                        _c("div", { staticClass: "light-text-head" }, [
                          _vm._v(
                            "\n                  " +
                              _vm._s(_vm.textTwo) +
                              "\n                "
                          ),
                        ]),
                        _vm.textThree
                          ? _c("div", { staticClass: "light-text-head" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm.textThree) +
                                  "\n                "
                              ),
                            ])
                          : _vm._e(),
                        _vm.buttonText
                          ? _c("div", { staticClass: "button-wrapper" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "action-button",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleContinueProject()
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(_vm.buttonText) +
                                      "\n                    "
                                  ),
                                  _c("img", {
                                    attrs: {
                                      src: "/img/dashboard/arrow-right.svg",
                                    },
                                  }),
                                ]
                              ),
                            ])
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.meetingCheckLoader,
                        expression: "meetingCheckLoader",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: {
                      "margin-right": "30px",
                      float: "right",
                      "margin-top": "50px",
                    },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]),
              ]),
            ]),
            _c("div", { staticClass: "column" }, [
              _c("div", { staticClass: "card-2" }, [
                _c(
                  "div",
                  { staticClass: "card-text-block card-content-left" },
                  [
                    _c("h5", [_vm._v("Refer & Earn")]),
                    _c("p", { staticClass: "p-2" }, [
                      _vm._v(
                        "\n                Get an Elite Product Engineering Team to bring your product\n                idea to life\n              "
                      ),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "button-plain",
                        on: { click: _vm.handleReferralNow },
                      },
                      [
                        _vm._v("\n                Refer now "),
                        _c("img", {
                          attrs: { src: "/img/onboarding/right-arrow.svg" },
                        }),
                      ]
                    ),
                  ]
                ),
                _vm._m(0),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "hangout-container" }, [
            _vm._m(1),
            _c("div", { staticClass: "hangout-bold-text" }, [
              _vm._v(
                "\n          Hangout with other Founders and VCs!\n        "
              ),
            ]),
            _c("div", { staticClass: "hangout-light-text" }, [
              _vm._v(
                "\n          Expand your network, bounce ideas, learn all you need to know about\n          building amazing products.\n        "
              ),
            ]),
            _c(
              "div",
              {
                staticClass: "join-conversation",
                on: { click: _vm.handleJoinConversation },
              },
              [
                _vm._v("\n          Join the Conversation\n          "),
                _c("img", {
                  attrs: { src: "/img/onboarding/blue-arrow.svg", alt: "" },
                }),
              ]
            ),
          ]),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-illustration" }, [
      _c("img", {
        attrs: { src: "/img/dashboard/referral-illustration.svg", alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/onboarding/sip_cup.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }