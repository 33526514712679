var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "page-layout",
          on: {
            click: function ($event) {
              _vm.statusSelect = false
            },
          },
        },
        [
          _c("div", { staticClass: "top-container" }, [
            _vm._m(0),
            _c("div", { staticClass: "right-con" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "grupa-grey-blue-btn",
                    on: { click: _vm.viewSchedule },
                  },
                  [_vm._v("\n            View Full Schedule\n          ")]
                ),
              ]),
            ]),
          ]),
          _c(
            "div",
            { staticClass: "sprint-schedule" },
            [
              _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "transparent",
                    color: "basil",
                    grow: "",
                  },
                  model: {
                    value: _vm.active_tab,
                    callback: function ($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab",
                  },
                },
                _vm._l(_vm.sprintArray, function (item, i) {
                  return _c(
                    "v-tab",
                    { key: i, attrs: { disabled: _vm.active_tab !== i } },
                    [
                      _c("div", { staticClass: "sprint-day" }, [
                        _vm._v("Day " + _vm._s(item.day)),
                      ]),
                      _c("div", { staticClass: "sprint-date" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.$moment(item.date).format("ddd, DD MMM")
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]
                  )
                }),
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.active_tab,
                    callback: function ($$v) {
                      _vm.active_tab = $$v
                    },
                    expression: "active_tab",
                  },
                },
                _vm._l(_vm.sprintArray, function (item, index) {
                  return _c("v-tab-item", { key: index }, [
                    _c("div", { staticClass: "tab-content" }, [
                      _c("div", { staticClass: "sprint-activities" }, [
                        _c("div", { staticClass: "sprint-header" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dailyActivities[index].title) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "text-below" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.dailyActivities[index].content) +
                              "\n              "
                          ),
                        ]),
                        _c("div", { staticClass: "item-list" }, [
                          _vm._v(
                            "\n                Timeframe:\n                "
                          ),
                          _c("span", [_vm._v("3 - 4 hours")]),
                        ]),
                        _c("div", { staticClass: "item-list" }, [
                          _vm._v(
                            "\n                Deliverables:\n                "
                          ),
                          _c("span", [_vm._v(_vm._s(_vm.deliverables))]),
                        ]),
                        _c("div", { staticClass: "item-list file-link" }, [
                          _vm._v(
                            "\n                Link to files:\n                "
                          ),
                          _c("div", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.link_to_file,
                                  expression: "link_to_file",
                                },
                              ],
                              staticClass: "no_outline",
                              staticStyle: { "margin-left": "8px" },
                              style:
                                _vm.link_to_file == ""
                                  ? "color: #C8CBD0;font-size: 14px;font-style: italic;font-weight: normal;"
                                  : null,
                              attrs: {
                                type: "text",
                                placeholder: "No link added yet",
                              },
                              domProps: { value: _vm.link_to_file },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) {
                                    return
                                  }
                                  _vm.link_to_file = $event.target.value
                                },
                              },
                            }),
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: "/img/dashboard/edit-pen.svg",
                                alt: "",
                              },
                            }),
                          ]),
                        ]),
                        _c("div", { staticClass: "sprint-status-div" }, [
                          _c("div", { staticClass: "item-list" }, [
                            _vm._v("Status:"),
                          ]),
                          item.client_status_id == 0 &&
                          _vm.currentDesignSprint !== null
                            ? _c("div", { staticClass: "menu-dropdown" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "default-status",
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleSelectStatus(
                                          $event,
                                          index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: "/img/sprint/select-status.svg",
                                      },
                                    }),
                                    _c("img", {
                                      staticStyle: {
                                        "margin-top": "2px",
                                        "margin-left": "3px",
                                      },
                                      attrs: {
                                        src: "/img/dashboard/chevron-down.svg",
                                      },
                                    }),
                                  ]
                                ),
                                _vm.statusSelect && _vm.dayStatus == index
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "dropdown-items",
                                        on: {
                                          click: function ($event) {
                                            return _vm.stayTop($event)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sprint-status-list",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDailyStatus(1)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/img/sprint/done.svg",
                                              },
                                            }),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "sprint-status-list",
                                            on: {
                                              click: function ($event) {
                                                return _vm.handleDailyStatus(2)
                                              },
                                            },
                                          },
                                          [
                                            _c("img", {
                                              attrs: {
                                                src: "/img/sprint/not-done.svg",
                                              },
                                            }),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ])
                            : _c(
                                "div",
                                {
                                  staticClass: "pick-dropdown",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleSelectStatus(
                                        $event,
                                        index
                                      )
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "selected-status",
                                      class: {
                                        "sprint-not-done":
                                          item.client_status_id == 2,
                                      },
                                    },
                                    [
                                      item.client_status_id == 1
                                        ? _c("img", {
                                            attrs: {
                                              src: "/img/sprint/done.svg",
                                            },
                                          })
                                        : _vm._e(),
                                      item.client_status_id == 2
                                        ? _c("img", {
                                            attrs: {
                                              src: "/img/sprint/not-done.svg",
                                            },
                                          })
                                        : _vm._e(),
                                      _c("img", {
                                        attrs: {
                                          src: "/img/sprint/dropdown-angle.svg",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm.statusSelect && _vm.dayStatus == index
                                    ? _c(
                                        "div",
                                        { staticClass: "dropdown-items" },
                                        [
                                          item.client_status_id == 2
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sprint-status-list",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDailyStatus(
                                                        1
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "/img/sprint/done.svg",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          item.client_status_id == 1
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "sprint-status-list",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.handleDailyStatus(
                                                        2
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("img", {
                                                    attrs: {
                                                      src: "/img/sprint/not-done.svg",
                                                    },
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              ),
                        ]),
                      ]),
                      _c("div", { staticClass: "design-sprint-info" }, [
                        _c("div", { staticClass: "design-sprint-date" }, [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: "/img/onboarding/schedule-icon.svg",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "full-details" }, [
                            _vm._v(
                              "\n                  Date:\n                  "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$moment(item.date)
                                    .format("dddd DD/MMM/YYYY")
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "full-details" }, [
                            _vm._v(
                              "\n                  Time:\n                  "
                            ),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm
                                    .$moment(item.start_time, "HH:mm")
                                    .format("hh:mm a")
                                )
                              ),
                            ]),
                          ]),
                          _c("div", { staticClass: "file-link full-details" }, [
                            _vm._v(
                              "\n                  Link:\n                  "
                            ),
                            _c("span", [_vm._v("https://goo.gl/hWrtygru")]),
                          ]),
                        ]),
                      ]),
                    ]),
                  ])
                }),
                1
              ),
            ],
            1
          ),
        ]
      ),
      _vm.openDialog
        ? _c(
            "ActivityModal",
            _vm._b(
              {},
              "ActivityModal",
              {
                closeDialog: _vm.closeDialog,
                dialogTitle: _vm.dialogTitle,
                handleDailyStatus: _vm.handleDailyStatus,
                currentDesignSprint: _vm.currentDesignSprint,
                yesSpinner: _vm.yesSpinner,
                noSpinner: _vm.noSpinner,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-con" }, [
      _c("div", { staticClass: "sprint-msg" }, [
        _vm._v(
          "\n          Design Sprint is a week-long collaborative process with activites\n          that ensures a product solves the problem form the users\n          perspective, is techniccally feasible and meets business\n          requirements. Your Product Team will draw up the schedule.\n        "
        ),
      ]),
      _c("div", { staticClass: "activity-msg" }, [
        _vm._v(
          "\n          Each activity is key. Ensure you (or a key decision maker) are\n          present at every session as we collaborate to bring your idea to\n          life.\n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }