<template>
  <div>
    <div class="section-wrapper">
      <div class="left-container">
        <div class="section-header">Pay & Kickoff</div>

        <div class="grey-text">
          Start work immediately with the planning and roadmapping phases of the
          building the product.
        </div>
        <div class="grey-text">
          These phases help the experts understand the product and sets a clear
          direction on expectations and deliverables.
        </div>
      </div>
      <div class="right-container">
        <div class="grey-header" style="display:none">
         
         <div class="alert  alert-warning">
          Here’s a copy of the Platform Agreement with Grupa, a copy has been
          sent to the team.

         </div> 
        </div>
        <div class="platform-agreement" style="display:none">
          <div class="document-name">
            <div class="document-icon">
              <img src="/img/doc-icons/pdf.svg" alt="" />
            </div>
            <div class="product-name">
              {{ project.project_name }} {{ `<>` }} Grupa Platform Agreement.pdf
            </div>
          </div>
          <div class="open-action" @click="handleOpenAgreement">Open</div>
        </div>
        <div class="section-divider" style="display:none"></div>
        <div class="payment-charge">
          <div class="total-charge">
            Total Charge: <span>${{ amountToPay.total }}</span>
          </div>
          <div class="payment-item-list">
            <div class="item-description">
              <div class="bullet-list">
                <img src="/img/dashboard/timer-icon.svg" alt="" />
              </div>
              <div class="description-text">
                <div class="description-title">Kickoff & Planning sessions</div>
                <div class="description-content">
                  During these sessions, the team gets to understand the product
                  fully and plan the project structure.
                </div>
              </div>
            </div>
            <div class="item-timeline">1 week</div>
            <div class="item-amount">
              ${{ amountToPay.per_sprint_fee.toLocaleString() }}
            </div>
          </div>
          <div class="payment-item-list">
            <div class="item-description">
              <div class="bullet-list">
                <img src="/img/dashboard/map-icon.svg" alt="" />
              </div>
              <div class="description-text">
                <div class="description-title">Product Roadmapping</div>
                <div class="description-content">
                  Roadmapping helps you and the team properly align on
                  deliverables and the future of the product.
                </div>
              </div>
            </div>
            <div class="item-timeline">1 week</div>
            <div class="item-amount">
              ${{ amountToPay.per_sprint_fee.toLocaleString() }}
            </div>
          </div>
        </div>
        <div class="btn-container">
          <button class="grupa-blue-btn" @click="handleMakePayment">
            Pay & Kickoff 
          </button>
          <div class="stripe-copy-right">
            <img
              src="/img/dashboard/stripe-lock.svg"
              style="margin-right: 5px"
            />
            Secured by <span>Stripe</span>
          </div>
        </div>
      </div>
    </div>
    <!-- <PlatformAgreement
      ref="platformAgreement"
      id="platformAgreement"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, project }"
    /> -->
    <!-- v-on:handleNextPage="handleNextPage" -->
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
//import ChipButton from "@/components/chipButton/chipButton";
//import PlatformAgreement from "@/components/general/PlatformAgreement";

export default {
  props: {
    handleMakePayment: {
      type: Function,
      required: true,
    },
  },
  //components: {  ChipButton ,  PlatformAgreement  },

  data: () => ({}),
  created() {},
  methods: {
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),
    handleOpenAgreement() {
      const elementHTML = this.$refs.platformAgreement.$refs.agreementContainer;
      const settings = this.pdfSettings("platformAgreement");
      const doc = html2pdf().set(settings).from(elementHTML).save();
      doc.output("datauristring").then((pdfAsString) => {
        let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
        var fileURL = URL.createObjectURL(output);
        window.open(fileURL);
      });
    },
    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
    pdfSettings(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `SLA_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProjectDoc",
      "clientProject",
      "projectTeam",
      "projectPrice",
      "startupStage",
    ]),
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("modalMgmt", ["paymentModalVisible"]),
    user() {
      return this.currentUser;
    },
    teamDetails() {
      let team = { ...this.projectTeam[0] };
      team.members = this.projectTeam;
      return team;
    },
    project() {
      const project = JSON.parse(JSON.stringify(this.clientProject));
      project.owner = this.user;
      // Remove when backend figure out pricing calculation
      if (this.projectPrice.startupStage) {
        project.startupStage = this.startupStage;
      }
      //
      return project;
    },
    amountToPay() {
      let { no_of_engineers, price } = this.clientProject;
     // const per_sprint_fee = (price * no_of_engineers) / 4;
      const per_sprint_fee = this.projectPrice.per_sprint_fee;

      //
      const total = (per_sprint_fee * 2).toLocaleString();
      return {
        per_sprint_fee,
        total,
      };
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.platform-agreement {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
}
.document-name {
  display: flex;
  align-items: center;

  gap: 24px;
}
.document-icon img {
  height: 32px;
  width: 32px;
}
.product-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.open-action {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
}
.section-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.payment-charge {
  margin-top: 24px;
}
.total-charge {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #979da5;
}
.total-charge span {
  color: #1b1e22;
}
.payment-item-list {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
}
.item-description {
  display: flex;
  gap: 12px;
}
.description-text {
  width: 323px;
}
.description-title {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.description-content {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 2px;
}
.btn-container {
  margin-top: 40px;
}
.stripe-copy-right {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
}
.stripe-copy-right span {
  color: #1b1e22;
}
</style>
