<template>
  <div>
    <div class="page-layout">
      <div class="section-cover">
        <div class="sla-page">
          This document captures the details of service expected. Please go
          through it and confirm that everything is in order.
        </div>
        <div class="sla-file">
          <div class="sla-title">
            Service Level Agreement
            <img src="/img/dashboard/expand-icon.svg" style="float: right" />
          </div>
          <div class="doc-scroll-wrapper">
            <div class="sla-content">
              In a few days Mr. Bingley returned Mr. Bennet’s visit, and sat
              about ten minutes with him in his library. He had entertained
              hopes of being admitted to a sight of the young ladies, of whose
              beauty he had heard much; but he saw only the father. The ladies
              were somewhat more fortunate, for they had the advantage of
              ascertaining from an upper window that he wore a blue coat, and
              rode a black horse.
            </div>
            <div class="sla-content">
              An invitation to dinner was soon afterwards dispatched; and
              already had Mrs. Bennet planned the courses that were to do credit
              to her housekeeping, when an answer arrived which deferred it all.
              Mr. Bingley was obliged to be in town the following day, and,
              consequently, unable to accept the honour of their invitation,
              etc. Mrs. Bennet was quite disconcerted. She could not imagine
              what business he could have in town so soon after his arrival in
              Hertfordshire; and she began to fear that he might be always
              flying about from one place to another, and never settled at
              Netherfield as he ought to be. Lady Lucas quieted her fears a
              little by starting the idea of his being gone to London only to
              get a large party for the ball; and a report soon followed that
              Mr. Bingley was to bring twelve ladies and seven gentlemen with
              him to the assembly. The girls grieved over such a number of
              ladies, but were comforted the day before the ball by hearing,
              that instead of twelve he brought only six with him from
              London—his five sisters and a cousin. And when the party entered
              the assembly room it consisted of only five altogether—Mr.
              Bingley, his two sisters, the husband of the eldest, and another
              young man.
            </div>
            <div class="sla-content">
              Mr. Bingley was good-looking and gentlemanlike; he had a pleasant
              countenance, and easy, unaffected manners. His sisters were fine
              women, with an air of decided fashion. His brother-in-law, Mr.
              Hurst, merely looked the gentleman; but his friend Mr. Darcy soon
              drew the attention of the room by his fine, tall person, handsome
              features, noble mien, and the
            </div>
            <div class="sla-content">
              Mr. Bingley was good-looking and gentlemanlike; he had a pleasant
              countenance, and easy, unaffected manners. His sisters were fine
              women, with an air of decided fashion. His brother-in-law, Mr.
              Hurst, merely looked the gentleman; but his friend Mr. Darcy soon
              drew the attention of the room by his fine, tall person, handsome
              features, noble mien, and the
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-cover {
  text-align: left;
  width: 521px;
}
.sla-page {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.sla-file {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  border-radius: 2px;
  padding: 24px;
}
.sla-title {
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #1b1e22;
  margin-bottom: 16px;
}
.sla-content {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-bottom: 24px;
}
.doc-scroll-wrapper {
  height: 473px;
  overflow-y: scroll;
  margin-top: 16px;
}
</style>
