<template>
  <div>
    <div class="tab-wrapper">
      <div class="plan-ahead-div">
        <div class="plan-ahead-title">Plan Ahead!</div>
        <div class="plan-text">
          Select the number of product iterations you’d like to build out in
          this version.
        </div>
        <div class="plan-text">
          This helps us customize your experience and lock down your product
          team for the period it will take to build these iterations.
        </div>
        <div class="plan-text" style="color: #1fa564">
          You can change this at any time.
        </div>
        <div class="slider-container">
          <div class="slider-title">Move the slider.</div>
          <div class="slider-div">
            <v-slider
              class="itr_slider"
              v-model="form.iterations"
              @change="updateSelectedIteration()"
              :tick-labels="iterationLabels"
              :max="iterationLabels.length"
              min="1"
              step="1"
              ticks="always"
              tick-size="4"
            ></v-slider>
          </div>
        </div>
        <div class="button-wrap">
          <button
            class="grupa-white-btn"
            style="background: #f7f7f8; margin-right: 24px"
            @click="switchTab('payment')"
          >
            Skip for Now
          </button>
          <button class="grupa-blue-btn" @click="handlePlanAhead">
            Subscribe
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 5px"
              v-if="spinnerBtn"
              class="button-spinner"
            />
          </button>
        </div>
      </div>
      <div class="service-plan-div">
        <div class="service-plan-info">
          <div class="service-box-title">SERVICE PLAN</div>
          <div class="plan-name">{{ project.experience }}</div>
          <div class="plan-info">team of 5</div>
          <div class="plan-info">100 work hours per week</div>
          <div class="plan-info">
            {{ project.iteration_cycle }} weeks per iteration
          </div>
          <div class="plan-price">
            ${{
              (project.price / project.iteration_cycle).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                }
              )
            }}
            per weekly sprint
          </div>
          <div class="plan-image">
            <img src="/img/onboarding/upstart-icon.svg" />
          </div>
        </div>
        <div class="team-lock">
          Your team would be locked down for
          <span
            >{{ project.iteration_cycle * form.iterations }} weeks ({{
              form.iterations
            }}
            iterations)</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { planAheadSubcription } from "@/api/client";
export default {
  props: {
    switchTab: {
      type: Function,
    },
  },
  components: {},

  data: () => ({
    iterationLabels: [],
    availableIterations: [],
    spinnerBtn: false,
    form: {
      iterations: 1,
      iterationPrice: 0,
      currentCharge: 0,
      paymentOption: "1",
      selectedIteration: [["sprint 0"]],
      iterationSelected: ["iteration 0"],
      iterationTracker: 0,
      max_subscribed_iteration: null,
    },
  }),
  created() {
    let { max_subscribed_iteration } = this.project;
    this.updateMaxSubIteration(
      max_subscribed_iteration ? max_subscribed_iteration : 0
    );
    this.createItrLabel();
    this.availableIterations = this.iterations.slice(0, this.form.iterations);
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    updateMaxSubIteration(iterationId) {
      let itrIndex = this.iterations.findIndex(
        (iteration) => iteration.id == iterationId
      );
      this.form.iterations = itrIndex >= 0 ? itrIndex + 1 : 1;
      this.form.max_subscribed_iteration =
        iterationId !== 0 ? iterationId : this.iterations[0].id;
    },
    createItrLabel() {
      this.iterations.forEach((version, index) => {
        this.iterationLabels.push(`Itr ${index + 1}`);
      });
    },
    updateSelectedIteration() {
      const { iterations } = this.form;
      const projIterations = this.iterations;
      this.availableIterations = this.iterations.slice(0, this.form.iterations);
      this.form.max_subscribed_iteration =
        projIterations[this.form.iterations - 1].id;
    },
    handlePlanAhead() {
      let payload = {
        project_id: this.$route.params.id,
        max_subscribed_iteration: this.form.max_subscribed_iteration,
      };
      this.spinnerBtn = true;
      planAheadSubcription(payload)
        .then((response) => {
          this.spinnerBtn = false;
          if (response.data.status == 1) {
            // this.text="Subcription Saved!!!";
            // this.snackbar =true;
            // this.msgColor='success';
            this.switchTab("payment");
            this.updateMaxSubIteration(
              response.data.project.max_subscribed_iteration
            );
          }
        })
        .catch((error) => {
          this.spinnerBtn = false;
        });
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),
    project() {
      return this.clientProject;
    },
    iterations() {
      return this.clientProjectDoc.documents.iterations;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.tab-wrapper {
  display: flex;
  justify-content: space-between;
}
.plan-ahead-div {
  width: 521px;
}
.plan-ahead-title {
  margin-top: 24px;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.plan-text {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
}
.slider-container {
  margin-top: 48px;
}
.slider-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.slider-div {
  margin-top: 24px;
}
.service-plan-info {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  min-width: 260px;
  margin-top: 24px;
  position: relative;
}
.service-box-title {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.plan-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
  text-transform: capitalize;
}
.plan-price {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
  margin-top: 4px;
}
.plan-info {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 4px;
}
.plan-image {
  position: absolute;
  right: 31px;
  top: 54px;
}
.team-lock {
  width: 260px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 19px;
}
.team-lock span {
  color: #1b1e22;
}
.button-wrap {
  display: flex;
  justify-content: center;
  margin-top: 48px;
}
</style>
<style>
.itr_slider .v-slider__track__container {
  height: 8px;
}
.itr_slider .v-slider__thumb {
  width: 36px;
  height: 36px;
  background: black;
}
.v-slider__track,
.v-slider__track-fill {
  height: 8px;
}
.itr_slider .theme--light.v-input--slider .v-slider__ticks {
  border-color: #c8cbd0;
  box-shadow: 1px 2px 7px rgba(2, 73, 140, 0.12);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}
</style>    