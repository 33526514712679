var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progress-container" }, [
    _c("div", { staticClass: "user-greeting" }, [
      _vm._v("Hi " + _vm._s(_vm.shortenName(_vm.currentUser.name)) + ","),
    ]),
    _c("div", { staticClass: "text-below" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.$moment().format("dddd, Do MMM YYYY, h:mm:ss a")) +
          "\n  "
      ),
    ]),
    _c(
      "div",
      { staticClass: "stages-container" },
      _vm._l(_vm.productStages, function (step, index) {
        return _c("div", { key: step.stage, staticClass: "tab-box" }, [
          _c(
            "div",
            {
              staticClass: "tab-button",
              class: {
                "active-tab": _vm.progressLevel == step.stage,
                "completed-tab":
                  _vm.progressLevel == 6
                    ? 3 > step.stage
                    : _vm.progressLevel > step.stage ||
                      (_vm.clientProject.accept_roadmap ? true : false),
              },
            },
            [_vm._v("\n        " + _vm._s(step.name) + "  \n      ")]
          ),
          index < _vm.productStages.length - 1
            ? _c("div", { staticClass: "tab-line" })
            : _vm._e(),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }