var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "section-wrapper" }, [
      _c("div", { staticClass: "page-msg" }, [
        _c("div", { staticClass: "container-title" }, [
          _vm._v("Draw Product Roadmap"),
        ]),
        _c("div", { staticClass: "description" }, [
          _vm._v(
            "\n        The Product Roadmap maps out the direction of the product development\n        over time. Based on learnings from the design sprint, priority is\n        given to the most important ideas, features or user stories.\n      "
          ),
        ]),
        _vm.project.share_roadmap != 1
          ? _c(
              "div",
              {
                staticClass: "alert alert-warning",
                staticStyle: { "margin-top": "30px" },
              },
              [
                _vm._v(
                  "\n        You are not expected to do anything here! Its expected that the experts would draw his roadmap within 1 - 2 days after his invitation "
                ),
              ]
            )
          : _vm._e(),
        _c("div", { staticClass: "btn-wrapper" }, [
          _c(
            "button",
            {
              staticClass: "grupa-disabled-btn",
              class: { "grupa-blue-btn": _vm.project.share_roadmap == 1 },
              attrs: { disabled: _vm.project.share_roadmap == 0 },
              on: { click: _vm.handleCheckRoadmap },
            },
            [_vm._v("\n          Check Roadmap\n        ")]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }