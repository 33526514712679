var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "section-wrapper" }, [
      _vm._m(0),
      _c("div", { staticClass: "right-container" }, [
        _vm._m(1),
        _c(
          "div",
          {
            staticClass: "platform-agreement",
            staticStyle: { display: "none" },
          },
          [
            _c("div", { staticClass: "document-name" }, [
              _vm._m(2),
              _c("div", { staticClass: "product-name" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.project.project_name) +
                    " " +
                    _vm._s("<>") +
                    " Grupa Platform Agreement.pdf\n          "
                ),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "open-action",
                on: { click: _vm.handleOpenAgreement },
              },
              [_vm._v("Open")]
            ),
          ]
        ),
        _c("div", {
          staticClass: "section-divider",
          staticStyle: { display: "none" },
        }),
        _c("div", { staticClass: "payment-charge" }, [
          _c("div", { staticClass: "total-charge" }, [
            _vm._v("\n          Total Charge: "),
            _c("span", [_vm._v("$" + _vm._s(_vm.amountToPay.total))]),
          ]),
          _c("div", { staticClass: "payment-item-list" }, [
            _vm._m(3),
            _c("div", { staticClass: "item-timeline" }, [_vm._v("1 week")]),
            _c("div", { staticClass: "item-amount" }, [
              _vm._v(
                "\n            $" +
                  _vm._s(_vm.amountToPay.per_sprint_fee.toLocaleString()) +
                  "\n          "
              ),
            ]),
          ]),
          _c("div", { staticClass: "payment-item-list" }, [
            _vm._m(4),
            _c("div", { staticClass: "item-timeline" }, [_vm._v("1 week")]),
            _c("div", { staticClass: "item-amount" }, [
              _vm._v(
                "\n            $" +
                  _vm._s(_vm.amountToPay.per_sprint_fee.toLocaleString()) +
                  "\n          "
              ),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "btn-container" }, [
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn",
              on: { click: _vm.handleMakePayment },
            },
            [_vm._v("\n          Pay & Kickoff \n        ")]
          ),
          _vm._m(5),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-container" }, [
      _c("div", { staticClass: "section-header" }, [_vm._v("Pay & Kickoff")]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n        Start work immediately with the planning and roadmapping phases of the\n        building the product.\n      "
        ),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n        These phases help the experts understand the product and sets a clear\n        direction on expectations and deliverables.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "grey-header", staticStyle: { display: "none" } },
      [
        _c("div", { staticClass: "alert  alert-warning" }, [
          _vm._v(
            "\n        Here’s a copy of the Platform Agreement with Grupa, a copy has been\n        sent to the team.\n\n       "
          ),
        ]),
      ]
    )
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "document-icon" }, [
      _c("img", { attrs: { src: "/img/doc-icons/pdf.svg", alt: "" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-description" }, [
      _c("div", { staticClass: "bullet-list" }, [
        _c("img", { attrs: { src: "/img/dashboard/timer-icon.svg", alt: "" } }),
      ]),
      _c("div", { staticClass: "description-text" }, [
        _c("div", { staticClass: "description-title" }, [
          _vm._v("Kickoff & Planning sessions"),
        ]),
        _c("div", { staticClass: "description-content" }, [
          _vm._v(
            "\n                During these sessions, the team gets to understand the product\n                fully and plan the project structure.\n              "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "item-description" }, [
      _c("div", { staticClass: "bullet-list" }, [
        _c("img", { attrs: { src: "/img/dashboard/map-icon.svg", alt: "" } }),
      ]),
      _c("div", { staticClass: "description-text" }, [
        _c("div", { staticClass: "description-title" }, [
          _vm._v("Product Roadmapping"),
        ]),
        _c("div", { staticClass: "description-content" }, [
          _vm._v(
            "\n                Roadmapping helps you and the team properly align on\n                deliverables and the future of the product.\n              "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stripe-copy-right" }, [
      _c("img", {
        staticStyle: { "margin-right": "5px" },
        attrs: { src: "/img/dashboard/stripe-lock.svg" },
      }),
      _vm._v("\n          Secured by "),
      _c("span", [_vm._v("Stripe")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }