var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "Navbar",
        _vm._b({}, "Navbar", { stageTitle: _vm.stageTitle }, false),
        [
          _vm.project.workspace_progress_level == 1 &&
          _vm.project.is_nda == 1 &&
          _vm.isMeetingDone()
            ? _c("div", { staticClass: "btn-wrap" }, [
                _c("button", { staticClass: "grupa-white-blue-btn" }, [
                  _vm._v("Choose A Different Team"),
                ]),
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    staticStyle: { "margin-left": "16px" },
                    on: { click: _vm.handleChooseThisTeam },
                  },
                  [
                    _vm._v(
                      "\n        Continue With " +
                        _vm._s(_vm.teamMembers[0].team_name) +
                        "\n        "
                    ),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btnSpinner,
                          expression: "btnSpinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          (_vm.isDesignOver && _vm.project.workspace_progress_level == 3) ||
          _vm.project.workspace_progress_level == 4
            ? _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-disabled-btn",
                    class: { "grupa-blue-btn": _vm.project.share_roadmap == 1 },
                    attrs: {
                      disabled:
                        _vm.project.share_roadmap == 0 ||
                        _vm.getDay3OfDesignSprint,
                    },
                    on: { click: _vm.handleCheckRoadmap },
                  },
                  [
                    _vm._v("\n        Check Roadmap\n        "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btnSpinner,
                          expression: "btnSpinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.project.workspace_progress_level == 5 &&
          !_vm.project.team_accept_sla
            ? _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-disabled-btn",
                    attrs: { disabled: true },
                  },
                  [_vm._v("\n        Waiting for Team\n      ")]
                ),
              ])
            : _vm._e(),
          _vm.project.workspace_progress_level == 5 &&
          _vm.project.team_accept_sla
            ? _c("div", { staticClass: "btn-wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "grupa-blue-btn",
                    on: { click: _vm.handleAcceptSla },
                  },
                  [
                    _vm._v("\n        Agree & Execute\n        "),
                    _c("img", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.btnSpinner,
                          expression: "btnSpinner",
                        },
                      ],
                      staticClass: "button-spinner",
                      staticStyle: { "margin-left": "20px" },
                      attrs: { src: "/img/lightbox/preloader.gif" },
                    }),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "prekickoff-wrapper" },
        [
          _vm.getDay3OfDesignSprint
            ? _c(
                "DesignSprintPayment",
                _vm._b(
                  {},
                  "DesignSprintPayment",
                  {
                    openLearnMore: _vm.openLearnMore,
                    openStripeModal: _vm.openStripeModal,
                  },
                  false
                )
              )
            : _vm._e(),
          _c("ProgessBar"),
          _c(
            "div",
            { staticClass: "right-container" },
            [
              _vm.project.is_nda < 1 ? _c("ProjectTeam") : _vm._e(),
              _vm.project.is_nda == 1 &&
              _vm.project.workspace_progress_level < 3
                ? _c("MeetTheTeam")
                : _vm._e(),
              _vm.project.workspace_progress_level == 3 && !_vm.isDesignOver
                ? _c(
                    "DesignSprint",
                    _vm._b(
                      {},
                      "DesignSprint",
                      { sprintArray: _vm.sprintArray },
                      false
                    )
                  )
                : _vm._e(),
              (_vm.isDesignOver && _vm.project.workspace_progress_level == 3) ||
              _vm.project.workspace_progress_level == 4
                ? _c("ProductRoadMap")
                : _vm._e(),
              _vm.project.workspace_progress_level == 5 &&
              !_vm.project.team_accept_sla
                ? _c("TeamSLA")
                : _vm._e(),
              _vm.project.workspace_progress_level == 5 &&
              _vm.project.team_accept_sla
                ? _c("ClientSLA")
                : _vm._e(),
              _vm.project.workspace_progress_level == 6
                ? _c("StartProductDevelopment")
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm.paymentModalVisible ? _c("PaymentSuccess") : _vm._e(),
      _vm.isLearnMore
        ? _c(
            "LearnMoreModal",
            _vm._b(
              {},
              "LearnMoreModal",
              { project: _vm.project, closeDialog: _vm.closeDialog },
              false
            )
          )
        : _vm._e(),
      _vm.openStripe
        ? _c(
            "StripeModal",
            _vm._b(
              {
                attrs: { amount: _vm.designSprintBalance },
                on: { close: _vm.closeStripeModal },
              },
              "StripeModal",
              {
                user: _vm.user,
                modalType: _vm.modalType,
                paymentInfo: _vm.paymentInfo,
                userCreditCard: _vm.userCreditCard,
              },
              false
            )
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }