var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vetting-progress" }, [
      _c(
        "div",
        { staticClass: "progress-wrapper" },
        _vm._l(_vm.vettingStages, function (stage, k) {
          return _c("div", { key: k }, [
            k == 0
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/img/onboarding/" +
                          (_vm.progressLevel == 1 && !_vm.isMeetingDone()
                            ? "green-dot.svg"
                            : "tick-icon.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        style:
                          _vm.progressLevel == 1 && !_vm.isMeetingDone()
                            ? "color: #1fa564;"
                            : "color: #1b1e22;",
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(stage) + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "progress-line",
                    style:
                      _vm.progressLevel == 1 && !_vm.isMeetingDone()
                        ? "border-left: solid 3px #54CF94;"
                        : "border-left: solid 3px #1b1e22",
                  }),
                ])
              : _vm._e(),
            k == 1
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/img/onboarding/" +
                          (_vm.progressLevel < 2 && _vm.isMeetingDone()
                            ? "green-dot.svg"
                            : _vm.progressLevel == k
                            ? "black-dot.svg"
                            : "tick-icon.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        style:
                          _vm.progressLevel < 2 && _vm.isMeetingDone()
                            ? "color: #1fa564;"
                            : _vm.progressLevel == k
                            ? "color: #979da5;"
                            : "color: #1b1e22;",
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(stage) + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  _c("div", {
                    staticClass: "progress-line",
                    style:
                      _vm.progressLevel < 2 && _vm.isMeetingDone()
                        ? "border-left: solid 3px #54CF94;"
                        : _vm.progressLevel == k
                        ? "solid 3px #e4e5e7;"
                        : "border-left: solid 3px #1b1e22",
                  }),
                ])
              : _vm._e(),
            k > 1
              ? _c("div", [
                  _c("div", { staticClass: "progress-stage" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "/img/onboarding/" +
                          (_vm.progressLevel == k + 1
                            ? "green-dot.svg"
                            : _vm.progressLevel < k + 1
                            ? "black-dot.svg"
                            : "tick-icon.svg"),
                        alt: "",
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "progress-name",
                        style:
                          _vm.progressLevel == k + 1
                            ? "color: #1fa564;"
                            : _vm.progressLevel > 3
                            ? "color: #1b1e22;"
                            : "",
                      },
                      [
                        _vm._v(
                          "\n              " + _vm._s(stage) + "\n            "
                        ),
                      ]
                    ),
                  ]),
                  k < 5
                    ? _c("div", {
                        staticClass: "progress-line",
                        style:
                          _vm.progressLevel == k + 1
                            ? "border-left: solid 3px #54CF94;"
                            : _vm.progressLevel > k
                            ? "border-left: solid 3px #1b1e22;"
                            : "",
                      })
                    : _vm._e(),
                ])
              : _vm._e(),
          ])
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }