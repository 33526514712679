var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "container-title" }, [
        _vm._v("Start Product Development"),
      ]),
      _c("div", { staticClass: "subcribe-text" }, [
        _vm._v(
          "\n      Subscribe and Pay to build out iterations of your product as outlined in\n      the Product Roadmap.\n    "
        ),
      ]),
      _c("div", { staticClass: "tab-container" }, [
        _c("div", { staticClass: "tabs-wrap" }, [
          _c(
            "div",
            {
              staticClass: "tab-div",
              class: { "active-tab": _vm.activeTab == "subcribe" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("subcribe")
                },
              },
            },
            [_vm._v("\n          1. Subscribe\n        ")]
          ),
          _c(
            "div",
            {
              staticClass: "tab-div",
              class: { "active-tab": _vm.activeTab == "payment" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("payment")
                },
              },
            },
            [_vm._v("\n          2. Select Payment Plan\n        ")]
          ),
        ]),
        _c(
          "div",
          { staticClass: "tabs-content" },
          [
            _vm.activeTab == "subcribe"
              ? _c(
                  "SubscribeComponent",
                  _vm._b(
                    {},
                    "SubscribeComponent",
                    { switchTab: _vm.switchTab },
                    false
                  )
                )
              : _vm._e(),
            _vm.activeTab == "payment" ? _c("PaymentComponent") : _vm._e(),
            _vm.successMsg
              ? _c("AlertSuccess", { attrs: { time: "7" } })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }