<template>
  <div>
    <div class="section-wrapper">
      <div class="left-container">
        <div class="section-header">Finalize SLA</div>

        <div class="grey-text">Let’s start building!</div>
        <div class="grey-text">
          This document captures the details of service expected. Please go
          through it and confirm that everything is in order.
        </div>
      </div>
      <div class="right-container">
        <div class="grey-header">
          Here’s a copy of the SLA, click to open the document.
        </div>
        <!-- Content -->
        <div class="platform-agreement">
          <div class="document-name">
            <div class="document-icon">
              <img src="/img/doc-icons/pdf.svg" alt="" />
            </div>
            <div class="product-name">
              {{ project.project_name }} {{ `<>` }}
              <span> Product Team Service Level Agreement.pdf </span>
            </div>
          </div>
          <div class="open-action" @click="handleOpenAgreement">Open</div>
        </div>
        <div class="btn-container">
          <button @click="handleSignSLA" class="grupa-blue-btn">
            Agree & Execute

            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
      </div>
    </div>
    <GrupaSLA
      ref="grupa_sla"
      id="grupa-sla"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, projectDoc }"
      v-bind:devUser="false"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
import { acceptSLA } from "@/api";

import GrupaSLA from "@/components/general/grupaSLA";

export default {
  props: {},
  components: {
    GrupaSLA,
  },
  data: () => ({
    spinner: false,
  }),
  created() {},
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    handleSignSLA() {
      this.spinner = true;
      let project_id = this.$route.params.id;
      let data = { project_id };
      acceptSLA(data)
        .then((resp) => {
          this.updateProject(resp.data.data);
          this.setSuccessMsg("Service Level Agreement Signed");
        })
        .catch((err) => {})
        .finally(() => {
          this.spinner = false;
        });
    },
    handleOpenAgreement() {
      const elementHTML = this.$refs.grupa_sla.$refs.slaWrapper;
      const settings = this.pdfSettings("platformAgreement");

      const doc = html2pdf().set(settings).from(elementHTML).save();
      doc.output("datauristring").then((pdfAsString) => {
        let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
        var fileURL = URL.createObjectURL(output);
        window.open(fileURL);
      });
    },
    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
    pdfSettings(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `SLA_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProjectDoc",
      "clientProject",
      "projectTeam",
    ]),
    teamDetails() {
      if (this.clientProject.workspace_progress_level > 4) {
        let team = { ...this.projectTeam[0] };
        team.members = this.projectTeam;
        return team;
      }
      return [];
    },
    project() {
      return this.clientProject;
    },
    projectDoc() {
      let projectDoc = { ...this.clientProjectDoc.documents };
      projectDoc.project = this.clientProjectDoc.project;
      return projectDoc;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.platform-agreement {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
}
.document-name {
  display: flex;
  align-items: center;

  gap: 24px;
}
.document-icon img {
  height: 32px;
  width: 32px;
}
.product-name {
  font-weight: bold;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.product-name span {
  color: #979da5;
}
.open-action {
  cursor: pointer;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #0781f2;
}
.btn-container {
  margin-top: 40px;
}
</style>
