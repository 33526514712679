<template>
  <div>
    <div class="page-layout">
      <div class="section-cover">
        <div class="schedule-meeting">
          Schedule a 30-minute evlauation call with
          <span>
            {{ teamMembers[0].team_name }}
          </span>
        </div>
        <div class="meeting-info">
          We advise that this is done within 48 hours, as the team is on
          standby.
        </div>
        <div class="calendar-embed">
          <div
            v-if="project.preview_meeting_start_date == null"
            class="schedule-text"
            @click="handleCalendly"
          >
            Click Here To Schedule Meeting
          </div>
          <div v-else class="meeting-details">
            <img src="/img/onboarding/schedule-icon.svg" />
            <div class="meeting-time">
              Meeting scheduled for
              <span style="">
                {{
                  $moment(project.preview_meeting_start_date).format(
                    "dddd, MMMM Do YYYY, h:mm:ss a"
                  )
                }}
              </span>
            </div>
            <div class="meeting-link">
              Meeting link:
              <span>
                <a target="_blank" :href="project.preview_meeting_hangout_link">
                  {{ project.preview_meeting_hangout_link }}
                </a>
              </span>
            </div>
          </div>
        </div>
        <div class="bottom-div">
          <div class="meeting-goal">
            <div class="meeting-header">MEETING OBJECTIVES</div>
            <div v-for="(obj, i) in objectives" :key="i" class="objectives">
              <img src="/img/onboarding/grey-checked.svg" />
              {{ obj }}
            </div>
          </div>
          <div
            class="meeting-attendees"
            v-if="project.preview_meeting_start_date !== null"
          >
            <div class="meeting-header">MEETING INVITEES (5)</div>
            <div
              class="members-box"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.name"
            >
              <div class="team-member">
                <div class="profile-image">
                  <v-avatar
                    :size="32"
                    color="grey lighten-4"
                    class="profile_bg"
                    :style="{
                      backgroundImage: `url(${
                        $imageUrl + member.profile_picture || test
                      })`,
                    }"
                  ></v-avatar>
                </div>
                <div class="dev-details">
                  <div class="dev-name">{{ member.name }}</div>
                  <div class="dev-role">
                    {{ member.team_lead == 1 ? "Team Lead/" : "" }}
                    {{ member.team_role }}
                  </div>
                </div>
              </div>
            </div>
            <div class="members-box">
              <div class="team-member">
                <div class="profile-image">
                  <div
                    class="grupa-icon user-name-icon"
                    style="height: 32px; width: 32px"
                  >
                    GR
                  </div>
                </div>
                <div class="dev-details">
                  <div class="dev-name">Grupa</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <CalendlyModal
      v-if="showCalendly"
      v-bind:firstMeeting="true"
      v-on:closeModal="closeCalendly"
      v-bind="{ loadCalendly }"
      v-bind:user="currentUser"
    />
    <MeetingSuccess
      v-if="isMeetingSuccess"
      v-on:closeModal="closeSuccessModal"
      v-bind="{ meetingLoader, meetingPayload }"
    />
    <RateMeetingDialog
      meetingTitle="Product Team"
      v-if="openDialog"
      v-bind="{
        closeDialog,
        submitRating,
        ratingSpinner,
        ratingSucess,
        failForm,
        isMeetingHold,
        handleMeetingStatus,
        isRatingForm,
      }"
      v-bind:form="ratingForm"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import CalendlyModal from "@/components/calendlyModal";
import MeetingSuccess from "@/components/meetingSuccess";
import { calendlyEndpoint } from "@/api/calendlyApi";
import { teamPreviewMeeting, rateFirstMeeting } from "@/api";
import RateMeetingDialog from "@/components/Modals/rateMeeting";

export default {
  props: ["stage", "changeStage"],
  components: {
    CalendlyModal,
    MeetingSuccess,
    RateMeetingDialog,
  },
  data: () => ({
    openDialog: false,
    objectives: [
      "Meet the Team.",
      "Discuss work to be done.",
      "Check cultural fit.",
      "Change team if you’re not satisfied.",
    ],
    showCalendly: false,
    meetingLoader: true,
    meetingPayload: null,
    isMeetingSuccess: false,
    loadCalendly: true,
    profile: "/img/onboarding/company/profile.jpeg",
    ratingForm: {
      value: "",
      comment: "",
    },
    ratingSpinner: false,
    test: "/img/profile.svg",
    failForm: {
      reason: "",
    },
    isMeetingHold: false,
    ratingSucess: false,
    isRatingForm: true,
  }),
  created() {
    window.addEventListener("message", async (e) => {
      if (this.isCalendlyEvent(e)) {
        this.$developConsole("Event Scheduled", e.data);
        //  calendly.event_scheduled calendly.date_and_time_selected
        if (e.data.event == "calendly.event_scheduled") {
          this.showCalendly = false;
          this.isMeetingSuccess = true;
          let uri = e.data.payload.event.uri;
          await this.fetchMeetingObject(uri);
        }
      }
    });
  },
  mounted() {
    this.showRatingDialog();
  },
  destroyed() {
    window.removeEventListener("message", function (e) {});
  },
  methods: {
    closeCalendly() {
      this.showCalendly = false;
    },
    isCalendlyEvent: function (e) {
      return e.data.event && e.data.event.indexOf("calendly") === 0;
    },
    scheduleTeamPreviewMeeting(payload) {
      let { end_time, start_time, event_guests, location, created_at } =
        payload;
      let data = {
        preview_meeting_attendees: event_guests,
        preview_meeting_created: "",
        preview_meeting_end: end_time,
        preview_meeting_hangout_link: location.join_url,
        preview_meeting_id: "",
        preview_meeting_start_date: start_time,
        preview_meeting_summary: "",
        project_id: +this.$route.params.id,
      };
      teamPreviewMeeting(data)
        .then((resp) => {
          this.meetingLoader = false;
        })
        .catch((err) => {
          this.meetingLoader = false;
        });
    },
    handleCalendly() {
      this.showCalendly = true;
    },
    closeSuccessModal() {
      if (!this.meetingLoader) {
        this.isMeetingSuccess = false;
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    showRatingDialog() {
      if (
        this.project.is_client_has_rate_meeting == 0 &&
        this.isMeetingDone() == true
      ) {
        this.openDialog = true;
      }
    },
    isMeetingDone() {
      return this.$moment(this.project.preview_meeting_start_date).isBefore(
        this.$moment()
      );
    },
    submitRating() {
      let { comment, value } = this.ratingForm;
      if (value !== "") {
        this.ratingSpinner = true;
        let payload = {
          comment,
          meeting_rating_id: value,
          project_id: this.$route.params.id,
          user_type_id: 2,
        };
        rateFirstMeeting(payload)
          .then((resp) => {
            this.ratingSpinner = false;
            this.ratingSucess = true;
            this.isRatingForm = false;
          })
          .catch((error) => {
            this.ratingSpinner = false;
          });
      }
    },
    handleMeetingStatus() {
      this.isMeetingHold = true;
      this.isRatingForm = false;
    },
    async fetchMeetingObject(url) {
      await calendlyEndpoint(url)
        .then((response) => {
          if (response.status == 200) {
            let { end_time, start_time, event_guests, location, created_at } =
              response.data.resource;
            let payload = {
              meeting_attendees: event_guests,
              meeting_end: end_time,
              hangout_link: location.join_url,
              meeting_id: "",
              meeting_start_date: start_time,
              meeting_summary: "",
              meeting_created: "",
            };
            this.meetingPayload = payload;
            this.scheduleTeamPreviewMeeting(response.data.resource);
          } else {
            this.meetingLoader = false;
            throw new Error(resp.data.msg);
          }
        })
        .catch((error) => {
          this.meetingLoader = false;
        });
    },
  },
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    project() {
      return this.clientProject;
    },
    teamMembers() {
      return this.projectTeam;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.schedule-meeting {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.schedule-meeting span {
  color: #0781f2;
}
.section-cover {
  text-align: left;
  /* width: 521px; */
}
.meeting-info {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 8px;
}
.calendar-embed {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 263px;
  width: 521px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
}
.schedule-text {
  font-weight: 900;
  font-size: 22px;
  line-height: 38px;
  color: #c8cbd0;
  width: 360px;
  text-align: center;
  cursor: pointer;
}
.meeting-goal {
}
.objectives {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
}
.meeting-details {
  text-align: center;
}
.meeting-time {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
}
.meeting-time span {
  color: #1b1e22;
}
.meeting-link {
  font-size: 12px;
  line-height: 140%;
  color: #c8cbd0;
  margin-top: 16px;
}
.meeting-link span {
  color: #0781f2;
  cursor: pointer;
}
.meeting-link span a {
  text-decoration: none;
}
.bottom-div {
  display: flex;
  margin-top: 24px;
}
.meeting-attendees {
  margin-left: 150px;
  text-align: left;
}
.meeting-header {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.members-box {
  display: flex;
  margin-top: 16px;
}
.team-member {
  display: flex;
  width: 220px;
}
.dev-details {
  margin-left: 8px;
}
.dev-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.dev-role {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 2px;
}
.profile_bg {
  background-size: cover;
  max-width: 100%;
  max-height: 100%;
  background-position: top;
}
.check-icon {
  margin-left: 100px;
}
</style>
