var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "section-wrapper" }, [
        _vm._m(0),
        _c("div", { staticClass: "right-container" }, [
          _c("div", { staticClass: "grey-header" }, [
            _vm._v(
              "\n        Here’s a copy of the SLA, click to open the document.\n      "
            ),
          ]),
          _c("div", { staticClass: "platform-agreement" }, [
            _c("div", { staticClass: "document-name" }, [
              _vm._m(1),
              _c("div", { staticClass: "product-name" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.project.project_name) +
                    " " +
                    _vm._s("<>") +
                    "\n            "
                ),
                _c("span", [
                  _vm._v(" Product Team Service Level Agreement.pdf "),
                ]),
              ]),
            ]),
            _c(
              "div",
              {
                staticClass: "open-action",
                on: { click: _vm.handleOpenAgreement },
              },
              [_vm._v("Open")]
            ),
          ]),
          _c("div", { staticClass: "btn-container" }, [
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                on: { click: _vm.handleSignSLA },
              },
              [
                _vm._v("\n          Agree & Execute\n\n          "),
                _c("img", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.spinner,
                      expression: "spinner",
                    },
                  ],
                  staticClass: "button-spinner",
                  staticStyle: { "margin-left": "20px" },
                  attrs: { src: "/img/lightbox/preloader.gif" },
                }),
              ]
            ),
          ]),
        ]),
      ]),
      _c(
        "GrupaSLA",
        _vm._b(
          {
            ref: "grupa_sla",
            staticStyle: { overflow: "hidden", height: "0" },
            attrs: { id: "grupa-sla", devUser: false },
          },
          "GrupaSLA",
          { teamDetails: _vm.teamDetails, projectDoc: _vm.projectDoc },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "left-container" }, [
      _c("div", { staticClass: "section-header" }, [_vm._v("Finalize SLA")]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v("Let’s start building!"),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n        This document captures the details of service expected. Please go\n        through it and confirm that everything is in order.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "document-icon" }, [
      _c("img", { attrs: { src: "/img/doc-icons/pdf.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }