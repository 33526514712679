var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "modal-container" }, [
    _c("div", { staticClass: "modal-wrap" }, [
      _c("div", { staticClass: "toogle-modal" }, [
        _c(
          "div",
          { staticClass: "close-btn", on: { click: _vm.handleCloseModal } },
          [
            _c("img", {
              attrs: { src: "/img/onboarding/cancel_close.svg", alt: "" },
            }),
          ]
        ),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "modal-msg" }, [_vm._v("Payment Successful!")]),
      _c("div", { staticClass: "modal-btn" }, [
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            on: { click: _vm.handleCloseModal },
          },
          [_vm._v("Close")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "image-div" }, [
      _c("img", { attrs: { src: "/img/success-mark.svg", alt: "" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }