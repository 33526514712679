var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-layout" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "stuff-to-know" },
        [
          _c(
            "div",
            { staticClass: "info-header", staticStyle: { color: "#979da5" } },
            [_vm._v("STUFF TO KNOW")]
          ),
          _vm._l(_vm.listItems, function (item, i) {
            return _c("div", { key: i, staticClass: "item-list" }, [
              _vm._m(1, true),
              _c("div", [_vm._v(_vm._s(item))]),
            ])
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-msg" }, [
      _c("div", { staticClass: "container-title" }, [
        _vm._v("Draw Product Roadmap"),
      ]),
      _c("div", { staticClass: "description" }, [
        _vm._v(
          "\n        The Product Roadmap maps out the direction of the product development\n        over time. Based on learnings from the design sprint, priority is\n        given to the most important ideas, features or user stories.\n      "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/onboarding/grey-checked.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }