<template>
  <div class="section-wrapper">
    <div class="left-container">
      <div class="section-header">Review & Sign Agreements</div>

      <div class="grey-text">
        Confirm the parameters as agreed with your team to generate the mutual
        platform agreement.
      </div>
      <div class="grey-text">
        The platform agreement would be sent to your email, to your team and can
        also be accessible in the Documents tab.
      </div>
    </div>
    <div class="right-container">
      <div class="grey-header">
        Confirm the parameters below as agreed by you and your team
      </div>
      <div>
        <div class="form-label">How many experts are we to provide? *</div>
        <div class="form-grouped">
          <div
            class="chip-rounded"
            v-for="number in teamNumber"
            @click="form.no_of_engineers = number"
            :key="number"
            :class="{ 'selected-chip-btn': form.no_of_engineers == number }"
          >
            <div class="chip-text">{{ number }}</div>
          </div>
        </div>
      </div>

      <div>
        <div class="form-label">
          Do you want to run a Design Sprint? * <span>What’s this?</span>
        </div>
        <div class="form-grouped">
          <ChipButton
            text="Yes"
            disabled="true"
            buttonIcon="/img/dashboard/yes-icon.svg"
            :dashedBorder="true"
            v-on:click="form.is_want_design_sprint = 1"
            :selected="form.is_want_design_sprint == 1 ? true : false"
          />
          <ChipButton
            text="No"
            disabled="true"
            buttonIcon="/img/dashboard/no-icon.svg"
            :dashedBorder="true"
            v-on:click="form.is_want_design_sprint = 0"
            :selected="form.is_want_design_sprint == 0 ? true : false"
          />
        </div>
      </div>
      <div>
        <div class="form-label">
          Confirm compensation method * <span>What’s this?</span>
        </div>
        <div class="form-grouped">
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px; height: 20px"
            class="button-spinner"
            v-if="isSavingComp"
          />
          <div  v-if="!isSavingComp">
            <div v-if="isSLASigned" class="form-grouped">
              <ChipButton
                v-for="(item, index) in compensationModels"
                disabled="true"
                :key="index"
                :text="item.name"
                :dashedBorder="true"
                :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
                :selected="item.value == form.compensation_id ? true : false"
                v-on:click="handleSelectCompensation(item.value)"
              />
            </div>
            <div
              class="alert alert-danger" v-if="isSLASigned"
              style="padding: 2px; text-align: center; margin-top: 10px; font-size: 10px;"
            >
              Cant change after signing SLA
            </div>

            <div v-if="!isSLASigned" class="form-grouped">
              <ChipButton
                v-for="(item, index) in compensationModels"
                :key="index"
                :text="item.name"
                :dashedBorder="true"
                :buttonIcon="'img/onboarding/specify_preferences/' + item.img"
                :selected="item.value == form.compensation_id ? true : false"
                v-on:click="handleSelectCompensation(item.value)"
              />
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="product-pricing">
        <div class="section-div-title">
          <img src="/img/dashboard/rate-icon.svg" alt="" />
          Rate per expert
        </div>

        <div class="pricing-label">
          Cash component:
          <span v-if="estimatedCost.cash"
            >${{ estimatedCost.cash.toLocaleString() }} cash per month</span
          >
        </div>

        <div class="pricing-label">
          Equity component:
          <span>{{ estimatedCost.equity }}% equity (3month cliff period)</span>
        </div>
      </div>

      <div>
        <Documents
          v-bind="{
            equity,
            isAgreedState,
            isSLASignedFunction,
            isSLASignedProps,
            isPAgreementSignedFunction,
            isPASignedProps,
          }"
        />
      </div>

      <!-- 
        v-if="isProjectRate"
       -->

      <div class="agreement-container">
        <div class="checkbox-div">
          <v-checkbox v-model="form.agreement"></v-checkbox>
        </div>
        <div class="agreement-text">
          I accept and agree to have signed the documents and proceed with the
          parameters as selected above
        </div>
      </div>

      <div class="button-container">
        <button
          :disabled="!enableBtn"
          @click="handleGenerateDoc"
          class="grupa-disabled-btn"
          :class="enableBtn ? 'grupa-blue-btn' : null"
        >
          All Agreement Signed
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ChipButton from "@/components/chipButton/chipButton";
import { updateProject, updateProjectCompensation } from "@/api/client";
import { grupaPricing } from "@/utils/grupaVariables";
import Documents from "./Documents";

export default {
  props: {},
  components: { ChipButton, Documents },

  data: () => ({
    spinner: false,
    teamNumber: [1, 2, 3, 4, 5, 6],
    form: {
      no_of_engineers: 0,
      is_want_design_sprint: "nil",
      compensation_id: 0,
      agreement: false,
      desired_hours: 0,
      stage_category_id: 0,
    },
    isAgreed: false,
    isSLASigned: false,
    isSavingComp: false,

    isPAgreementSigned: false,

    compensationModels: [
      {
        name: "Cash Only",
        value: 0,
        img: "cash.png",
      },
      {
        name: "A mix of Cash & Equity",
        value: 1,
        img: "cash.png",
      },
      {
        name: "Equity only",
        value: 2,
        img: "cash.png",
      },
      /*  {
        name: "I’m not sure yet",
        value: 3,
        img: "cash.png",
      }, */
    ],
    equity: 0,
  }),
  created() {
    this.isSLASigned = this.clientProject.accept_sla ? true : false;
  },
  mounted() {
    this.equity = this.estimatedCost.equity;

    //console.log(this.clientProject)
    this.form.no_of_engineers = this.clientProject.no_of_engineers;
    this.form.is_want_design_sprint = 1;
    this.form.compensation_id = parseInt(this.clientProject.compensation_id);
    this.form.desired_hours = this.clientProject.desired_hours;
    this.form.stage_category_id = this.startupStage;

    // console.log(this.form)
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("clientDashboard", ["updateProject", "setProjectPrice"]),
    handleSelectCompensation(value) {
      this.form.compensation_id = value;
      // console.log(this.estimatedCost.cash);

      const payload = {
        compensation_id: "",
        price: this.estimatedCost.cash,
        per_sprint_fee: this.estimatedCost.cash / 4,
      };
      payload.compensation_id = Number(value);
      this.isSavingComp = true;

      updateProjectCompensation(payload, this.$route.params.id)
        .then((response) => {
          const { project, status } = response.data;

          if (status == 1) {
          }
          this.updateProject(project);
          this.isSavingComp = false;
          this.setSuccessMsg("Compensation Updated");
        })
        .catch((error) => {
          this.isSavingComp = false;

          console.log(error);
          this.setErrorMsg("Error occurred :: " + error);
        });

      //this.form.compensation_id
    },
    isAgreedState() {
      this.isAgreed = true;
    },

    isSLASignedFunction() {
      this.isSLASigned = true;
    },
    isSLASignedProps() {
      return this.isSLASigned;
    },
    isPAgreementSignedFunction() {
      this.isPAgreementSigned = true;
    },
    isPASignedProps() {
      return this.isPAgreementSigned;
    },

    compensationMethod(b) {
      let a = parseInt(b);
      if (a == null) {
        return "";
      }
      switch (a) {
        case 0:
          return "Cash Only";
        case 1:
          return "A mix of cash & equity";
        case 2:
          return "Equity only";
        case 3:
          return "I’m not sure yet";

        default:
          return "None";
      }
    },

    handleGenerateDoc() {
      //console.log(this.form);
      // Calculate per sprint fee
      const payload = this.form;
      const { cash: price, equity } = this.estimatedCost;
      const no_of_weeks = 4;
      //const { no_of_engineers } = this.form;
      const per_sprint_fee = parseInt(price) / no_of_weeks;
      console.log(parseInt(price));
      console.log(per_sprint_fee);
      console.log(price);

      // const per_sprint_fee = (price * no_of_engineers) / no_of_weeks;

      this.setProjectPrice({ price, per_sprint_fee, equity });

      const project_id = this.$route.params.id;
      payload.project_id = project_id;
      payload.is_want_design_sprint = 0;
      payload.compensation_id = Number(payload.compensation_id);
      payload.stage_category_id = this.startupStage;
      this.spinner = true;

      console.log(payload);

      /* */ updateProject(payload)
        .then((response) => {
          if (response.data.status === 1) {
            this.updateProject(response.data.project);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.spinner = false;
        }); /* */
    },
  },
  computed: {
    ...mapState("clientDashboard", ["startupStage", "clientProject"]),
    estimatedCost() {
      let expertcount = this.form.no_of_engineers;
      let cashcost = 0,
        equityMultiplier,
        equityBaseMultiplier,
        equityHoursMultiplier,
        cashdiscount,
        equityCost;

      switch (this.form.compensation_id) {
        case 0:
          cashcost = 20000;
          equityBaseMultiplier = 0;
          break;
        case 1:
          cashcost = 15000;
          equityBaseMultiplier = 1;
          break;
        case 2:
          cashcost = 3000;
          //cashcost = 1000;
          equityBaseMultiplier = 2.5;
          break;
        default:
          equityBaseMultiplier = 1;
      }

      switch (this.form.desired_hours) {
        case 20:
          cashdiscount = 7500;
          // equityHoursMultiplier = 1;
          equityHoursMultiplier = 0;

          break;
        case 30:
          cashdiscount = 5000;
          equityHoursMultiplier = 1.5;
          //equityHoursMultiplier = 1;

          break;
        case 40:
          cashdiscount = 0;
          equityHoursMultiplier = 2.0;
          // equityHoursMultiplier = 2.5;

          break;
        default:
          cashdiscount = 0;
          equityHoursMultiplier = 1;
      }

      switch (this.form.stage_category_id) {
        case 1: // angel
          equityCost = 1;
          break;
        case 2: // pre seed
          equityCost = 0.5;
          break;
        case 3: // seed
          equityCost = 0.25;
          break;
        case 4: // series A
          equityCost = 0.125;

        case 5: // series B
          equityCost = 0.0613;

        case 6: // series C
          equityCost = 0.0307;

        case 7: // series D
          equityCost = 0.0154;
          break;
        default:
          equityCost = 1;
      }

      equityMultiplier = equityBaseMultiplier * equityHoursMultiplier;
      this.equity =
        Math.round(1000 * Number(expertcount) * equityCost * equityMultiplier) /
        1000;

      return {
        cash: Number(expertcount) * Math.max(3000, cashcost - cashdiscount),
        equity:
          Math.round(
            1000 * Number(expertcount) * equityCost * equityMultiplier
          ) / 1000,
      };
    },
    isProjectRate() {
      const {
        no_of_engineers,
        is_want_design_sprint,
        compensation_id,
        agreement,
        desired_hours,
      } = this.form;

      if (
        no_of_engineers &&
        this.form.agreement &&
        this.isSLASigned &&
        this.isPAgreementSigned &&
        is_want_design_sprint !== "nil" &&
        compensation_id !== ""
      ) {
        return true;
      }
      return false;
    },

    enableBtn() {
      if (
        this.isProjectRate
        //this.isAgreed &&
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.form-label {
  margin-top: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.form-label span {
  color: #0781f2;
}
.form-grouped {
  margin-top: 8px;
  display: flex;
  gap: 8px;
}
.chip-rounded {
  background: #f7f7f8;
  border: 1px dashed #979da5;
  box-sizing: border-box;
  border-radius: 50%;
  width: 39px;
  height: 33px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
}
.selected-chip-btn {
  background: rgba(111, 181, 246, 0.2);
  color: #0781f2;
  border: 1px dashed #0781f2;
}
.product-pricing {
  width: 427px;
  background: rgba(7, 129, 242, 0.05);
  border-radius: 4px;
  margin-top: 12px;
  padding: 16px;
}
.pricing-label {
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  margin-top: 16px;
}
.pricing-label span {
  color: #1fa564;
}
.agreement-container {
  margin-top: 48px;
  display: flex;
}
.agreement-text {
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.button-container {
  margin-top: 24px;
}
</style>
<style>
.checkbox-div .v-input--selection-controls {
  margin-top: 0px;
  padding-top: 0px;
}
</style>
