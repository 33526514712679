<template>
  <div class="modal-container">
    <div class="modal-wrap">
      <div class="toogle-modal">
        <div class="close-btn" @click="handleCloseModal">
          <img src="/img/onboarding/cancel_close.svg" alt="" />
        </div>
      </div>
      <div class="image-div">
        <img src="/img/success-mark.svg" alt="" />
      </div>
      <div class="modal-msg">Payment Successful!</div>
      <div class="modal-btn">
        <button class="grupa-blue-btn" @click="handleCloseModal">Close</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {},
  data() {
    return {};
  },
  created() {},
  methods: {
    ...mapActions("modalMgmt", ["updatePaymentModalFn"]),

    handleCloseModal() {
      this.$emit("handleNextPage");
      this.updatePaymentModalFn(false);
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-wrap {
  background: #ffffff;
  padding: 15px;
  flex-direction: column;
  width: 440px;
  height: 507px;
  box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
}
.toogle-modal {
  display: flex;
  justify-content: flex-end;
}
.image-div {
  margin-top: 64px;
}
.modal-msg {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-top: 24px;
}
.modal-btn {
  margin-top: 48px;
}
.close-btn {
  cursor: pointer;
}
</style>


