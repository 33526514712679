import { render, staticRenderFns } from "./project-team.vue?vue&type=template&id=023b8c60&scoped=true&"
import script from "./project-team.vue?vue&type=script&lang=js&"
export * from "./project-team.vue?vue&type=script&lang=js&"
import style0 from "./project-team.vue?vue&type=style&index=0&id=023b8c60&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "023b8c60",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('023b8c60')) {
      api.createRecord('023b8c60', component.options)
    } else {
      api.reload('023b8c60', component.options)
    }
    module.hot.accept("./project-team.vue?vue&type=template&id=023b8c60&scoped=true&", function () {
      api.rerender('023b8c60', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ClientDashboard/workspace/prekickoff/project-team.vue"
export default component.exports