var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tab-wrapper" }, [
      _c("div", { staticClass: "plan-ahead-div" }, [
        _c("div", { staticClass: "plan-ahead-title" }, [_vm._v("Plan Ahead!")]),
        _c("div", { staticClass: "plan-text" }, [
          _vm._v(
            "\n        Select the number of product iterations you’d like to build out in\n        this version.\n      "
          ),
        ]),
        _c("div", { staticClass: "plan-text" }, [
          _vm._v(
            "\n        This helps us customize your experience and lock down your product\n        team for the period it will take to build these iterations.\n      "
          ),
        ]),
        _c(
          "div",
          { staticClass: "plan-text", staticStyle: { color: "#1fa564" } },
          [_vm._v("\n        You can change this at any time.\n      ")]
        ),
        _c("div", { staticClass: "slider-container" }, [
          _c("div", { staticClass: "slider-title" }, [
            _vm._v("Move the slider."),
          ]),
          _c(
            "div",
            { staticClass: "slider-div" },
            [
              _c("v-slider", {
                staticClass: "itr_slider",
                attrs: {
                  "tick-labels": _vm.iterationLabels,
                  max: _vm.iterationLabels.length,
                  min: "1",
                  step: "1",
                  ticks: "always",
                  "tick-size": "4",
                },
                on: {
                  change: function ($event) {
                    return _vm.updateSelectedIteration()
                  },
                },
                model: {
                  value: _vm.form.iterations,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "iterations", $$v)
                  },
                  expression: "form.iterations",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "button-wrap" }, [
          _c(
            "button",
            {
              staticClass: "grupa-white-btn",
              staticStyle: { background: "#f7f7f8", "margin-right": "24px" },
              on: {
                click: function ($event) {
                  return _vm.switchTab("payment")
                },
              },
            },
            [_vm._v("\n          Skip for Now\n        ")]
          ),
          _c(
            "button",
            {
              staticClass: "grupa-blue-btn",
              on: { click: _vm.handlePlanAhead },
            },
            [
              _vm._v("\n          Subscribe\n          "),
              _vm.spinnerBtn
                ? _c("img", {
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "5px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  })
                : _vm._e(),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "service-plan-div" }, [
        _c("div", { staticClass: "service-plan-info" }, [
          _c("div", { staticClass: "service-box-title" }, [
            _vm._v("SERVICE PLAN"),
          ]),
          _c("div", { staticClass: "plan-name" }, [
            _vm._v(_vm._s(_vm.project.experience)),
          ]),
          _c("div", { staticClass: "plan-info" }, [_vm._v("team of 5")]),
          _c("div", { staticClass: "plan-info" }, [
            _vm._v("100 work hours per week"),
          ]),
          _c("div", { staticClass: "plan-info" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.project.iteration_cycle) +
                " weeks per iteration\n        "
            ),
          ]),
          _c("div", { staticClass: "plan-price" }, [
            _vm._v(
              "\n          $" +
                _vm._s(
                  (
                    _vm.project.price / _vm.project.iteration_cycle
                  ).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                ) +
                "\n          per weekly sprint\n        "
            ),
          ]),
          _vm._m(0),
        ]),
        _c("div", { staticClass: "team-lock" }, [
          _vm._v("\n        Your team would be locked down for\n        "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.project.iteration_cycle * _vm.form.iterations) +
                " weeks (" +
                _vm._s(_vm.form.iterations) +
                "\n          iterations)"
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plan-image" }, [
      _c("img", { attrs: { src: "/img/onboarding/upstart-icon.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }