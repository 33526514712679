<template>
  <div>
    <EmptyDesignSprint v-if="sprintArray.length == 0" />
    <DesignSprint v-if="sprintArray.length > 0" v-bind="{ sprintArray }" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import EmptyDesignSprint from "./emptyDesignSprint";
import DesignSprint from "./designSprint";
export default {
  props: {
    sprintArray: {
      type: Array,
    },
  },
  components: {
    EmptyDesignSprint,
    DesignSprint,
  },

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("clientDashboard", ["clientProject"]),
    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
</style>
