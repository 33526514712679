<template>
  <div>
    <div class="vetting-progress">
      <div class="progress-wrapper">
        <div class="" v-for="(stage, k) in vettingStages" :key="k">
          <div v-if="k == 0">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel == 1 && !isMeetingDone()
                    ? 'green-dot.svg'
                    : 'tick-icon.svg'
                }`"
                alt=""
              />
              <div
                class="progress-name"
                :style="
                  progressLevel == 1 && !isMeetingDone()
                    ? 'color: #1fa564;'
                    : 'color: #1b1e22;'
                "
              >
                {{ stage }}
              </div>
            </div>
            <div
              class="progress-line"
              :style="
                progressLevel == 1 && !isMeetingDone()
                  ? 'border-left: solid 3px #54CF94;'
                  : 'border-left: solid 3px #1b1e22'
              "
            ></div>
          </div>
          <div v-if="k == 1">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel < 2 && isMeetingDone()
                    ? 'green-dot.svg'
                    : progressLevel == k
                    ? 'black-dot.svg'
                    : 'tick-icon.svg'
                }`"
                alt=""
              />
              <div
                class="progress-name"
                :style="
                  progressLevel < 2 && isMeetingDone()
                    ? 'color: #1fa564;'
                    : progressLevel == k
                    ? 'color: #979da5;'
                    : 'color: #1b1e22;'
                "
              >
                {{ stage }}
              </div>
            </div>
            <div
              class="progress-line"
              :style="
                progressLevel < 2 && isMeetingDone()
                  ? 'border-left: solid 3px #54CF94;'
                  : progressLevel == k
                  ? 'solid 3px #e4e5e7;'
                  : 'border-left: solid 3px #1b1e22'
              "
            ></div>
          </div>
          <div v-if="k > 1">
            <div class="progress-stage">
              <img
                :src="`/img/onboarding/${
                  progressLevel == k + 1
                    ? 'green-dot.svg'
                    : progressLevel < k + 1
                    ? 'black-dot.svg'
                    : 'tick-icon.svg'
                }`"
                alt=""
              />
              <div
                class="progress-name"
                :style="
                  progressLevel == k + 1
                    ? 'color: #1fa564;'
                    : progressLevel > 3
                    ? 'color: #1b1e22;'
                    : ''
                "
              >
                {{ stage }}
              </div>
            </div>
            <div
              v-if="k < 5"
              class="progress-line"
              :style="
                progressLevel == k + 1
                  ? 'border-left: solid 3px #54CF94;'
                  : progressLevel > k
                  ? 'border-left: solid 3px #1b1e22;'
                  : ''
              "
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  components: {},
  data() {
    return {
      vettingStages: [
        "Meet the Team",
        "Confirm Selection",
        "Run Design Sprint",
        "Draw Product Roadmap",
        "Finalise SLA",
        "Start Product Development",
      ],
    };
  },
  created() {},
  methods: {
    isMeetingDone() {
      let meeting_date = this.project.preview_meeting_start_date;
      return this.$moment(meeting_date).isBefore(this.$moment());
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject"]),
    project() {
      return this.clientProject;
    },
    progressLevel() {
      return this.clientProject.workspace_progress_level;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.vetting-progress {
  background: #ffffff;
  padding-top: 48px;
  display: flex;
  justify-content: center;
  width: 307px;
  height: 100%;
}
.progress-wrapper {
  position: fixed;
  height: 100%;
}
.progress-stage {
  display: flex;
}
.progress-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-left: 4px;
}
.progress-line {
  height: 60px;
  border-left: solid 2px #e4e5e7;
  margin: 7px 0px 10px 3px;
}
</style>


