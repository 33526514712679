<template>
  <div>
    <div v-if="project == null">
      <Loader />
    </div>
    <div v-else>
      <NewProject
        v-if="progressLevel !== null && progressLevel < 3 && !teamId"
      />
      <!-- <Prekickoff v-if="progressLevel >= 1 && progressLevel < 7" /> -->
      <ProductEngagement
        v-if="progressLevel >= 2 && progressLevel < 7 && teamId"
      />
      <ProjectKickoff v-if="progressLevel !== null && progressLevel >= 7" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NewProject from "./newProject";
import Prekickoff from "./prekickoff";
import ProductEngagement from "./productEngagement";
import {
  getProjectActivityLog,
  fetchProjectDesignSprint,
  clientPaymentCards,
  completeProjectDetails,
} from "@/api";
import ProjectKickoff from "./kickoff";
import Loader from "@/components/general/centerLoader";
import { logoutUser } from "@/utils/logoutUser";

export default {
  props: {},
  components: {
    Prekickoff,
    NewProject,
    ProjectKickoff,
    Loader,
    ProductEngagement,
  },

  data: () => ({
    project: null,
    progressLevel: null,
    teamId: null,
    isProject: false,
    isProjectActivity: false,
    isProjectStrint: false,
  }),

  created() {
    this.allRequest();
    this.$watch(
      () => this.$route.params,
      () => {
        this.project = null;
        this.allRequest();
      }
    );
  },
  methods: {
    ...mapActions("clientDashboard", [
      "setProjectDetails",
      "setAllProjects",
      "updateProjectActivity",
    ]),
    ...mapActions("clientPrekickoff", [
      "setDesignSprintDays",
      "setClientCreditCards",
    ]),
    allRequest() {
      let project_id = this.$route.params.id;
      if (this.isProject === false) {
        this.fetchProject(project_id);
      }
      if (this.isProjectActivity === false) {
        this.fetchActivitiesLog();
      }
      if (this.isProjectStrint === false) {
        this.fetchDesignSprint(project_id);
      }

      this.clientCreditCard();
    },
    fetchProject(projectId) {
      completeProjectDetails(projectId)
        .then((res) => {
          this.isProject = true;
          this.project = res.data.project;
          const { team_id, workspace_progress_level } = res.data.project;
          this.progressLevel = workspace_progress_level;
          this.teamId = team_id;
          this.setProjectDetails(res.data);
        })
        .catch((err) => {
          this.isProject = true;
          logoutUser();
          this.$developConsole(err, "Error Fetching Project");
        });
    },
    fetchActivitiesLog() {
      getProjectActivityLog(this.$route.params.id)
        .then((res) => {
          this.isProjectActivity = true;
          if (res.data.status == 1) {
            const { data, current_page, last_page_url, next_page_url } =
              res.data;
            this.updateProjectActivity(data.data);
          }
        })
        .catch((err) => {
          this.isProjectActivity = true;
        });
    },
    fetchDesignSprint(project_id) {
      fetchProjectDesignSprint(project_id)
        .then((resp) => {
          this.isProjectStrint = true;
          this.setDesignSprintDays(resp.data.data);
          this.$developConsole(resp.data.data, "Design Sprint");
        })
        .catch((err) => {
          this.isProjectStrint = true;

          this.$developConsole(err, "design sprint");
        });
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.setClientCreditCards(response.data.cards);
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
  },
  computed: {},
};
</script>
<style scoped></style>
