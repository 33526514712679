var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    _vm._b(
      {
        attrs: { dialogTitle: "Design Sprint Balance" },
        scopedSlots: _vm._u([
          {
            key: "dialog-footer",
            fn: function () {
              return [
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "grupa-blue-btn",
                      on: { click: _vm.closeDialog },
                    },
                    [_vm._v("\n        Okay, Got it\n      ")]
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "DialogComponent",
      { closeDialog: _vm.closeDialog },
      false
    ),
    [
      _c("div", { attrs: { id: "main-children" } }, [
        _c("div", { staticClass: "container-box" }, [
          _c("div", { staticClass: "inner-container" }, [
            _c("div", { staticClass: "right-div" }, [
              _c("div", { staticClass: "cost-text" }, [_vm._v("COST")]),
              _c("div", { staticClass: "sprint-amount" }, [
                _vm._v(
                  "\n            $" +
                    _vm._s(
                      _vm.priceRoundUp(_vm.project.design_thinking_fee).price
                    ) +
                    "\n            "
                ),
                _c(
                  "span",
                  { staticStyle: { "font-size": "23px", color: "#000" } },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.priceRoundUp(_vm.project.design_thinking_fee)
                          .decimal
                      )
                    ),
                  ]
                ),
              ]),
              _c("div", { staticClass: "pay-info" }, [
                _vm._v(
                  "\n            Pay 50% ($" +
                    _vm._s(_vm.project.design_thinking_fee / 2) +
                    ") now, pay the rest\n            at the end of the sprint.\n          "
                ),
              ]),
              _c("div", { staticClass: "cost-text" }, [
                _vm._v("DELIVERABLES - What you get"),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "explain-message" }, [
          _vm._v(
            "\n      The design sprint costs a total of " +
              _vm._s(_vm.project.design_thinking_fee) +
              ".\n      You paid "
          ),
          _c("span", [
            _vm._v("$" + _vm._s(_vm.project.design_thinking_fee / 2)),
          ]),
          _vm._v(
            " at the\n      beginning of the design sprint. Now the balance is due, midway through\n      the sprint.\n    "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }