<template>
  <div class="page-layout">
    <div class="rectangle-div-box">
      <div class="centered-div">
        <img src="/img/dashboard/layers-icon.svg" />
        <div class="centered-text">
          Waiting for the Team to sign the Service Level Agreement...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.rectangle-div-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 263px;
  width: 521px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
}
.centered-div {
  text-align: center;
}
.centered-text {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  width: 288px;
  margin-top: 16px;
}
</style>
