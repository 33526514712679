<template>
  <div class="section-wrapper">
    <div class="left-container">
      <div class="section-header">Start Development</div>

      <div class="grey-text">Let’s start building!</div>
      <div class="grey-text">
        Payments are to be done before corresponding work starts.
      </div>
      <div class="grey-text">
        We hold your payments in escrow and disburse weekly to the team only
        after you have confirmed that the sprint for that week has been
        delivered.
      </div>
    </div>
    <div class="right-container">
      <div class="grey-header">How would you like to pay?</div>
      <div class="payment-options">
        <div
          class="payment-option-card"
          @click="handleSelectOption('BI-SPRINT')"
        >
          <div class="div-option-name">
            <img
              :src="`/img/dashboard/${
                selectedOption == 'BI-SPRINT'
                  ? 'radio-checked.svg'
                  : 'radio-unchecked.svg'
              }`"
              alt=""
              style="margin-right: 8px"
            />
            Bi-Weekly
          </div>
          <div class="payment-description">
            Pay an installment every 2 weeks, at the beginning of every 2
            sprints
          </div>
          <div class="card-payment-value">
            ${{ formatPrice(biSprintPrice) }}<span>/installment</span>
          </div>
        </div>
        <!-- Monthly -->
        <div class="payment-option-card" @click="handleSelectOption('MONTHLY')">
          <div class="div-option-name">
            <img
              :src="`/img/dashboard/${
                selectedOption == 'MONTHLY'
                  ? 'radio-checked.svg'
                  : 'radio-unchecked.svg'
              }`"
              alt=""
              style="margin-right: 8px"
            />
            Monthly
          </div>
          <div class="payment-description">
            Pay per month, at the beginning of every new iteration.
          </div>
          <div class="card-payment-value">
            ${{ formatPrice(monthlyPrice) }}<span>/installment</span>
          </div>
        </div>
      </div>
      <div class="payment-details">
        <div class="flex-section">
          <div class="section-grey-text">Charge per expert:</div>
          <div v-if="selectedOption == 'MONTHLY'" class="section-grey-text">
            ${{ formatPrice(project.price) }}
          </div>
          <div v-else class="section-grey-text">
            ${{ formatPrice(project.price / 2) }}
          </div>
        </div>
        <div class="flex-section section-margin">
          <div class="section-grey-text">Number of experts:</div>
          <div class="section-grey-text">{{ project.no_of_engineers }}</div>
        </div>
        <div class="divider-section"></div>
        <div class="flex-section section-margin">
          <div class="section-bold-text">Sub-total:</div>
          <div v-if="selectedOption == 'MONTHLY'" class="section-bold-text">
            ${{ formatPrice(monthlyPrice) }}
          </div>
          <div v-else class="section-bold-text">
            ${{ formatPrice(biSprintPrice) }}
          </div>
        </div>
        <div class="flex-section section-margin">
          <div class="section-grey-text">VAT (7.5%):</div>
          <div v-if="selectedOption == 'MONTHLY'" class="section-grey-text">
            ${{ formatPrice(vatCharges(monthlyPrice)) }}
          </div>
          <div v-else class="section-grey-text">
            ${{ formatPrice(vatCharges(biSprintPrice)) }}
          </div>
        </div>
        <div class="flex-section section-margin">
          <div class="section-grey-text">Transaction fee (3%):</div>
          <div v-if="selectedOption == 'MONTHLY'" class="section-grey-text">
            ${{ formatPrice(transactionFee(monthlyPrice)) }}
          </div>
          <div v-else class="section-grey-text">
            ${{ formatPrice(transactionFee(biSprintPrice)) }}
          </div>
        </div>
        <div class="divider-section"></div>
        <div class="flex-section section-margin">
          <div class="section-bold-text">Total Charge:</div>
          <div v-if="selectedOption == 'MONTHLY'" class="section-bold-text">
            ${{ formatPrice(amountDue(monthlyPrice)) }}
          </div>
          <div v-else class="section-bold-text">
            ${{ formatPrice(amountDue(biSprintPrice)) }}
          </div>
        </div>
      </div>
      <div class="action-container">
        <button class="grupa-blue-btn" @click="handleKickoffFee">
          Pay ${{
            selectedOption == "MONTHLY"
              ? formatPrice(amountDue(monthlyPrice))
              : formatPrice(amountDue(biSprintPrice))
          }}
        </button>
        <div class="stripe-copy-right">
          <img src="/img/dashboard/stripe-lock.svg" style="margin-right: 5px" />
          Secured by <span>Stripe</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    project: Object,
    monthlyPrice: Number,
    biSprintPrice: Number,
    selectedOption: String,
    handleSelectOption: Function,
    handleKickoffFee: Function,
  },

  data: () => ({
    // selectedOption: "MONTHLY",
  }),
  created() {},
  methods: {
    formatPrice(value) {
      return value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    transactionFee(amount) {
      let amountToPay = amount;
      let percent = amount * 0.075;
      let amountToPay1 = percent + amountToPay;
      return 0.03 * amountToPay1;
    },
    vatCharges(amount) {
      return amount * 0.075;
    },
    amountDue(amount) {
      let amountDue = amount;
      let transactionFeePercent = amount * 0.075;
      let transactionFee = transactionFeePercent + amount;
      let totalTran = transactionFee * 0.03;
      let vatCharges = transactionFeePercent;
      return amountDue + totalTran + vatCharges;
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.payment-options {
  display: flex;
  gap: 24px;
  margin-top: 16px;
}
.payment-option-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 24px;
  text-align: left;
}
.div-option-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  font-weight: 400;
}
.payment-description {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
  width: 197px;
}
.card-payment-value {
  font-weight: 700;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
  margin-top: 24px;
}

.card-payment-value span {
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.payment-details {
  background: #f7f7f8;
  border-radius: 4px;
  width: 514px;
  margin-top: 24px;
  padding: 24px;
}
.flex-section {
  display: flex;
  justify-content: space-between;
}
.section-grey-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
}
.section-margin {
  margin-top: 16px;
}
.divider-section {
  border-top: 1px solid #e4e5e7;
  margin-top: 16px;
}
.section-bold-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  color: #1b1e22;
}
.action-container {
  margin-top: 24px;
}
.stripe-copy-right {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
}
.stripe-copy-right span {
  color: #1b1e22;
}
</style>
