var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.sprintArray.length == 0 ? _c("EmptyDesignSprint") : _vm._e(),
      _vm.sprintArray.length > 0
        ? _c(
            "DesignSprint",
            _vm._b({}, "DesignSprint", { sprintArray: _vm.sprintArray }, false)
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }