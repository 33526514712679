<template>
  <div>
    <div class="report-container">
      <div class="report-header">
        <div class="report-schedule">
          <div class="schedule-text">SCHEDULE</div>
          <div class="schedule-current-date">March 2021</div>
          <div class="report-date-toggle">
            <div><img src="/img/dashboard/dev/day-previous.svg" /></div>
            <div class="current-day">Today</div>
            <div><img src="/img/dashboard/dev/day-next.svg" /></div>
          </div>
        </div>
        <div>
          <div><img src="/img/dashboard/settings-icon.svg" /></div>
        </div>
      </div>
      <div class="day-date-head">
        <div class="first-column"></div>
        <div class="date-list-div">
          <div class="day-column" v-for="(day, i) in days" :key="i">
            {{ day }}
          </div>
        </div>
      </div>
      <div class="standup-schedule">
        <div class="first-column">
          <div style="margin-left: 8px">
            <img src="/img/dashboard/dev/meeting-icon.svg" />
          </div>
          <span style="margin-top: 7px">Scheduled Meetings</span>
        </div>
        <div class="report-row-white">
          <div class="report-column" v-for="i in 5" :key="i">
            <div class="standup-card">
              <div class="standup-badge"></div>
              <div class="standup-content">
                <div class="standup-name">Sprint Planning</div>
                <div class="standup-time">
                  <img src="/img/dashboard/time-icon.svg" />
                  7:00pm WAT
                </div>
              </div>
            </div>
            <div class="standup-card" v-if="i == 1">
              <div class="standup-badge"></div>
              <div class="standup-content">
                <div class="standup-name">Daily Standup</div>
                <div class="standup-time">
                  <img src="/img/dashboard/time-icon.svg" />
                  7:00pm WAT
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sprint-report-row">
        <div class="first-column empty-sprint">SPRINT REPORTS</div>
        <div class="report-row-white">
          <div class="report-column" v-for="i in 5" :key="i"></div>
        </div>
      </div>
      <div
        class="developer-task-row"
        v-for="(member, i) in teamMembers"
        :key="i"
        :style="(i + 1) % 2 == 0 ? 'background: #FFFFFF;' : null"
        v-if="member.name"
      >
        <div class="first-column task-owner">
          <div>
            <v-avatar
              :size="32"
              color="grey lighten-4"
              class="profile_bg"
              :style="{
                backgroundImage: `url(${
                  $imageUrl + member.profile_picture || test
                })`,
              }"
            ></v-avatar>
          </div>
          <div class="owner-name">
            {{ shortenName(member.name) }}
          </div>
        </div>
        <div class="task-report-row">
          <div class="task-report-column" v-for="j in reports.length" :key="j">
            <div class="backlog-card">
              {{ backlogTasks.length - reportTasks }} tasks in Backlog
            </div>
            <div v-for="report in reports" :key="report.id" class="">
              <div v-for="rep in report.report" :key="rep.id">
                <div v-if="rep.team_user == member.id">
                  <div class="task-ongoing-card" v-if="rep.completed == 1">
                    <div class="green-badge"></div>
                    <div class="task-ongoing-content">
                      0/{{ backlogTasks.length }} tasks Completed
                    </div>
                  </div>
                  <div class="task-ongoing-card" v-if="rep.pending == 1">
                    <div class="green-badge"></div>
                    <div class="task-ongoing-content">
                      1/{{ pendingTasksLength }} tasks Pending
                    </div>
                  </div>
                  <div
                    class="task-ongoing-card"
                    v-if="rep.completed_for_review == 1"
                  >
                    <div class="green-badge"></div>
                    <div class="task-ongoing-content">
                      1/{{ completedForReviewTasksLength }} tasks Completed For
                      Review
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="j < 4" class="task-ongoing-card">
              <div class="green-badge"></div>
              <div class="task-ongoing-content">4/6 tasks Completed</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import shortName from "@/mixins/shortName";
import { getReportBySprintId } from "@/api/client";
export default {
  mixins: [shortName],
  props: {},
  components: {},

  data: () => ({
    days: ["MON 13th", "TUE 14th", "WED 15th", "THU 16th", "FRI 17th"],
    profile: "/img/onboarding/company/profile.jpeg",
    reports: [],
  }),

  created() {
    this.getReport();
  },
  methods: {
    async getReport() {
      // let { task_id } = data;
      // let taskList = JSON.parse(JSON.stringify(this.tasks));
      await getReportBySprintId(1500)
        .then((res) => {
          if (res.data) {
            this.reports = res.data.data;
          }
        })
        .catch((err) => {
          this.$developConsole(err);
        });
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "selectedProject",
      "teamInfo",
      "clientProjectDoc",
      "projectTeam",
    ]),

    currentIteration() {
      return this.clientProjectDoc.documents.iterations[0];
    },

    teamMembers() {
      return this.projectTeam;
    },

    tasks() {
      return this.clientProjectDoc.documents.tasks;
    },

    backlogTasks() {
      let tasksArr = this.clientProjectDoc.documents.tasks;
      let data = tasksArr.filter((task) => task.task_status_id == 1);
      return data;
    },
    reportTasks() {
      let total_number_report_tasks = this.reports[0].report.length;
      return total_number_report_tasks;
    },
    pendingTasksLength() {
      let data = this.reports[0].report.filter((fl) => fl.pending == 1);
      return data.length;
    },
    completedForReviewTasksLength() {
      let data = this.reports[0].report.filter(
        (fl) => fl.completed_for_review == 1
      );
      return data.length;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.report-container {
  background: #ffffff;
  border-radius: 4px;
}
.report-header {
  padding: 24px;
  display: flex;
  justify-content: space-between;
}
.sprint-report-row,
.report-date-toggle,
.report-schedule {
  display: flex;
}
.schedule-current-date,
.schedule-text {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  padding: 8px 24px 8px 0px;
  border-right: 1px solid #e4e5e7;
}
.schedule-current-date {
  padding-left: 24px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.report-date-toggle {
  padding-left: 24px;
  padding-top: 8px;
}
.current-day {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 140%;
  color: #53585f;
  padding: 4px 16px 3px 16px;
  margin: 0px 8px;
  height: 25px;
}
.day-date-head {
  margin-top: 24px;
  border-bottom: 1px solid #e4e5e7;
  display: flex;
}
.first-column {
  width: 234px;
  border-right: 1px solid #e4e5e7;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 0px 0px 24px;
  display: flex;
}
.date-list-div {
  background: #fafafa;
  padding: 15px;
  display: flex;
  min-width: calc(100% - 234px);
}
.day-column {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-align: center;
  width: 20%;
}
.standup-schedule {
  display: flex;
  border-bottom: 1px solid #e4e5e7;
}
.report-row-white {
  display: flex;
  min-width: calc(100% - 234px);
  background: #ffffff;
}
.task-report-column,
.report-column {
  padding: 24px 0px 16px 4px;
  width: 20%;
  border-right: 1px solid #e4e5e7;
}
.task-ongoing-card,
.standup-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 1px 3px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  display: flex;
  width: 180px;
  margin-bottom: 4px;
}
.green-badge,
.blue-badge,
.standup-badge {
  width: 4px;
  /* height: 100%; */
  background: #979da5;
  border-radius: 4px 0px 0px 4px;
}
.standup-content {
  padding: 8px 12px;
}
.standup-name {
  font-size: 14px;
  line-height: 140%;
  color: #53585f;
}
.standup-time {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 8px;
}
.developer-task-row {
  display: flex;
  background: #fafafa;
}
.task-owner {
  padding-top: 16px;
}
.owner-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 7px;
  margin-left: 8px;
}
.empty-sprint {
  padding-bottom: 24px;
}
.task-report-row {
  display: flex;
  min-width: calc(100% - 234px);
}
.task-report-column {
  padding-top: 16px;
}
.backlog-card {
  background: #f7f7f8;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px 12px;
  font-size: 14px;
  line-height: 140%;
  color: #979da5;
  max-width: 180px;
  margin-bottom: 4px;
}
.green-badge {
  background: #54cf94;
}
.blue-badge {
  background: #54bdd4;
}
.task-ongoing-content {
  font-size: 14px;
  line-height: 140%;
  color: #53585f;
  padding: 16px 12px;
}
</style>
