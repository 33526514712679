<template>
  <div class="progress-container">
    <div class="user-greeting">Hi {{ shortenName(currentUser.name) }},</div>
    <div class="text-below">
      {{ $moment().format("dddd, Do MMM YYYY, h:mm:ss a") }}
    </div>
    <div class="stages-container">
      <div
        class="tab-box"
        v-for="(step, index) in productStages"
        :key="step.stage"
      >
        <div
          class="tab-button"
          :class="{
            'active-tab': progressLevel == step.stage   ,
            'completed-tab':
              progressLevel == 6 ? 3 > step.stage : progressLevel > step.stage || (clientProject.accept_roadmap?true:false) ,
          }"
        >
          {{ step.name }}  
        </div>

       <!--  {{ step.stage }}-{{ progressLevel }} - {{  progressLevel == 6 ? 3 > step.stage : progressLevel > step.stage || clientProject.accept_roadmap?true:false }} -->
       
        <div class="tab-line" v-if="index < productStages.length - 1"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";

export default {
  props: {},
  components: {},
  mixins: [shortName],
  data: () => ({
    productStages: [
      {
        stage: 2,
        name: "1. Meet the Team",
      },
      {
        stage: 3,
        name: "2. Review & Sign Agreements",
      },
      {
        stage: 4,
        name: "3. Pay & Kickoff",
      },
      {
        stage: 5,
        name: "4. Review Roadmap",
      },
      /*  {
        stage: 5.5,
        name: "5. Finalize SLA",
      }, */
      {
        stage: 6,
        name: "5. Start Development",
      },
    ],
  }),

  created() {},
  methods: {},
  computed: {
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    progressLevel() {
      let project = this.clientProject;
      if (project.accept_roadmap && !project.accept_sla_date) {
        return 5.5;
      }
      return project.workspace_progress_level;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.progress-container {
  text-align: left;
  /* padding-bottom: 32px; */
}
.stages-container {
  display: flex;
  margin-top: 36px;
}
.tab-box {
  display: flex;
  align-items: center;
}
.tab-button {
  padding: 8px 12px;
  color: #979da5;
  border: 1px solid #979da5;
  background: #ffffff;
  border-radius: 4px;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  cursor: pointer;
}
.tab-line {
  border-top: 1px solid #e4e5e7;
  width: 80px;
  height: 1px;
}
.active-tab {
  border: 1px solid #1fa564;
  color: #1fa564;
}
.completed-tab {
  background: #53585f;
  color: #ffffff;
}
@media (max-width: 1440px) {
  .tab-line {
    width: 40px;
  }
  .tab-button {
    /* padding: 8px 8px; */
  }
}
@media (max-width: 1280px) {
  .tab-line {
    width: 40px;
  }
  .tab-button {
    padding: 8px 8px;
  }
}
</style>
