<template>
  <div>
    <div class="section-wrapper">
      <div class="page-msg">
        <div class="container-title">Draw Product Roadmap</div>
        <div class="description">
          The Product Roadmap maps out the direction of the product development
          over time. Based on learnings from the design sprint, priority is
          given to the most important ideas, features or user stories.
        </div>

        <div v-if="project.share_roadmap != 1" class="alert alert-warning" style="margin-top:30px">
          You are not expected to do anything here! Its expected that the experts would draw his roadmap within 1 - 2 days after his invitation </div>
        <div class="btn-wrapper">
          <button
            class="grupa-disabled-btn"
            :class="{ 'grupa-blue-btn': project.share_roadmap == 1 }"
            :disabled="project.share_roadmap == 0"
            @click="handleCheckRoadmap"
          >
            Check Roadmap
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {
    handleCheckRoadmap() {
      let project_id = this.$route.params.id;
      this.$router.push({ name: "client_roadmap", params: { project_id } });
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject"]),
    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.section-wrapper {
  display: flex;
  text-align: left;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* min-height: calc(100vh - 248px); */
}
.page-msg {
  width: 500px;
}
.container-title {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  text-align: center;
  margin-top: 48px;
  font-weight: bold;
}
.description {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
  margin-top: 16px;
  text-align: center;
}
.btn-wrapper {
  margin-top: 48px;
  text-align: center;
}
</style>
