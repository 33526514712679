var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "section-wrapper" }, [
    _c("div", { staticClass: "left-container" }, [
      _c("div", { staticClass: "section-header" }, [_vm._v("Meet the Team")]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      " +
            _vm._s(
              _vm.teamDetails.team_name
                ? _vm.teamDetails.team_name
                : _vm.teamDetails.name.split(" ")[0] + "'s Team"
            ) +
            "\n      Team has been notified of your commitment to work with them. They’ll\n      respond shortly (typically within 0 - 6hrs)\n    "
        ),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      We’ll send you an email to schedule the initial meeting with the team.\n    "
        ),
      ]),
      _c("div", { staticClass: "grey-text" }, [
        _vm._v(
          "\n      You can request to change your team if you’re not satisfied after this\n      initial meeting.\n    "
        ),
      ]),
    ]),
    _c("div", { staticClass: "right-container" }, [
      _c("div", { staticClass: "grey-header" }, [
        _vm._v("Click the team to view more details"),
      ]),
      _c("div", { staticClass: "selected-team-card" }, [
        _c("div", { staticClass: "team-info" }, [
          _c("div", { staticClass: "team-name" }, [
            _vm._v(
              "\n          \n          " +
                _vm._s(
                  Object.keys(_vm.teamMembers).length == 1
                    ? _vm.teamMembers[0].name
                    : _vm.teamDetails.team_name
                    ? _vm.teamDetails.team_name
                    : _vm.teamDetails.name.split(" ")[0] + "'s Team"
                ) +
                "\n        "
            ),
          ]),
          _c("div", { staticClass: "team-description" }, [
            _vm._v(
              "\n          " +
                _vm._s(_vm.teamDetails.bio ? _vm.teamDetails.bio : "") +
                "\n        "
            ),
          ]),
          _c(
            "div",
            { staticClass: "team-member-list" },
            _vm._l(_vm.teamMembers, function (member, i) {
              return member.name
                ? _c(
                    "div",
                    { key: i, staticClass: "profile-image" },
                    [
                      _c("v-avatar", {
                        staticClass: "profile_bg",
                        style: {
                          backgroundImage:
                            "url(" +
                            (_vm.$imageUrl +
                            (member.profile_picture === null ||
                              member.profile_picture === "")
                              ? _vm.test
                              : member.profile_picture) +
                            ")",
                        },
                        attrs: { size: 30, color: "grey lighten-4" },
                      }),
                    ],
                    1
                  )
                : _vm._e()
            }),
            0
          ),
        ]),
        _c("div", { staticClass: "team-location-info" }, [
          _c("div", { staticClass: "information-list" }, [
            _c("img", {
              attrs: { src: "/img/onboarding/select-team/location.svg" },
            }),
            _vm._v(
              "\n          " +
                _vm._s(_vm.teamDetails.country) +
                "\n          " +
                _vm._s(
                  _vm.teamDetails.city ? ", " + _vm.teamDetails.city : ""
                ) +
                "\n        "
            ),
          ]),
          _vm.teamDetails.team_timezone
            ? _c("div", { staticClass: "information-list list-item" }, [
                _c("img", {
                  attrs: { src: "/img/onboarding/select-team/gmt.svg" },
                }),
                _vm._v(
                  "\n          GMT " +
                    _vm._s(_vm.getTimeZone(_vm.teamDetails.team_timezone)) +
                    " (" +
                    _vm._s(
                      _vm.compareTimeZone(
                        _vm.teamDetails.team_timezone,
                        _vm.userLocation.timezone
                      )
                    ) +
                    ")\n        "
                ),
              ])
            : _vm._e(),
          _vm.teamDetails.compensation_plans
            ? _c("div", { staticClass: "information-list list-item" }, [
                _c("img", {
                  attrs: { src: "/img/onboarding/stack-of-coins.svg" },
                }),
                _c(
                  "span",
                  { staticClass: "open-to" },
                  [
                    _vm._v("Open to\n            "),
                    _vm._l(
                      _vm.convertEquity(_vm.teamDetails.compensation_plans),
                      function (plan, index) {
                        return _c(
                          "span",
                          { key: index, staticClass: "team-equity" },
                          [_vm._v(_vm._s(plan))]
                        )
                      }
                    ),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._m(0),
        ]),
      ]),
      _c("div", { staticClass: "btn-container" }, [
        _c(
          "button",
          {
            staticClass: "grupa-blue-btn",
            on: { click: _vm.handleProceedWithTeam },
          },
          [
            _vm._v("\n        Proceed with this Team\n        "),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.spinner,
                  expression: "spinner",
                },
              ],
              staticClass: "button-spinner",
              staticStyle: { "margin-left": "20px" },
              attrs: { src: "/img/lightbox/preloader.gif" },
            }),
          ]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "information-list list-item" }, [
      _c("img", { attrs: { src: "/img/onboarding/select-team/time.svg" } }),
      _c("span", { staticClass: "open-to" }, [
        _vm._v("Available to work\n            "),
        _c("span", { staticClass: "green-text" }, [_vm._v("up to 30h a week")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }