<template>
  <div class="payment-prompt">
    <div class="payment-text">
      You need to complete your payment for design sprint (${{
        project.design_thinking_fee / 2
      }}
      left). Please click the button to make payment
    </div>
    <div class="payment-actions">
      <div class="make-payment" @click="openStripeModal">Make Payment</div>
      <div class="learn-more" @click="openLearnMore">Learn More</div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  props: {
    openLearnMore: {
      type: Function,
      required: true,
    },
    openStripeModal: {
      type: Function,
      required: true,
    },
  },

  data: () => ({
    openStripe: false,
    modalType: "design_sprint",
    paymentInfo: {
      purpose: "design sprint balance",
      payment_plan: "none",
      iteration_id: null,
    },
  }),

  created() {},
  methods: {
    closeModal() {
      this.openStripe = false;
    },
    closeDialog() {
      this.isLearnMore = false;
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    ...mapState("clientPrekickoff", ["clientCreditCards"]),
    ...mapState("userManagement", ["currentUser"]),
    user() {
      return this.currentUser;
    },
    project() {
      return this.clientProject;
    },
    userCreditCard() {
      return this.clientCreditCards;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.payment-prompt {
  display: flex;
  padding: 16px;
  background: #f4ede3;
  justify-content: center;
  position: absolute;
  top: 62px;
  width: calc(100% - 200px);
  position: fixed;
  left: 200px;
}
.payment-text {
  font-size: 14px;
  line-height: 130%;
  color: #de9221;
}
.payment-actions {
  display: flex;
  margin-left: 123px;
}
.make-payment {
  font-size: 14px;
  line-height: 130%;
  color: #0781f2;
  cursor: pointer;
}
.learn-more {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-left: 32px;
  cursor: pointer;
}
</style>
