<template>
  <div class="manager-report">
    <div class="progress-text">PROGRESS REPORT</div>
    <div class="bolder-header">Current Sprint</div>
    <div class="sprint-name">
      <div class="sprint-no">Sprint {{ currentSprint.sprint }}</div>
      <div>
        <img src="/img/sprint/clock-ongoing.svg" />
        <!-- <img src="/img/sprint/clock-overdue.svg" /> -->
      </div>
      <!-- <span>2 days overdue</span> -->
      <span style="color: #54cf94">
        {{ sprintDate }}
      </span>
    </div>
    <div class="overview-progress-bar-wrap">
      <div class="overview-bar-con">
        <div
          class="bar-auto-increase"
          :style="`width:${percentageTasksDone(tasks)}%`"
        ></div>
      </div>
      <div class="overview-percent-div">{{ percentageTasksDone(tasks) }}%</div>
    </div>
    <div class="task-completed-count">
      {{ completedTasks.length }}
      /{{ tasks.length }} tasks completed
    </div>
    <div class="current-iteration-info">
      <div class="bolder-header">Current Iteration  </div>
      <div class="iteration-name">
        <div class="itr-no">Iteration {{ currentIteration.it_number }}</div>
        <div>
          <img src="/img/sprint/clock-ongoing.svg" />
        </div>
        <span style="color: #54cf94">
          {{ iterationStartDate }}
        </span>
      </div>
      <div class="overview-progress-bar-wrap">
        <div class="overview-bar-con">
          <div class="bar-auto-increase"></div>
        </div>
        <div class="overview-percent-div">0%</div>
      </div>
      <div class="task-completed-count">
        0/{{ currentFeatures.length }} features completed
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin],
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),
    projectDoc() {
      return this.clientProjectDoc.documents;
    },
    currentIteration() {
      return this.projectDoc.iterations.find(
        (itr) => itr.id == this.clientProject.current_iteration
      );
    },
    currentSprint() {
     // console.log("CS : "+JSON.stringify(this.projectDoc.sprints.find((sprint) => sprint.status == 1)));
      return this.projectDoc.sprints.find((sprint) => sprint.status == 1);
    },
    tasks() {
      return this.projectDoc.tasks.filter(
        (task) => task.sprint_id == this.currentSprint.id
      );
    },
    completedTasks() {
      return this.tasks.filter((task) => task.task_status_id == 5);
    },
    sprintDate() {
      let { start_date } = this.currentSprint;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
          ${this.addDaysFromDate(start_date, 4)}`;
      } else {
        return "Not Started";
      }
    },
    currentFeatures() {
      return this.projectDoc.features.filter(
        (feature) => feature.iteration_id == this.currentIteration.id
      );
    },
    iterationStartDate() {
      let { start_date, due_date } = this.currentIteration;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
        ${this.$moment(due_date).format("Do MMM")} `;
      } else {
        return "Not Started";
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.manager-report {
  background: #ffffff;
  border-radius: 4px;
  width: 30%;
  padding: 24px 24px 48px;
  text-align: left;
  margin-right: 16px;
}
.progress-text {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.bolder-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 24px;
}
.iteration-name,
.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 12px;
  display: flex;
}
.iteration-name span,
.sprint-name span {
  color: #de9221;
  margin-left: 8px;
}
.iteration-name img,
.sprint-name img {
  margin-left: 8px;
}
.itr-no,
.sprint-no {
  border-right: 1px solid #e4e5e7;
  padding-right: 8px;
}
.overview-progress-bar-wrap {
  display: flex;
  margin-top: 12px;
}
.overview-bar-con {
  width: 121px;
  height: 4px;
  background: #e4e5e7;
  border-radius: 4px;
  margin-top: 5px;
}
.overview-percent-div {
  font-size: 12px;
  line-height: 100%;
  color: #1b1e22;
  margin-left: 9px;
}
.bar-auto-increase {
  background: #0781f2;
  border-radius: 4px;
  height: 100%;
  width: 0%;
}
.task-completed-count {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 15px;
}
.current-iteration-info {
  margin-top: 24px;
  border-top: 1px solid #e4e5e7;
}
</style>
