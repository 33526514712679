<template>
  <DialogComponent dialogTitle="Design Sprint Balance" v-bind="{ closeDialog }">
    <div id="main-children">
      <div class="container-box">
        <div class="inner-container">
          <div class="right-div">
            <div class="cost-text">COST</div>
            <div class="sprint-amount">
              ${{ priceRoundUp(project.design_thinking_fee).price }}
              <span style="font-size: 23px; color: #000">{{
                priceRoundUp(project.design_thinking_fee).decimal
              }}</span>
            </div>
            <div class="pay-info">
              Pay 50% (${{ project.design_thinking_fee / 2 }}) now, pay the rest
              at the end of the sprint.
            </div>
            <div class="cost-text">DELIVERABLES - What you get</div>
          </div>
        </div>
      </div>
      <div class="explain-message">
        The design sprint costs a total of {{ project.design_thinking_fee }}.
        You paid <span>${{ project.design_thinking_fee / 2 }}</span> at the
        beginning of the design sprint. Now the balance is due, midway through
        the sprint.
      </div>
    </div>
    <template v-slot:dialog-footer>
      <div>
        <button class="grupa-blue-btn" @click="closeDialog">
          Okay, Got it
        </button>
      </div>
    </template>
  </DialogComponent>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";
export default {
  props: {
    project: {
      type: Object,
      required: true,
    },
    closeDialog: {
      type: Function,
      required: true,
    },
  },
  components: { DialogComponent },

  data: () => ({}),

  created() {},
  methods: {
    priceRoundUp(data) {
      let price = data.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
      return {
        price: price.substring(0, price.length - 2),
        decimal: price.substring(price.length - 2, price.length),
      };
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.container-box {
  background: #e4e5e7;
  border-radius: 4px;
  width: 356px;
  height: 174px;
  padding: 7px;
}
.inner-container {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.explain-message {
  width: 356px;
  font-size: 14px;
  line-height: 130%;
  color: #6f7680;
  margin-top: 24px;
  text-align: left;
}
.explain-message span {
  color: #1fa564;
}
.right-div {
  width: 195px;
  text-align: left;
}
.sprint-duration {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 24px;
}
.sprint-duration span {
  color: #1b1e22;
}
.action-btn {
  margin-top: 108px;
}
.cost-text {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  text-align: left;
  margin-top: 10px;
}
.sprint-amount {
  font-size: 46px;
  line-height: 100%;
  letter-spacing: -1px;
  color: #1b1e22;
  margin-top: 8px;
}
.pay-info {
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
  margin-top: 8px;
  /* width: 237px; */
}
.item_list {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 12px;
}
</style>
