import { render, staticRenderFns } from "./waitRoadMap.vue?vue&type=template&id=23b9f0a9&scoped=true&"
import script from "./waitRoadMap.vue?vue&type=script&lang=js&"
export * from "./waitRoadMap.vue?vue&type=script&lang=js&"
import style0 from "./waitRoadMap.vue?vue&type=style&index=0&id=23b9f0a9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23b9f0a9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/oeze/Documents/grupa/grupa-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('23b9f0a9')) {
      api.createRecord('23b9f0a9', component.options)
    } else {
      api.reload('23b9f0a9', component.options)
    }
    module.hot.accept("./waitRoadMap.vue?vue&type=template&id=23b9f0a9&scoped=true&", function () {
      api.rerender('23b9f0a9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/ClientDashboard/workspace/productEngagement/roadMap/waitRoadMap.vue"
export default component.exports