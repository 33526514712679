<template>
  <div>
    <div class="payment-wrappper">
      <div class="plan-text">
        Payments are to be done before corresponding work starts.
      </div>
      <div class="plan-text">
        We hold your payments in escrow and disburse weekly to the team only
        after you have confirmed that the sprint for that week has been
        delivered.
      </div>
      <div class="payment-selection">
        <div class="payment-options">
          <div class="payment-ques">How would you like to pay?</div>

          <div
            class="payment-card"
            @click="selectedOption = option.name"
            v-for="(option, i) in paymentOptions"
            :key="i"
            :style="selectedOption == option.name ? 'background: #FFFFFF;' : ''"
          >
            <div class="option-name-price">
              <div class="div-option-name">
                <img
                  :src="`/img/dashboard/${
                    selectedOption == option.name
                      ? 'radio-checked.svg'
                      : 'radio-unchecked.svg'
                  }`"
                  alt=""
                  style="margin-right: 16px"
                />
                {{ option.name }}
              </div>
              <div class="div-option-price">
                ${{ paymentPrices(option.name).toLocaleString() }}
                <span>/installment</span>
              </div>
            </div>
            <div class="price-description">
              <div class="option-descrip">
                {{ option.description }}
              </div>
              <div class="option-descrip">
                <div v-if="i > 0" class="option-discount">
                  {{ option.discount }}
                </div>
                <div v-if="i < 2" :style="i == 0 ? 'margin-top: 17px;' : ''">
                  {{ option.installments }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="payment-details">
          <div class="total-charge">Total Charge</div>
          <div style="margin-top: 4px; display: flex">
            <div class="total-amount">
              ${{ paymentPrices(selectedOption).toLocaleString() }}
            </div>
            <div v-if="selectedOption == 'MONTHLY'" class="actual-amount">
              ${{ project.price }}
            </div>
            <div v-if="selectedOption == 'MID-ITERATION'" class="actual-amount">
              ${{ project.price / 2 }}
            </div>
          </div>
          <div class="amount-divider"></div>
          <div class="plan-selected">
            <div class="option-info-div">YOU SELECTED</div>
            <div class="service-plan-name">
              {{ project.experience }} Service Plan
              <img
                src="/img/dashboard/learn-more.svg"
                style="margin-left: 8px"
              />
            </div>
            <div class="service-plan-price">
              {{ (project.price / project.iteration_cycle).toLocaleString() }}
              <span class="option-info-div"> per weekly sprint</span>
            </div>
          </div>
          <div class="plan-selected">
            <div class="option-info-div">OUR PAYMENT PARTNERS CHARGE</div>
            <div class="service-plan-name">Transaction Fee</div>
            <div class="service-plan-price">3%</div>
          </div>
          <div v-if="selectedOption == 'MONTHLY'" class="plan-selected">
            <div class="option-info-div">WE GIFT YOU</div>
            <div class="service-plan-name">Discount</div>
            <div
              v-if="selectedOption == 'MONTHLY'"
              class="service-plan-price"
              style="color: #d45b54"
            >
              5%
            </div>
          </div>
        </div>
      </div>
      <div class="action-container">
        <button class="grupa-blue-btn" @click="goToPayment">
          Pay ${{ paymentPrices(selectedOption).toLocaleString() }} Now
        </button>
        <div class="stripe-copy-right">
          <img src="/img/dashboard/stripe-lock.svg" style="margin-right: 5px" />
          Secured by <span>Stripe</span>
        </div>
      </div>
    </div>
    <StripeModal
      v-if="openStripe"
      v-on:close="closeModal"
      v-bind:amount="amountDue"
      v-bind="{ modalType, paymentInfo, userCreditCard, user, isWorkSpace }"
    />
  </div>
</template>

<script>
import StripeModal from "@/components/stripeModal";
import { mapState, mapActions } from "vuex";
import { clientPaymentCards } from "@/api";
export default {
  props: {},
  components: {
    StripeModal,
  },

  data: () => ({
    isWorkSpace: true,
    paymentOptions: [
      {
        name: "BI-SPRINT",
        description:
          "Pay an installment every 2 weeks, at the beginning of every 2 sprints",
        installments: "2 installments",
        price: "$5,000",
      },
      {
        name: "MONTHLY",
        description: "Pay per monthly iteration (4 sprints) upfront",
        price: "$27,360",
        discount: "5% discount",
      },
    ],
    selectedOption: "MONTHLY",
    amountDue: null,
    modalType: "design_sprint",
    openStripe: false,
    paymentInfo: {
      purpose: "kickoff fee",
      payment_plan: "bi-weekly",
      iteration_id: null,
    },
    userCreditCard: [],
  }),

  created() {
    this.paymentInfo.iteration_id = this.project.current_iteration
      ? this.project.current_iteration
      : this.iterations[0].id;
    this.clientCreditCard();
  },
  methods: {
    paymentPrices(plan) {
      const project_price = this.project.price;
      if (plan == "BI-SPRINT" || plan == 0) {
        return (project_price / this.project.iteration_cycle) * 2;
      } else if (plan == "MID-ITERATION" || plan == 1) {
        const discount = (1.5 / 100) * project_price;
        const price = (project_price - discount) / 2;
        return price;
      } else if (plan == "MONTHLY" || plan == 2) {
        const discount = (5 / 100) * project_price;
        const price = project_price - discount;
        return price;
      }
    },
    goToPayment() {
      this.paymentInfo.payment_plan =
        this.selectedOption == "BI-SPRINT"
          ? "bi-weekly"
          : this.selectedOption == "MID-ITERATION"
          ? "dual-deposit"
          : "iteration";
      // this.paymentInfo.iteration_id = this.findIteration.id;
      this.amountDue = this.paymentPrices(this.selectedOption);
      this.openStripe = true;
    },
    closeModal() {
      this.openStripe = false;
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.userCreditCard = response.data.cards;
          this.$developConsole(response.data.cards, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    findIteration() {
      let notStarted = this.iterations.filter(
        (iteration) => iteration.status == 0
      );
      let current = notStarted.sort((a, b) => a.id - b.id);
      return current[0];
    },
  },
  computed: {
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),
    ...mapState("userManagement", ["currentUser"]),
    user() {
      return this.currentUser;
    },
    project() {
      return this.clientProject;
    },
    iterations() {
      return this.clientProjectDoc.documents.iterations;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.plan-text {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
  width: 521px;
}
.payment-wrappper .plan-text:first-child {
  margin-top: 24px;
  margin-bottom: 34px;
}
.payment-selection {
  display: flex;
  margin-top: 48px;
  justify-content: space-between;
}
.payment-ques {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.payment-card {
  margin-top: 24px;
  background: #f7f7f8;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 16px;
  width: 521px;
  cursor: pointer;
  margin-bottom: 24px;
}
.option-name-price,
.price-description {
  display: flex;
  justify-content: space-between;
}
.div-option-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.div-option-price {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.div-option-price span {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.price-description {
  margin-top: 16px;
}
.option-descrip {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  max-width: 355px;
}
.option-discount {
  color: #1fa564;
}
.payment-details {
  background: #ffffff;
  border-radius: 4px;
  padding: 24px;
  width: 260px;
}
.total-charge {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.total-amount {
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.actual-amount {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  text-decoration: line-through;
  margin-top: 8px;
  margin-left: 8px;
}
.amount-divider {
  border-top: 1px solid #e4e5e7;
  margin-top: 24px;
}
.plan-selected {
  margin-top: 24px;
}
.option-info-div {
  font-size: 10px;
  line-height: 12px;
  color: #979da5;
}
.service-plan-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
  text-transform: capitalize;
}
.service-plan-price {
  font-size: 14px;
  line-height: 130%;
  color: #1fa564;
  margin-top: 8px;
}
.service-plan-price span {
  margin-top: 4px;
}
.stripe-copy-right {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 12px;
}
.stripe-copy-right span {
  color: #1b1e22;
}
</style>
