var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar", { attrs: { stageTitle: "Workspace" } }),
      _c(
        "div",
        { staticClass: "main-wrapper" },
        [
          _c("ProgressBar"),
          _c(
            "div",
            { staticClass: "content-container" },
            [
              _vm.progressLevel == 2 ? _c("MeetDTeam") : _vm._e(),
              _vm.progressLevel == 3 || _vm.progressLevel == 6
                ? _c(
                    "SignPlatformAgreement",
                    _vm._b(
                      {},
                      "SignPlatformAgreement",
                      {
                        project: _vm.project,
                        handleMakePayment: _vm.handleMakePayment,
                      },
                      false
                    )
                  )
                : _vm._e(),
              _vm.progressLevel == 4
                ? _c(
                    "PayAndKickoff",
                    _vm._b(
                      {},
                      "PayAndKickoff",
                      { handleMakePayment: _vm.handleMakePayment },
                      false
                    )
                  )
                : _vm._e(),
              _vm.progressLevel == 5 && !_vm.project.accept_roadmap
                ? _c("DrawRoadMap")
                : _vm._e(),
              (_vm.progressLevel == 6 ||
                (_vm.progressLevel == 5 && _vm.project.accept_roadmap)) &&
              _vm.project.accept_roadmap
                ? _c(
                    "StartProductDevelopment",
                    _vm._b(
                      {},
                      "StartProductDevelopment",
                      {
                        project: _vm.project,
                        monthlyPrice: _vm.monthlyPrice,
                        biSprintPrice: _vm.biSprintPrice,
                        handleSelectOption: _vm.handleSelectOption,
                        selectedOption: _vm.selectedOption,
                        handleKickoffFee: _vm.handleKickoffFee,
                      },
                      false
                    )
                  )
                : _vm._e(),
              _vm.openStripe
                ? _c(
                    "StripeModal",
                    _vm._b(
                      {
                        attrs: { amount: _vm.amountToPay },
                        on: { close: _vm.closeModal },
                      },
                      "StripeModal",
                      {
                        modalType: _vm.modalType,
                        paymentInfo: _vm.paymentInfo,
                        userCreditCard: _vm.userCreditCard,
                        user: _vm.user,
                      },
                      false
                    )
                  )
                : _vm._e(),
              _vm.paymentModalVisible ? _c("PaymentSuccess") : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }