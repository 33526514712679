var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "payment-wrappper" }, [
        _c("div", { staticClass: "plan-text" }, [
          _vm._v(
            "\n      Payments are to be done before corresponding work starts.\n    "
          ),
        ]),
        _c("div", { staticClass: "plan-text" }, [
          _vm._v(
            "\n      We hold your payments in escrow and disburse weekly to the team only\n      after you have confirmed that the sprint for that week has been\n      delivered.\n    "
          ),
        ]),
        _c("div", { staticClass: "payment-selection" }, [
          _c(
            "div",
            { staticClass: "payment-options" },
            [
              _c("div", { staticClass: "payment-ques" }, [
                _vm._v("How would you like to pay?"),
              ]),
              _vm._l(_vm.paymentOptions, function (option, i) {
                return _c(
                  "div",
                  {
                    key: i,
                    staticClass: "payment-card",
                    style:
                      _vm.selectedOption == option.name
                        ? "background: #FFFFFF;"
                        : "",
                    on: {
                      click: function ($event) {
                        _vm.selectedOption = option.name
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "option-name-price" }, [
                      _c("div", { staticClass: "div-option-name" }, [
                        _c("img", {
                          staticStyle: { "margin-right": "16px" },
                          attrs: {
                            src:
                              "/img/dashboard/" +
                              (_vm.selectedOption == option.name
                                ? "radio-checked.svg"
                                : "radio-unchecked.svg"),
                            alt: "",
                          },
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(option.name) +
                            "\n            "
                        ),
                      ]),
                      _c("div", { staticClass: "div-option-price" }, [
                        _vm._v(
                          "\n              $" +
                            _vm._s(
                              _vm.paymentPrices(option.name).toLocaleString()
                            ) +
                            "\n              "
                        ),
                        _c("span", [_vm._v("/installment")]),
                      ]),
                    ]),
                    _c("div", { staticClass: "price-description" }, [
                      _c("div", { staticClass: "option-descrip" }, [
                        _vm._v(
                          "\n              " +
                            _vm._s(option.description) +
                            "\n            "
                        ),
                      ]),
                      _c("div", { staticClass: "option-descrip" }, [
                        i > 0
                          ? _c("div", { staticClass: "option-discount" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(option.discount) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        i < 2
                          ? _c(
                              "div",
                              { style: i == 0 ? "margin-top: 17px;" : "" },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(option.installments) +
                                    "\n              "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
          _c("div", { staticClass: "payment-details" }, [
            _c("div", { staticClass: "total-charge" }, [
              _vm._v("Total Charge"),
            ]),
            _c(
              "div",
              { staticStyle: { "margin-top": "4px", display: "flex" } },
              [
                _c("div", { staticClass: "total-amount" }, [
                  _vm._v(
                    "\n            $" +
                      _vm._s(
                        _vm.paymentPrices(_vm.selectedOption).toLocaleString()
                      ) +
                      "\n          "
                  ),
                ]),
                _vm.selectedOption == "MONTHLY"
                  ? _c("div", { staticClass: "actual-amount" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(_vm.project.price) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
                _vm.selectedOption == "MID-ITERATION"
                  ? _c("div", { staticClass: "actual-amount" }, [
                      _vm._v(
                        "\n            $" +
                          _vm._s(_vm.project.price / 2) +
                          "\n          "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _c("div", { staticClass: "amount-divider" }),
            _c("div", { staticClass: "plan-selected" }, [
              _c("div", { staticClass: "option-info-div" }, [
                _vm._v("YOU SELECTED"),
              ]),
              _c("div", { staticClass: "service-plan-name" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.project.experience) +
                    " Service Plan\n            "
                ),
                _c("img", {
                  staticStyle: { "margin-left": "8px" },
                  attrs: { src: "/img/dashboard/learn-more.svg" },
                }),
              ]),
              _c("div", { staticClass: "service-plan-price" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      (
                        _vm.project.price / _vm.project.iteration_cycle
                      ).toLocaleString()
                    ) +
                    "\n            "
                ),
                _c("span", { staticClass: "option-info-div" }, [
                  _vm._v(" per weekly sprint"),
                ]),
              ]),
            ]),
            _vm._m(0),
            _vm.selectedOption == "MONTHLY"
              ? _c("div", { staticClass: "plan-selected" }, [
                  _c("div", { staticClass: "option-info-div" }, [
                    _vm._v("WE GIFT YOU"),
                  ]),
                  _c("div", { staticClass: "service-plan-name" }, [
                    _vm._v("Discount"),
                  ]),
                  _vm.selectedOption == "MONTHLY"
                    ? _c(
                        "div",
                        {
                          staticClass: "service-plan-price",
                          staticStyle: { color: "#d45b54" },
                        },
                        [_vm._v("\n            5%\n          ")]
                      )
                    : _vm._e(),
                ])
              : _vm._e(),
          ]),
        ]),
        _c("div", { staticClass: "action-container" }, [
          _c(
            "button",
            { staticClass: "grupa-blue-btn", on: { click: _vm.goToPayment } },
            [
              _vm._v(
                "\n        Pay $" +
                  _vm._s(
                    _vm.paymentPrices(_vm.selectedOption).toLocaleString()
                  ) +
                  " Now\n      "
              ),
            ]
          ),
          _vm._m(1),
        ]),
      ]),
      _vm.openStripe
        ? _c(
            "StripeModal",
            _vm._b(
              {
                attrs: { amount: _vm.amountDue },
                on: { close: _vm.closeModal },
              },
              "StripeModal",
              {
                modalType: _vm.modalType,
                paymentInfo: _vm.paymentInfo,
                userCreditCard: _vm.userCreditCard,
                user: _vm.user,
                isWorkSpace: _vm.isWorkSpace,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plan-selected" }, [
      _c("div", { staticClass: "option-info-div" }, [
        _vm._v("OUR PAYMENT PARTNERS CHARGE"),
      ]),
      _c("div", { staticClass: "service-plan-name" }, [
        _vm._v("Transaction Fee"),
      ]),
      _c("div", { staticClass: "service-plan-price" }, [_vm._v("3%")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "stripe-copy-right" }, [
      _c("img", {
        staticStyle: { "margin-right": "5px" },
        attrs: { src: "/img/dashboard/stripe-lock.svg" },
      }),
      _vm._v("\n        Secured by "),
      _c("span", [_vm._v("Stripe")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }