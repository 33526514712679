<template>
  <div>
    <div class="page-layout">
      <div class="container-title">Start Product Development</div>
      <div class="subcribe-text">
        Subscribe and Pay to build out iterations of your product as outlined in
        the Product Roadmap.
      </div>
      <div class="tab-container">
        <div class="tabs-wrap">
          <div
            class="tab-div"
            :class="{ 'active-tab': activeTab == 'subcribe' }"
            @click="switchTab('subcribe')"
          >
            1. Subscribe
          </div>
          <div
            :class="{ 'active-tab': activeTab == 'payment' }"
            class="tab-div"
            @click="switchTab('payment')"
          >
            2. Select Payment Plan
          </div>
        </div>
        <div class="tabs-content">
          <SubscribeComponent
            v-bind="{ switchTab }"
            v-if="activeTab == 'subcribe'"
          />
          <PaymentComponent v-if="activeTab == 'payment'" />
          <AlertSuccess v-if="successMsg" time="7" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import AlertSuccess from "@/components/alertSuccess";
import SubscribeComponent from "./subcribePlan";
import PaymentComponent from "./paymentComponent";
export default {
  props: {},
  components: {
    SubscribeComponent,
    PaymentComponent,
    AlertSuccess,
  },

  data: () => ({
    activeTab: "payment",
  }),

  created() {},
  methods: {
    switchTab(tab) {
      this.activeTab = tab;
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.container-title {
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
}
.subcribe-text {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 8px;
  width: 521px;
}
.tab-container {
  margin-top: 48px;
}
.tabs-wrap {
  display: flex;
  border-bottom: 2px solid #e4e5e7;
}
.tab-div {
  padding-bottom: 12px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  cursor: pointer;
}
.tabs-wrap .tab-div:last-child {
  margin-left: 72px;
}
.active-tab {
  color: #1b1e22;
  border-bottom: 2px solid #1b1e22;
  margin-bottom: -2px;
}
</style>
