var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "page-layout" }, [
      _c("div", { staticClass: "page-msg" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.teamBio.team_name) +
            " has been notified of your commitment to work\n      with them. They’ll respond shortly (typically within 0 - 6hrs)\n    "
        ),
      ]),
      _c("div", { staticClass: "team-wrapper" }, [
        _c("div", { staticClass: "top-info" }, [
          _c("div", { staticClass: "team-info" }, [
            _c("div", { staticClass: "team-name" }, [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.teamBio.team_name) +
                  "\n            "
              ),
              _c("span", {}, [
                _vm._v(" " + _vm._s(_vm.teamBio.grupa_score) + " "),
              ]),
            ]),
            _c("div", { staticClass: "team-experience" }, [
              _vm._v("Principal Engineering Team"),
            ]),
            _c("div", { staticClass: "team-location" }, [
              _c("img", {
                attrs: { src: "/img/onboarding/location-icon.svg" },
              }),
              _vm._v(
                "\n            " +
                  _vm._s(_vm.teamBio.city ? _vm.teamBio.city + "," : "") +
                  "\n            " +
                  _vm._s(_vm.teamBio.country ? _vm.teamBio.country : "") +
                  "\n            "
              ),
              _vm.teamBio.timezone
                ? _c("span", [
                    _vm._v(
                      "(GMT " +
                        _vm._s(_vm.getTimeZone(_vm.teamBio.timezone)) +
                        ")"
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "team-bio" }, [
              _vm._v(_vm._s(_vm.teamBio.bio)),
            ]),
            _c("div", { staticClass: "action-div" }),
          ]),
          _c("div", { staticClass: "team-members" }, [
            _vm._m(0),
            _c(
              "div",
              { staticClass: "team-developers" },
              _vm._l(_vm.teamMembers, function (member, i) {
                return _c(
                  "div",
                  { key: i, staticClass: "dev-info" },
                  [
                    _c("v-avatar", {
                      staticClass: "profile_bg",
                      style: {
                        backgroundImage:
                          "url(" +
                          (_vm.$imageUrl + member.profile_picture || _vm.test) +
                          ")",
                      },
                      attrs: { size: 72, color: "grey lighten-4" },
                    }),
                    member.name
                      ? _c("div", { staticClass: "dev_name" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(
                                _vm.abbreviateFullName(
                                  member.name.split(" ")[0]
                                )
                              ) +
                              "\n              "
                          ),
                        ])
                      : _vm._e(),
                    member.team_role
                      ? _c("div", { staticClass: "dev_role" }, [
                          _vm._v(
                            "\n                " +
                              _vm._s(member.team_role.split(" ")[0]) +
                              "\n              "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
        _c("div", { staticClass: "bottom-info" }, [
          _c("div", { staticClass: "team-skills" }, [
            _c("div", { staticClass: "section-title" }, [
              _vm._v("SKILLS & LANGUAGES"),
            ]),
            _vm._m(1),
            _c(
              "div",
              { staticClass: "team-stack" },
              _vm._l(_vm.teamFrameWork(_vm.teamMembers), function (stack, i) {
                return _c("div", { key: i, staticClass: "stack-div" }, [
                  _vm._v("\n              " + _vm._s(stack) + "\n            "),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "service-plan" }, [
            _c("div", { staticClass: "section-title" }, [
              _vm._v("SERVICE PLAN"),
            ]),
            _c("div", { staticClass: "selected-plan" }, [
              _c("div", { staticClass: "plan-details" }, [
                _c("div", { staticClass: "plan-name" }, [
                  _vm._v(_vm._s(_vm.project.work_mode)),
                ]),
                _c("div", { staticClass: "plan-info" }, [_vm._v("team of 5")]),
                _c("div", { staticClass: "plan-info" }, [
                  _vm._v("100 work hours per week"),
                ]),
                _c("div", { staticClass: "plan-info" }, [
                  _vm._v("8 weeks per iteration"),
                ]),
                _c("div", { staticClass: "plan-price" }, [
                  _vm._v("$2,500 per weekly sprint"),
                ]),
              ]),
              _vm._m(2),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-header" }, [
      _c("div", { staticClass: "team-header" }, [_vm._v("TEAM")]),
      _c("div", { staticClass: "team-msg" }, [
        _vm._v(
          "\n              Number of actual team members may vary based on the service plan\n              you have selected.\n            "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "team-speciality" }, [
      _c("img", { attrs: { src: "/img/onboarding/speciality-icon.svg" } }),
      _vm._v("\n            Web & Mobile\n          "),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "plan-icon" }, [
      _c("img", { attrs: { src: "/img/onboarding/upstart-icon.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }