var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "payment-prompt" }, [
    _c("div", { staticClass: "payment-text" }, [
      _vm._v(
        "\n    You need to complete your payment for design sprint ($" +
          _vm._s(_vm.project.design_thinking_fee / 2) +
          "\n    left). Please click the button to make payment\n  "
      ),
    ]),
    _c("div", { staticClass: "payment-actions" }, [
      _c(
        "div",
        { staticClass: "make-payment", on: { click: _vm.openStripeModal } },
        [_vm._v("Make Payment")]
      ),
      _c(
        "div",
        { staticClass: "learn-more", on: { click: _vm.openLearnMore } },
        [_vm._v("Learn More")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }