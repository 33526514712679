var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "page-layout" }, [
        _c("div", { staticClass: "section-cover" }, [
          _c("div", { staticClass: "schedule-meeting" }, [
            _vm._v(
              "\n        Schedule a 30-minute evlauation call with\n        "
            ),
            _c("span", [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.teamMembers[0].team_name) +
                  "\n        "
              ),
            ]),
          ]),
          _c("div", { staticClass: "meeting-info" }, [
            _vm._v(
              "\n        We advise that this is done within 48 hours, as the team is on\n        standby.\n      "
            ),
          ]),
          _c("div", { staticClass: "calendar-embed" }, [
            _vm.project.preview_meeting_start_date == null
              ? _c(
                  "div",
                  {
                    staticClass: "schedule-text",
                    on: { click: _vm.handleCalendly },
                  },
                  [
                    _vm._v(
                      "\n          Click Here To Schedule Meeting\n        "
                    ),
                  ]
                )
              : _c("div", { staticClass: "meeting-details" }, [
                  _c("img", {
                    attrs: { src: "/img/onboarding/schedule-icon.svg" },
                  }),
                  _c("div", { staticClass: "meeting-time" }, [
                    _vm._v("\n            Meeting scheduled for\n            "),
                    _c("span", {}, [
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm
                              .$moment(_vm.project.preview_meeting_start_date)
                              .format("dddd, MMMM Do YYYY, h:mm:ss a")
                          ) +
                          "\n            "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "meeting-link" }, [
                    _vm._v("\n            Meeting link:\n            "),
                    _c("span", [
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: _vm.project.preview_meeting_hangout_link,
                          },
                        },
                        [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.project.preview_meeting_hangout_link) +
                              "\n              "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
          ]),
          _c("div", { staticClass: "bottom-div" }, [
            _c(
              "div",
              { staticClass: "meeting-goal" },
              [
                _c("div", { staticClass: "meeting-header" }, [
                  _vm._v("MEETING OBJECTIVES"),
                ]),
                _vm._l(_vm.objectives, function (obj, i) {
                  return _c("div", { key: i, staticClass: "objectives" }, [
                    _c("img", {
                      attrs: { src: "/img/onboarding/grey-checked.svg" },
                    }),
                    _vm._v("\n            " + _vm._s(obj) + "\n          "),
                  ])
                }),
              ],
              2
            ),
            _vm.project.preview_meeting_start_date !== null
              ? _c(
                  "div",
                  { staticClass: "meeting-attendees" },
                  [
                    _c("div", { staticClass: "meeting-header" }, [
                      _vm._v("MEETING INVITEES (5)"),
                    ]),
                    _vm._l(_vm.teamMembers, function (member, i) {
                      return member.name
                        ? _c("div", { key: i, staticClass: "members-box" }, [
                            _c("div", { staticClass: "team-member" }, [
                              _c(
                                "div",
                                { staticClass: "profile-image" },
                                [
                                  _c("v-avatar", {
                                    staticClass: "profile_bg",
                                    style: {
                                      backgroundImage:
                                        "url(" +
                                        (_vm.$imageUrl +
                                          member.profile_picture || _vm.test) +
                                        ")",
                                    },
                                    attrs: {
                                      size: 32,
                                      color: "grey lighten-4",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "dev-details" }, [
                                _c("div", { staticClass: "dev-name" }, [
                                  _vm._v(_vm._s(member.name)),
                                ]),
                                _c("div", { staticClass: "dev-role" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        member.team_lead == 1
                                          ? "Team Lead/"
                                          : ""
                                      ) +
                                      "\n                  " +
                                      _vm._s(member.team_role) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e()
                    }),
                    _vm._m(0),
                  ],
                  2
                )
              : _vm._e(),
          ]),
        ]),
      ]),
      _vm.showCalendly
        ? _c(
            "CalendlyModal",
            _vm._b(
              {
                attrs: { firstMeeting: true, user: _vm.currentUser },
                on: { closeModal: _vm.closeCalendly },
              },
              "CalendlyModal",
              { loadCalendly: _vm.loadCalendly },
              false
            )
          )
        : _vm._e(),
      _vm.isMeetingSuccess
        ? _c(
            "MeetingSuccess",
            _vm._b(
              { on: { closeModal: _vm.closeSuccessModal } },
              "MeetingSuccess",
              {
                meetingLoader: _vm.meetingLoader,
                meetingPayload: _vm.meetingPayload,
              },
              false
            )
          )
        : _vm._e(),
      _vm.openDialog
        ? _c(
            "RateMeetingDialog",
            _vm._b(
              { attrs: { meetingTitle: "Product Team", form: _vm.ratingForm } },
              "RateMeetingDialog",
              {
                closeDialog: _vm.closeDialog,
                submitRating: _vm.submitRating,
                ratingSpinner: _vm.ratingSpinner,
                ratingSucess: _vm.ratingSucess,
                failForm: _vm.failForm,
                isMeetingHold: _vm.isMeetingHold,
                handleMeetingStatus: _vm.handleMeetingStatus,
                isRatingForm: _vm.isRatingForm,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "members-box" }, [
      _c("div", { staticClass: "team-member" }, [
        _c("div", { staticClass: "profile-image" }, [
          _c(
            "div",
            {
              staticClass: "grupa-icon user-name-icon",
              staticStyle: { height: "32px", width: "32px" },
            },
            [_vm._v("\n                  GR\n                ")]
          ),
        ]),
        _c("div", { staticClass: "dev-details" }, [
          _c("div", { staticClass: "dev-name" }, [_vm._v("Grupa")]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }