<template>
  <div>
    <div class="page-layout">
      <div class="sprint-msg">
        Design Sprint is a week-long collaborative process with activites that
        ensures a product solves the problem form the users perspective, is
        techniccally feasible and meets business requirements. Your Product Team
        will draw up the schedule.
      </div>
      <div class="empty-box">
        <div><img src="/img/onboarding/schedule-icon.svg" /></div>
        <div class="waiting-msg">
          Waiting for team to generate design sprint schedule...
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  components: {},

  data: () => ({}),

  created() {},
  methods: {},
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.sprint-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 512px;
}
.empty-box {
  margin-top: 24px;
  width: 521px;
  height: 193px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  flex-direction: column;
}
.waiting-msg {
  margin-top: 16px;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #979da5;
  width: 311.86px;
}
</style>
