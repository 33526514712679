var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "project-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "", name: "GrupaSLA" },
          model: {
            value: _vm.drawer,
            callback: function ($$v) {
              _vm.drawer = $$v
            },
            expression: "drawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-wrapper", staticStyle: { width: "100%" } },
            [
              _c("div", { staticClass: "toolbar" }, [
                _c("div", { staticClass: "drawer-title" }, [
                  _c(
                    "span",
                    {
                      staticStyle: { cursor: "pointer" },
                      on: { click: _vm.toggleSLADrawer },
                    },
                    [_c("img", { attrs: { src: "/img/close.svg" } })]
                  ),
                  _vm._v("    Service Level Agreement\n        "),
                ]),
                _c("div", { staticClass: "btn-wrapper" }, [
                  _vm.project.accept_sla
                    ? _c(
                        "button",
                        {
                          staticClass: "grupa-disabled-btn",
                          on: { click: _vm.toggleSLADrawer },
                        },
                        [_vm._v("\n            Agreed & Signed\n          ")]
                      )
                    : _vm._e(),
                  !_vm.project.accept_sla
                    ? _c(
                        "button",
                        {
                          staticClass: "grupa-blue-btn",
                          on: { click: _vm.handleSignSLA },
                        },
                        [
                          _vm._v(
                            "\n            Agree & Execute SLA\n            "
                          ),
                          _c("img", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.spinnerSLA,
                                expression: "spinnerSLA",
                              },
                            ],
                            staticClass: "button-spinner",
                            staticStyle: { "margin-left": "20px" },
                            attrs: { src: "/img/lightbox/preloader.gif" },
                          }),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "nda-content" },
                [
                  _c(
                    "GrupaSLA",
                    _vm._b(
                      {
                        ref: "grupa_sla_",
                        staticStyle: { overflow: "hidden", height: "'auto'" },
                        attrs: { id: "grupa-sla_", devUser: false },
                      },
                      "GrupaSLA",
                      {
                        teamDetails: _vm.teamDetails,
                        projectDoc: _vm.projectDoc,
                        project: _vm.project,
                        equity: _vm.equity,
                      },
                      false
                    )
                  ),
                ],
                1
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }