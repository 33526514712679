<template>
  <div>
    <Navbar stageTitle="Workspace" />
    <div class="main-wrapper">
      <ProgressBar />
      <div class="content-container">
        <MeetDTeam v-if="progressLevel == 2" />
        <SignPlatformAgreement
          v-if="progressLevel == 3 || progressLevel == 6"
          v-bind="{
            project,
            handleMakePayment,
          }"
        />

        <PayAndKickoff
          v-if="progressLevel == 4"
          v-bind="{ handleMakePayment }"
        />

        <DrawRoadMap v-if="progressLevel == 5 && !project.accept_roadmap" />

        <!--   <FinalizeSLA v-if="progressLevel < 6 && project.accept_roadmap" /> -->
<!-- {{ progressLevel }} -->
        <StartProductDevelopment
          v-if="
            (progressLevel == 6 ||
              (progressLevel == 5 && project.accept_roadmap)) &&
            project.accept_roadmap
          "
          v-bind="{
            project,
            monthlyPrice,
            biSprintPrice,
            handleSelectOption,
            selectedOption,
            handleKickoffFee,
          }"
        />
        <StripeModal
          v-if="openStripe"
          v-on:close="closeModal"
          v-bind:amount="amountToPay"
          v-bind="{ modalType, paymentInfo, userCreditCard, user }"
        />
        <PaymentSuccess v-if="paymentModalVisible" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { clientPaymentCards } from "@/api";
import Navbar from "@/components/general/toolbar";
import ProgressBar from "./ProgressBar.vue";
import MeetDTeam from "./MeetDTeam.vue";
import SignPlatformAgreement from "./SignAgreement/SignAgreement.vue";

import DrawRoadMap from "./roadMap/waitRoadMap.vue";
import PayAndKickoff from "./SignAgreement/PayAndKickoff.vue";
import FinalizeSLA from "./SignSLA/SignSLA.vue";
import StartProductDevelopment from "./startProductDev/StartProductDevelopment.vue.vue";
import StripeModal from "@/components/stripeModal";
import PaymentSuccess from "@/components/paymentSuccess";
export default {
  props: {},
  components: {
    Navbar,
    ProgressBar,
    MeetDTeam,
    SignPlatformAgreement,
    PayAndKickoff,
    StripeModal,
    PaymentSuccess,
    DrawRoadMap,
    StartProductDevelopment,
    FinalizeSLA,
  },

  data: () => ({
    currentStage: 0,
    amountToPay: 0,
    modalType: "design_sprint",
    openStripe: false,
    paymentInfo: {
      purpose: "project roadmap",
      payment_plan: "bi-weekly",
      iteration_id: null,
    },
    userCreditCard: [],
    selectedOption: "MONTHLY",
  }),

  created() {
    this.clientCreditCard();
  },
  methods: {
    handlePage(value) {
      this.currentStage = value;
    },
    closeModal() {
      this.openStripe = false;
    },
    handleMakePayment() {
     // let { price, no_of_engineers } = this.clientProject;
     // const per_sprint_fee = (price * no_of_engineers) / 4;
     const per_sprint_fee = this.projectPrice.per_sprint_fee
      this.amountToPay = per_sprint_fee * 2;
      this.paymentInfo.iteration_id = this.project.current_iteration;
      this.openStripe = true;
    },
    handleKickoffFee() {
      let selectedOption = this.selectedOption;
      this.amountToPay =
        selectedOption == "MONTHLY" ? this.monthlyPrice : this.biSprintPrice;
      const iteration_id = this.project.current_iteration;
      const purpose = "kickoff fee";
      const payment_plan =
        selectedOption == "MONTHLY" ? "iteration" : "bi-weekly";
      this.paymentInfo = { purpose, iteration_id, payment_plan };
      this.openStripe = true;
    },
    clientCreditCard() {
      clientPaymentCards()
        .then((response) => {
          this.userCreditCard = response.data.cards;
          this.$developConsole(response.data.cards, "Api Response");
        })
        .catch((error) => {
          this.$developConsole(error.response, "Error Response");
        });
    },
    handleSelectOption(value) {
      this.selectedOption = value;
    },
  },
  computed: {
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
      "projectPrice",
    ]),
    ...mapState("userManagement", ["currentUser"]),
    ...mapState("modalMgmt", ["paymentModalVisible"]),
    user() {
      return this.currentUser;
    },
    progressLevel() {
      //alert(this.clientProject.workspace_progress_level)
      return this.clientProject.workspace_progress_level;
    },
    project() {
      const project = JSON.parse(JSON.stringify(this.clientProject));
      project.owner = this.user;
      // Remove when backend figure out pricing calculation
      if (this.projectPrice.startupStage) {
        project.startupStage = this.startupStage;
      }
      return project;
    },
    monthlyPrice() {
      let {no_of_engineers } = this.clientProject;
      let {price} = this.projectPrice
     // console.log(price)

      return price * Number(no_of_engineers);
    },
    biSprintPrice() {
      return this.monthlyPrice / 2;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.main-wrapper {
  background: #f7f7f8;
  padding: 32px;
  margin-top: 64px;
}
.content-container {
  background: #ffffff;
  border-radius: 4px;
  min-height: calc(100vh - 248px);
  margin-top: 32px;
}
</style>
