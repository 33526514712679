<template>
  <div class="project-drawer">
    <v-navigation-drawer
      v-model="drawer"
      right
      absolute
      temporary
      name="GrupaSLA"
    >
      <div class="drawer-wrapper" style="width: 100%">
        <div class="toolbar">
          <div class="drawer-title">
            <span style="cursor: pointer" @click="toggleSLADrawer"
              ><img src="/img/close.svg" /></span
            >&nbsp;&nbsp;&nbsp; Service Level Agreement
          </div>
          <div class="btn-wrapper">
            <!--  @click="handleSignSLA" -->
            <button
              @click="toggleSLADrawer"
              class="grupa-disabled-btn"
              v-if="project.accept_sla"
            >
              Agreed & Signed
            </button>

            <button
              class="grupa-blue-btn"
              @click="handleSignSLA"
              v-if="!project.accept_sla"
            >
              Agree & Execute SLA
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
                v-show="spinnerSLA"
              />
            </button>
          </div>
        </div>
        <div class="nda-content">
          <GrupaSLA
            ref="grupa_sla_"
            id="grupa-sla_"
            style="overflow: hidden; height: 'auto'"
            v-bind="{ teamDetails, projectDoc, project, equity }"
            v-bind:devUser="false"
          />
        </div>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import GrupaSLA from "@/components/general/grupaSLA";

export default {
  props: {
    equity: {
      required: true,
    },
    projectDoc: {
      type: Object,
      required: true,
    },
    teamDetails: {
      type: Object,
      required: true,
    },

    handleSignSLA: {
      type: Function,
      required: true,
    },

    spinnerSLA: {
      type: Boolean,
      required: true,
    },

    project: {
      required: true,
    },

    //
  },
  data() {
    return {
      drawer: null,
    };
  },

  components: { GrupaSLA },

  created() {},
  methods: {
    toggleSLADrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>

<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}
.drawer-wrapper {
  width: 596px;
  /* margin-top: 147px; */
}
.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.nda-content {
  padding: 28px 24px;
  text-align: left;
}
.nda-title {
  font-size: 18px;
  line-height: 22px;
  color: #1b1e22;
  margin-bottom: 16px;
}
.div-paragraph {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 16px;
}
.div-column {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin-top: 8px;
}
</style>

<style>
.project-drawer .v-overlay--absolute {
  position: fixed;
}
.project-drawer .v-navigation-drawer--open {
  min-width: 60% !important;
}
.project-drawer .v-navigation-drawer--absolute {
  position: fixed;
}
</style>
