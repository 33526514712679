var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Navbar", { attrs: { stageTitle: "Workspace" } }),
      _c("div", { staticClass: "pm-workspace" }, [
        _c("div", { staticClass: "pm-container" }, [
          _c(
            "div",
            { staticClass: "progress-report-wrapper" },
            [
              _c("ProjectReport"),
              _c(
                "div",
                { staticClass: "project-activity" },
                [
                  _c(
                    "ActivityLog",
                    _vm._b(
                      {},
                      "ActivityLog",
                      {
                        projectActivity: _vm.projectActivity,
                        teamMembers: _vm.teamMembers,
                      },
                      false
                    )
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "container-divider" }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }