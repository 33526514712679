<template>
  <div>
    <div class="page-container">
      <div class="content-wrapper">
        <div class="row">
          <div class="col-10">
            <div class="user-greeting">
              <TimeZoneCheck v-bind="{ currentUser }" /> Hi
              {{ shortenName(currentUser.name) }},
            </div>
            <div class="text-below">
              {{ $moment().format("dddd, Do MMM YYYY, h:mm:ss a") }}
            </div>
          </div>
          <div class="col-2 text-left" v-if="browserTimezone != ''">
            <div class="text-below" style="margin-top: 0px; font-size: 10px">
              Timezone
            </div>
            <div>
              <b>{{ browserTimezone }}</b>
            </div>

            <div
              v-if="isUsingVPN && ipTimezone != ''"
              style="
                margin-top: 15px;
                color: #d45b54;
                font-size: 12px;
                font-weight: 900;
              "
            >
              VPN DETECTED
            </div>

            <div
              v-if="isUsingVPN && ipTimezone != ''"
              class="text-below"
              style="margin-top: 0px; font-size: 10px"
            >
              VPN Timezone
            </div>
            <div>
              <b>{{ ipTimezone }}</b>
            </div>
          </div>
        </div>

        <nylasPop />

        <div class="row">
          <div class="column">
            <div class="card card-1">
              <div class="card-text-block">
                <div v-if="!meetingCheckLoader" class="card-text">
                  <div class="bold-text-head">{{ textOne }}</div>
                  <div class="light-text-head">
                    {{ textTwo }}
                  </div>
                  <div v-if="textThree" class="light-text-head">
                    {{ textThree }}
                  </div>
                  <div v-if="buttonText" class="button-wrapper">
                    <div @click="handleContinueProject()" class="action-button">
                      {{ buttonText }}
                      <img src="/img/dashboard/arrow-right.svg" />
                    </div>
                  </div>
                </div>

                <img
                  src="/img/lightbox/preloader.gif"
                  style="margin-right: 30px; float: right; margin-top: 50px"
                  class="button-spinner"
                  v-show="meetingCheckLoader"
                />
              </div>
            </div>
          </div>
          <div class="column">
            <div class="card-2">
              <div class="card-text-block card-content-left">
                <h5>Refer & Earn</h5>
                <p class="p-2">
                  Get an Elite Product Engineering Team to bring your product
                  idea to life
                </p>
                <div @click="handleReferralNow" class="button-plain">
                  Refer now <img src="/img/onboarding/right-arrow.svg" />
                </div>
              </div>
              <div class="card-illustration">
                <img src="/img/dashboard/referral-illustration.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="hangout-container">
          <div><img src="/img/onboarding/sip_cup.svg" /></div>
          <div class="hangout-bold-text">
            Hangout with other Founders and VCs!
          </div>
          <div class="hangout-light-text">
            Expand your network, bounce ideas, learn all you need to know about
            building amazing products.
          </div>
          <div @click="handleJoinConversation" class="join-conversation">
            Join the Conversation
            <img src="/img/onboarding/blue-arrow.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Axios from "axios";
import Vue from "vue";
import { mapState, mapActions } from "vuex";
import shortName from "@/mixins/shortName";
import mixPanel from "@/mixins/mixPanel";
import appMixin from "@/mixins/appMixin";
import { getGrupaNylasRecord, connectGrupaToNylas } from "@/api/nylasApi";
import TimeZoneCheck from "@/views/TimeZoneCheck/index";
import nylasPop from "./nylasPop.vue";
import { FounderMeetingChecher } from "@/api/client";
import { getEnginnerforProject } from "@/api";

export default {
  props: {},
  components: { TimeZoneCheck, nylasPop },
  mixins: [shortName, mixPanel, appMixin],
  data: () => ({
    isUsingVPN: true,
    browserTimezone: "",
    ipTimezone: "",
    textOne: `Build a Product`,
    textTwo: `Continue from where you stopped in the Hire a Team Process`,
    textThree: ``,
    buttonText: `Continue`,
    isGetNylasData: false,
    devNylasRecord: [],
    isHideBar: true,
    meetingCheckLoader: false,
    meetingRecord: [],
    isLoadingLookingFor: false,
    teamRolesArrData: [],

    engineerRole: {
      engineers: [],
      engineersData: [],
      role: [],
      preview: true,
      isSelected: true,
      timeSlot: [],
    },
  }),

  mounted() {
    let payload2 = {
      skill_check: true,
      platforms_check: true,
      industry_check: true,
    };

    this.fetchEngineerProject(payload2);

    this.computedDashboardText();
    this.ChecherMeetingState();

    this.detectVPN();

    this.$nextTick(async () => {
      if (!this.isGetStartUpList) {
        // alert("fetchStartUpList");
        // this.fetchStartUpList();
        this.isGetStartUpList = true;
      }

      // this.isGetNylasData = true;
      if (!this.isGetNylasData) {
        this.getGrupaNylasRecordFunction();
        this.isGetNylasData = true;
      }
      $(".step2, .step3, .nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

      let InnterVal = setInterval(() => {
        $(".nylasLoader2").fadeToggle(3000);
      }, 1500);

      //alert(this.showNylasBar())

      let nylas_bar = setTimeout(() => {
        $(".nylas-warning-container").fadeIn(1000);
        clearTimeout(nylas_bar);
      }, 3500);

      $(".isSetupProfileClicked").on("click", function () {
        $(".step2, .step3").fadeOut(() => {
          $(".nylasLoader, .nylasAlert, .nylasLoader2").fadeOut();

          $(".step1").fadeIn();
        });
      });

      $(".nylasBarButton_").on("click", async function () {
        const user = JSON.parse(localStorage.getItem("grupa_user"));
        let baseURL = process.env.VUE_APP_BACKEND_API;
        $(".nylasLoader").fadeIn();

        let button = $(this);

        await $.ajax({
          url: `${baseURL}/connect/nylas/nylas_authentication_curl/`,
          type: "POST",
          contentType: "application/json; charset=utf-8",
          dataType: "json",
          beforeSend: function (xhr) {
            xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
            // xhr.setRequestHeader("contentType", "application/json");
            button.hide();
            //console.log(user.email);
          },

          data: {
            email: user.email,
            //name: "eze okechukwu",
          },
          // data: jQuery.param({ email: user.email }),
          success: function (data) {
            // alert(data);
            $(".nylasLoader").fadeOut(() => {
              // button.hide();
              // $("#nylasAuth").attr("src", data);
            });

            // console.log(data);

            $(".step1").fadeOut(() => {
              $(".step2").fadeIn();
              $(".nylasLoader2").fadeIn();
            });
            //return data;
          },
          error: function (error) {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasLoader2").fadeOut();

            return error;
          },
        });
      });
    });
  },
  methods: {
    async fetchEngineerProject(params_Data) {
      let projectId = this.clientProject.id;
      this.meetingCheckLoader = true;

      await getEnginnerforProject(projectId, params_Data)
        .then((response) => {
          this.meetingCheckLoader = false;
          //console.log(response.data.engineer[0]);

          let matchRoles = response.data.engineer[0].matchRole;
          this.engineerRole.role = matchRoles;
        })
        .catch((error) => {
          this.meetingCheckLoader = false;
        });
    },
    checkedSelectedTeamRole(id) {
      const foundItem = this.teamRolesArrData.find((item) => item.id == id);
      return foundItem.name;
    },

    async ChecherMeetingState() {
      const payload = {
        project_id: this.clientProject.id,
        client_id: this.currentUser.id,
      };
      this.meetingCheckLoader = true;

      await FounderMeetingChecher(payload)
        .then((data) => {
          this.meetingCheckLoader = false;
          // console.log(data.data);
          this.meetingRecord = data.data.meeting;

          this.computedDashboardText();
        })
        .catch((error) => {
          this.meetingCheckLoader = false;
        });
    },
    validURL(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },

    async detectVPN() {
      var browserTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      this.browserTimezone = browserTimezone;

      return fetch(`https://ipapi.co/json`)
        .then(function (response) {
          return response.json();
        })
        .then((data) => {
          var ipTimezone = data.timezone;

          /*  alert(
            `browser timezone: ${browserTimezone}`+
            `ip timezone: ${ipTimezone}`
          ); */

          // alert(ipTimezone + " - " + browserTimezone);

          if (ipTimezone != browserTimezone) {
            this.isUsingVPN = true;
            this.ipTimezone = ipTimezone;
          } else {
            this.isUsingVPN = false;
          }
        });
    },

    handleGetNylasUniqueURL() {
      const user = JSON.parse(localStorage.getItem("grupa_user"));

      let payload = {
        email: user.email,
      };

      let button = $(".nylasBarButton");

      button.fadeOut(0);

      $(".nylasLoader").fadeIn(() => {
        connectGrupaToNylas(payload)
          .then((data) => {
            var nylas_window = window.open(
              data.data,
              "",
              "toolbar=no,status=no,menubar=no,location=center,scrollbars=no,resizable=no,height=600,width=657"
            );

            $("#showNylasBar").modal("hide");

            // console.log(nylas_window);

            const timer = setInterval(() => {
              let url = nylas_window.location.href;
              console.log(url);
              if (nylas_window.closed) {
                clearInterval(timer);
                //
                clearTimeout(InnterVal);
                //

                //console.log("closed");
              }
            }, 1000);

            //logout-link
            if (this.validURL(data.data)) {
              button.show();
              $(".nylasLoader").fadeOut(() => {
                // button.hide();
                // $("#nylasAuth").attr("src", data.data);

                $(".step1").fadeOut(() => {
                  $(".step2").fadeIn();
                });
              });
            } else {
              button.show();
              $(".nylasLoader").fadeOut();
              $(".nylasAlert").fadeIn();
            }
            //console.log(this.validURL(data.data));
            //console.log(data.data);
          })
          .catch((error) => {
            console.log(error);

            button.show();
            $(".nylasLoader").fadeOut();
            $(".nylasAlert").fadeIn();
          });
      });

      $(".nylasAlert").fadeOut();
    },

    showNylasBar() {
      let state =
        this.devNylasRecord.hasOwnProperty("user") &&
        this.devNylasRecord.user !== null
          ? Object.keys(this.devNylasRecord.user).length <= 0
            ? true
            : false
          : true;
      this.isHideBar = false;
      return state;
    },

    async getGrupaNylasRecordFunction() {
      if (!this.isGetNylasData) {
        let payload = {
          email: this.currentUser.email, //"okechukwu0127@gmail.com", //
        };
        this.isReloadingContent = true;

        //////GET NYLAS RECORD

        this.devNylasRecord = { user: this.currentUser.nylas };
        // console.log(JSON.stringify(res.data.data));
        this.isGetNylasData = true;
        this.isReloadingContent = false;

        /*  await getGrupaNylasRecord(payload)
           .then((res) => {
             this.devNylasRecord = res.data.data;
             console.log(JSON.stringify(res.data.data));
             this.isGetNylasData = true;
             this.isReloadingContent = false;
           })
           .catch((error) => {
             this.isReloadingContent = true;
             console.log("ERROR getGrupaNylasRecord: ", error);
           }); */
      }
    },
    handleReferralNow() {
      const id = this.$route.params.id;
      this.$router.push({ name: "referral", params: { id } });
    },
    handleJoinConversation() {
      this.$router.push({ name: "join_conversation" });
      this.mixPanelTrack({
        event: "ClientJoinConversation",
      });
    },
    handleContinueProject() {
      let {
        id,
        team_id,
        workspace_progress_level,
        startup,
        preview_meeting_start_date,
      } = this.project;
      // const { is_priority, is_waiting, backed_by } = startup;
      if (workspace_progress_level < 1) {
        this.$router.push({
          name: "preview_potential_team",
          params: { id },
        });
        this.mixPanelTrack({
          event: "ContinueToPreviewPotentialTeam",
        });
      } else if (
        workspace_progress_level == 1 &&
        (!preview_meeting_start_date || preview_meeting_start_date)
      ) {
        this.$router.push({
          name: "schedule_call",
          params: { id },
        });
        this.mixPanelTrack({
          event: "ContinueToScheduleCall",
        });
      } else if (workspace_progress_level >= 2) {
        if (this.meetingRecord.length > 0) {
          this.engineerRole.preview = true;
          let arraySet = [];
          let arraySet2 = [];

          (JSON.parse(this.clientProject.looking_for) || []).map((data, i) => {
            arraySet.push([]);
            arraySet2.push([]);
          });

          let EngTimeSlot = [];

          this.engineerRole.engineers = arraySet;
          this.engineerRole.engineersData = arraySet2;

          this.engineerRole.role.map((role, i) => {
            let engRow = [];

            for (let k = 0; k < this.meetingRecord.length; k++) {
              if (role === this.meetingRecord[k].role) {
                this.engineerRole.engineers[i][k] =
                  this.meetingRecord[k].eng_id.toString();

                let engData = {
                  id: this.meetingRecord[k].eng_id,
                  location: this.meetingRecord[k].eng_loc || "Empty",
                  name: this.meetingRecord[k].eng_name,
                  image: "/img/onboarding/profile-avatar.svg",
                  // image: "https://grupa-media.s3-us-west-2.amazonaws.com/live/1672827757_mn6Vc5jL.png",
                  organisation: this.meetingRecord[k].eng_org,
                  email: this.meetingRecord[k].eng_email,
                  timezone: this.meetingRecord[k].eng_zone || "Empty",
                  token: this.meetingRecord[k].calendar_id,
                };

                EngTimeSlot.push({
                  eng: engData,
                  role: this.meetingRecord[k].role,
                  when: {
                    start_time: this.meetingRecord[k].start_time,
                    end_time: this.meetingRecord[k].end_time,
                  },
                });

                engRow.push(engData);
              } else {
              }
            }

            this.engineerRole.timeSlot = EngTimeSlot;

            engRow.forEach((item) => {
              this.engineerRole.engineersData[i].push(item);
            });

            var filtered = this.engineerRole.engineers[i].filter(function (el) {
              return el != null;
            });
            this.engineerRole.engineers[i] = filtered;

            var filtered = this.engineerRole.engineersData[i].filter(function (
              el
            ) {
              return el != null;
            });
            this.engineerRole.engineersData[i] = filtered;
          });

          localStorage.setItem(
            "engineerRole",
            JSON.stringify(this.engineerRole)
          );

          this.$router.push({
            name: "select_team",
            params: { id },
          });
        } else {
          this.$router.push({
            name: "select_team",
            params: { id },
          });
        }

        this.mixPanelTrack({
          event: "ContinueToSelectTeam",
        });
      }
    },
    convertedStartDate(start_date) {
      let userTimeZone = this.userLocation.timezone;
      let newDate = this.changeTimezone(start_date, userTimeZone);
      let momentDate = this.$moment(newDate).format(
        "dddd, MMMM Do YYYY, h:mm:ss a"
      );
      return momentDate;
    },
    computedDashboardText() {
      let {
        team_id,
        workspace_progress_level,
        startup,
        preview_meeting_start_date,
      } = this.project;

      // const { is_priority, is_waiting, backed_by } = startup;
      if (workspace_progress_level === 1 && preview_meeting_start_date) {
        // alert(workspace_progress_level+' - '+team_id+'-1')
        this.textOne = `Scheduled Product Meeting`;
        this.textTwo = `Meeting Scheduled for ${this.convertedStartDate(
          preview_meeting_start_date
        )}`;
        this.textThree = `Meeting link would be sent to your email.  Reach out to us at hello@grupa.io with any requests.`;
        this.buttonText = "";
      }

      if (
        this.meetingRecord.length > 0 &&
        workspace_progress_level >= 2 &&
        !team_id
      ) {
        this.textOne = `Proceed with Team`;
        this.textTwo = `Team would be notified of your interest to work with them.  You can request to change your team if you’re not satisfied after your
        initial meeting.`;
        this.buttonText = `Proceed with Team`;
      } else {
        if (workspace_progress_level >= 2 && !team_id) {
          //alert(workspace_progress_level+' - '+team_id+'-2')
          this.textOne = `Choose a Team`;
          this.textTwo = `You have been given priority access to the teams in the Grupa ecosystem`;
          this.buttonText = `Choose a Team`;
        }
      }

      if (startup && startup.is_waiting && workspace_progress_level < 1) {
        this.textOne = `We are working to get you matched...`;
        this.textTwo = `We would get you a match in no time. Join our priority list to get matched immediately!`;
        this.buttonText = `Join Priority List`;
      }
    },
  },
  created() {
    
  },
  computed: {
    ...mapState("userManagement", ["currentUser", "userLocation"]),
    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),
    project() {
      return this.clientProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.nylasLoader,
.nylasLoader2 {
  margin-top: 50px;
  height: 20vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nylasBarTextSub {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  color: #979da5;
  padding-top: 17px;
  padding-left: 30px;
  padding-right: 30px;
}

.create-team-msg {
  width: 397px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  margin-top: 8px;
  color: #979da5;
}

.nylasBarButton {
  margin: auto;
  margin-top: 60px;
  margin-bottom: 20px;
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  cursor: pointer;
  width: 372px;
  height: 45px;

  /* primary/0 */

  background: #ffffff;
  box-shadow: 0px 0px 3px rgba(27, 30, 34, 0.07),
    1px 2px 4px rgba(27, 30, 34, 0.15);
  border-radius: 3px;
}

.nylasBarText {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
  /* or 20px */

  color: #1b1e22;
  padding-top: 32px;
  padding-left: 30px;
  padding-right: 30px;
}

.nylas-warning-container {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border-radius: 4px;
  padding: 0px 62px 0px;
  display: flex;
  margin-top: 34px;
  justify-content: space-between;
  text-align: left;
}

.page-container {
  padding: 48px 32px 32px;
  margin-top: 67px;
  min-height: calc(100vh - 67px);
  background: #f7f7f8;
}

.user-greeting {
  font-weight: 500;
  font-size: 32px;
  line-height: 145%;
  color: #000000;
}

.text-below {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 4px;
}

.content-wrapper {
  text-align: left;
}

.column {
  float: left;
  width: 50%;
  padding: 0 10px;
}

/* Remove extra left and right margins, due to padding in columns */
.row {
  margin: 34px -5px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Style the counter cards */
.card {
  padding: 24px;
  text-align: center;
  background-color: #f1f1f1;
  height: 100%;
}

.card.card-1 {
  background: radial-gradient(56.56% 50% at 50% 50%, #23272c 0%, #15171a 100%);
  border: none;
  border: 4px;
  color: #fff;
}

.card-2 {
  background: #fbefee;
  color: #d45b54;
  border: none;
  display: flex;
  justify-content: space-between;
  padding: 24px;
  text-align: center;
}

.card-text-block {
  /* width: 380px; */
}

.card-text-block p {
  word-break: break-word;
  font-size: 18px;
  padding: 20px;
  text-align: left;
  font-weight: bold;
  color: #ffffff;
}

.card-text-block p.p-2 {
  font-size: 14px;
  line-height: 145%;
  color: #d45b54;
  max-width: 266px;
  font-weight: normal;
}

.card-text-block h5 {
  text-align: left;
  padding-left: 7px;
  padding-bottom: 10px;
  font-weight: bold;
}

.card-text-block .button {
  word-break: break-word;
  font-size: 15px;
  width: 150px;
  padding: 5px;
  background: #ffffff;
  border-radius: 3px;
  color: #0781f2 !important;
  margin-left: 20px;
  cursor: pointer;
}

.card-text-block .button-plain {
  word-break: break-word;
  font-size: 15px;
  width: 150px;
  padding: 5px;
  border-radius: 3px;
  color: #0781f2 !important;
  text-align: left;
  cursor: pointer;
}

.button-plain img {
  fill: #0781f2;
}

.hangout-container {
  background: #ffffff;
  border-radius: 4px;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 515px;
}

.hangout-bold-text {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  text-align: center;
  color: #1b1e22;
  margin-top: 24px;
}

.hangout-light-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  color: #53585f;
  margin-top: 12px;
  width: 420px;
}

.join-conversation {
  margin-top: 24px;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0781f2;
  cursor: pointer;
}

/* New style */
.card-text {
  width: 359px;
  text-align: left;
}

.bold-text-head {
  font-weight: bold;
  font-size: 18px;
  line-height: 145%;
  color: #ffffff;
}

.light-text-head {
  margin-top: 12px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #979da5;
  /* max-width: 330px;
  width: 250px; */
}

.button-wrapper {
  display: flex;
  margin-top: 24px;
}

.action-button {
  background: #ffffff;
  border-radius: 3px;
  padding: 10.5px 20px;
  font-size: 14px;
  line-height: 100%;
  color: #0781f2;
  cursor: pointer;
}

.card-content-left {
  max-width: 301px;
}

.card-illustration img {
  width: 128px;
  height: 128px;
}

/* Responsive columns - one column layout (vertical) on small screens */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }

  .hangout-light-text {
    width: 100%;
  }
}
</style>
