var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.project == null
      ? _c("div", [_c("Loader")], 1)
      : _c(
          "div",
          [
            _vm.progressLevel !== null && _vm.progressLevel < 3 && !_vm.teamId
              ? _c("NewProject")
              : _vm._e(),
            _vm.progressLevel >= 2 && _vm.progressLevel < 7 && _vm.teamId
              ? _c("ProductEngagement")
              : _vm._e(),
            _vm.progressLevel !== null && _vm.progressLevel >= 7
              ? _c("ProjectKickoff")
              : _vm._e(),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }