var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "manager-report" }, [
    _c("div", { staticClass: "progress-text" }, [_vm._v("PROGRESS REPORT")]),
    _c("div", { staticClass: "bolder-header" }, [_vm._v("Current Sprint")]),
    _c("div", { staticClass: "sprint-name" }, [
      _c("div", { staticClass: "sprint-no" }, [
        _vm._v("Sprint " + _vm._s(_vm.currentSprint.sprint)),
      ]),
      _vm._m(0),
      _c("span", { staticStyle: { color: "#54cf94" } }, [
        _vm._v("\n      " + _vm._s(_vm.sprintDate) + "\n    "),
      ]),
    ]),
    _c("div", { staticClass: "overview-progress-bar-wrap" }, [
      _c("div", { staticClass: "overview-bar-con" }, [
        _c("div", {
          staticClass: "bar-auto-increase",
          style: "width:" + _vm.percentageTasksDone(_vm.tasks) + "%",
        }),
      ]),
      _c("div", { staticClass: "overview-percent-div" }, [
        _vm._v(_vm._s(_vm.percentageTasksDone(_vm.tasks)) + "%"),
      ]),
    ]),
    _c("div", { staticClass: "task-completed-count" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.completedTasks.length) +
          "\n    /" +
          _vm._s(_vm.tasks.length) +
          " tasks completed\n  "
      ),
    ]),
    _c("div", { staticClass: "current-iteration-info" }, [
      _c("div", { staticClass: "bolder-header" }, [
        _vm._v("Current Iteration  "),
      ]),
      _c("div", { staticClass: "iteration-name" }, [
        _c("div", { staticClass: "itr-no" }, [
          _vm._v("Iteration " + _vm._s(_vm.currentIteration.it_number)),
        ]),
        _vm._m(1),
        _c("span", { staticStyle: { color: "#54cf94" } }, [
          _vm._v("\n        " + _vm._s(_vm.iterationStartDate) + "\n      "),
        ]),
      ]),
      _vm._m(2),
      _c("div", { staticClass: "task-completed-count" }, [
        _vm._v(
          "\n      0/" +
            _vm._s(_vm.currentFeatures.length) +
            " features completed\n    "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/sprint/clock-ongoing.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/sprint/clock-ongoing.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "overview-progress-bar-wrap" }, [
      _c("div", { staticClass: "overview-bar-con" }, [
        _c("div", { staticClass: "bar-auto-increase" }),
      ]),
      _c("div", { staticClass: "overview-percent-div" }, [_vm._v("0%")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }