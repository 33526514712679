<template>
  <div class="section-wrapper">
    <div class="left-container">
      <div class="section-header">Meet the Team</div>
      <div class="grey-text">
        {{
          teamDetails.team_name
            ? teamDetails.team_name
            : `${teamDetails.name.split(" ")[0]}'s Team`
        }}
        Team has been notified of your commitment to work with them. They’ll
        respond shortly (typically within 0 - 6hrs)
      </div>
      <div class="grey-text">
        We’ll send you an email to schedule the initial meeting with the team.
      </div>
      <div class="grey-text">
        You can request to change your team if you’re not satisfied after this
        initial meeting.
      </div>
    </div>
    <div class="right-container">
      <div class="grey-header">Click the team to view more details</div>
      <div class="selected-team-card">
        <div class="team-info">
          <div class="team-name">
            
            {{
              Object.keys(teamMembers).length == 1
                ? teamMembers[0].name
                : teamDetails.team_name
                ? teamDetails.team_name
                : `${teamDetails.name.split(" ")[0]}'s Team`
            }}
          </div>
          <div class="team-description">
            {{ teamDetails.bio ? teamDetails.bio : "" }}
          </div>
          <div class="team-member-list">
           

            <div
              class="profile-image"
              v-for="(member, i) in teamMembers"
              :key="i"
              v-if="member.name"
            >
              <v-avatar
                :size="30"
                color="grey lighten-4"
                class="profile_bg"
                :style="{
                  backgroundImage: `url(${
                    $imageUrl +
                    (member.profile_picture === null ||
                      member.profile_picture === '')
                      ? test
                      : member.profile_picture
                  })`,
                }"
              ></v-avatar>
            </div>
          </div>
        </div>
        <div class="team-location-info">
          <div class="information-list">
            <img src="/img/onboarding/select-team/location.svg" />
            {{ teamDetails.country }}
            {{ teamDetails.city ? `, ${teamDetails.city}` : "" }}
          </div>
          <div
            v-if="teamDetails.team_timezone"
            class="information-list list-item"
          >
            <img src="/img/onboarding/select-team/gmt.svg" />
            GMT {{ getTimeZone(teamDetails.team_timezone) }} ({{
              compareTimeZone(teamDetails.team_timezone, userLocation.timezone)
            }})
          </div>
          <div
            v-if="teamDetails.compensation_plans"
            class="information-list list-item"
          >
            <img src="/img/onboarding/stack-of-coins.svg" />
            <span class="open-to"
              >Open to
              <span
                class="team-equity"
                v-for="(plan, index) in convertEquity(
                  teamDetails.compensation_plans
                )"
                :key="index"
                >{{ plan }}</span
              >
            </span>
          </div>
          <div class="information-list list-item">
            <img src="/img/onboarding/select-team/time.svg" />
            <span class="open-to"
              >Available to work
              <span class="green-text">up to 30h a week</span>
            </span>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="grupa-blue-btn" @click="handleProceedWithTeam">
          Proceed with this Team
          <img
            src="/img/lightbox/preloader.gif"
            style="margin-left: 20px"
            class="button-spinner"
            v-show="spinner"
          />
        </button>
        <!-- <button class="grupa-white-btn">Change Team</button> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { compareTimeZone, getTimeZone } from "@/utils/timeZone";
import { updateProject } from "@/api/client";
export default {
  props: {},
  components: {},

  data: () => ({
    test: "/img/profile.svg",
    spinner: false,
    compareTimeZone: compareTimeZone,
    getTimeZone: getTimeZone,
  }),

  created() {},
  methods: {
    ...mapActions("clientDashboard", ["updateProject"]),
    handleProceedWithTeam() {
      const project_id = this.$route.params.id;
      const payload = {
        workspace_progress_level: 3,
        project_id,
      };
      this.spinner = true;
      updateProject(payload)
        .then((response) => {
          if (response.data.status === 1) {
            this.updateProject(response.data.project);
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.spinner = false;
        });
    },
    convertEquity(value) {
      if (value && value.includes("[")) {
        let toArray = JSON.parse(value);
        return toArray.map((item) => grupaCompensation(item));
      }
      return [];
    },
  },
  computed: {
    ...mapState("userManagement", ["userLocation"]),
    ...mapState("clientDashboard", [
      "clientProject",
      "clientProjectDoc",
      "projectTeam",
    ]),
    project() {
      return this.clientProject;
    },
    teamMembers() {
      return this.projectTeam;
    },
    teamDetails() {
      return this.projectTeam[0];
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
  /* #E4E5E7 */
}
.section-wrapper {
  display: flex;
  text-align: left;
  min-height: calc(100vh - 248px);
}
.left-container {
  padding: 40px;
  width: 30%;
  background: #ffffff;
  box-shadow: 1px 0px 0px rgba(27, 30, 34, 0.1);
  border-radius: 4px 0px 0px 4px;
  text-align: left;
  align-items: stretch;
  display: flex;
  flex-direction: column;
}
.section-header {
  font-weight: bold;
  font-size: 23px;
  line-height: 28px;
  color: #1b1e22;
}
.grey-text {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  margin-top: 16px;
  color: #53585f;
}
.right-container {
  padding: 40px;
  flex: auto;
  width: 70%;
}
.grey-header {
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;

  color: #979da5;
}

.selected-team-card {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 1px 2px 8px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  padding: 24px;
  margin-top: 16px;
  display: flex;
}
.team-info {
  border-right: 1px solid #e4e5e7;
  padding-right: 24px;
}
.team-info,
.team-location-info {
  width: 50%;
}
.team-location-info {
  padding-left: 24px;
}
.team-name {
  font-weight: normal;
  font-size: 16px;
  line-height: 145%;
  color: #1b1e22;
  text-transform: capitalize;
}
.team-description {
  margin-top: 8px;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.information-list {
  font-size: 14px;
  line-height: 145%;
  color: #53585f;
}
.list-item {
  margin-top: 16px;
}
.team-member-list {
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;
}
.btn-container {
  margin-top: 24px;
  display: flex;
  gap: 8px;
}
.green-text {
  color: #1fa564;
  font-weight: 400;
  font-size: 14px;
  line-height: 145%;
}
.team-equity {
  color: #de9221;
}
</style>
