var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("div", { staticClass: "page-layout" }, [
        _c("div", { staticClass: "section-cover" }, [
          _c("div", { staticClass: "sla-page" }, [
            _vm._v(
              "\n        This document captures the details of service expected. Please go\n        through it and confirm that everything is in order.\n      "
            ),
          ]),
          _c("div", { staticClass: "sla-file" }, [
            _c("div", { staticClass: "sla-title" }, [
              _vm._v("\n          Service Level Agreement\n          "),
              _c("img", {
                staticStyle: { float: "right" },
                attrs: { src: "/img/dashboard/expand-icon.svg" },
              }),
            ]),
            _c("div", { staticClass: "doc-scroll-wrapper" }, [
              _c("div", { staticClass: "sla-content" }, [
                _vm._v(
                  "\n            In a few days Mr. Bingley returned Mr. Bennet’s visit, and sat\n            about ten minutes with him in his library. He had entertained\n            hopes of being admitted to a sight of the young ladies, of whose\n            beauty he had heard much; but he saw only the father. The ladies\n            were somewhat more fortunate, for they had the advantage of\n            ascertaining from an upper window that he wore a blue coat, and\n            rode a black horse.\n          "
                ),
              ]),
              _c("div", { staticClass: "sla-content" }, [
                _vm._v(
                  "\n            An invitation to dinner was soon afterwards dispatched; and\n            already had Mrs. Bennet planned the courses that were to do credit\n            to her housekeeping, when an answer arrived which deferred it all.\n            Mr. Bingley was obliged to be in town the following day, and,\n            consequently, unable to accept the honour of their invitation,\n            etc. Mrs. Bennet was quite disconcerted. She could not imagine\n            what business he could have in town so soon after his arrival in\n            Hertfordshire; and she began to fear that he might be always\n            flying about from one place to another, and never settled at\n            Netherfield as he ought to be. Lady Lucas quieted her fears a\n            little by starting the idea of his being gone to London only to\n            get a large party for the ball; and a report soon followed that\n            Mr. Bingley was to bring twelve ladies and seven gentlemen with\n            him to the assembly. The girls grieved over such a number of\n            ladies, but were comforted the day before the ball by hearing,\n            that instead of twelve he brought only six with him from\n            London—his five sisters and a cousin. And when the party entered\n            the assembly room it consisted of only five altogether—Mr.\n            Bingley, his two sisters, the husband of the eldest, and another\n            young man.\n          "
                ),
              ]),
              _c("div", { staticClass: "sla-content" }, [
                _vm._v(
                  "\n            Mr. Bingley was good-looking and gentlemanlike; he had a pleasant\n            countenance, and easy, unaffected manners. His sisters were fine\n            women, with an air of decided fashion. His brother-in-law, Mr.\n            Hurst, merely looked the gentleman; but his friend Mr. Darcy soon\n            drew the attention of the room by his fine, tall person, handsome\n            features, noble mien, and the\n          "
                ),
              ]),
              _c("div", { staticClass: "sla-content" }, [
                _vm._v(
                  "\n            Mr. Bingley was good-looking and gentlemanlike; he had a pleasant\n            countenance, and easy, unaffected manners. His sisters were fine\n            women, with an air of decided fashion. His brother-in-law, Mr.\n            Hurst, merely looked the gentleman; but his friend Mr. Darcy soon\n            drew the attention of the room by his fine, tall person, handsome\n            features, noble mien, and the\n          "
                ),
              ]),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }